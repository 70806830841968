import { call, put } from 'redux-saga/effects';
import { UserManagementActions, UIAction } from '../actions/index';
import { UserService } from '../services';
import {Constants} from "../../constants/Constants";
import { AuthCheck } from "../lib/authCheck";

export function* getUserList(action: ReturnType<typeof UserManagementActions.getUserListStartAction>) {
    try {
        
        yield put(UIAction.showLoader(true));
        yield put(UIAction.setApiError(null));
        const index = (action.payload) ? action.payload.findIndex((obj: { key: string; }) => obj.key === 'tenantId'):'-1';
        const supplierCode = (index > -1 && action.payload[index])?action.payload[index].value:'ALL'
        yield call(AuthCheck.getInstance().check,{ action: 'read', resource: 'userManagement', tenantId: supplierCode });
        if (action.payload.find((params: any) => params.key === 'csvExport')) {
			yield call(UserService.getInstance().downloadUsersCsv, action.payload);
		} else {
            const usersList:any[] = yield call(UserService.getInstance().getUserList, action.payload)
            yield put(UserManagementActions.getUserListCompleteAction(usersList));
        
            if(!usersList || usersList.length === 0){
                yield put(UIAction.setApiError({errorMessage:Constants.ALERT.NO_RECORD_FOUND,severity:'info',title:"Info"}));  
            }
        }
        yield put(UIAction.showLoader(false));
    } catch (err: any) {
        yield put(UserManagementActions.getUserListCompleteAction([]));
        yield put(UIAction.showLoader(false));

        const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
        yield put(UIAction.setApiError({errorMessage:errorMessage,severity:'error',title:"Error"}));
    }
}

export function* createUser(action: ReturnType<typeof UserManagementActions.createUserAction>) {
    try {
        yield put(UIAction.showLoader(true));
        yield put(UserManagementActions.userErrorAction(false));
        yield call(UserService.getInstance().createUser, action.payload.userData);
        const usersList:any[] = yield call(UserService.getInstance().getUserList, action.payload.filterPayload)
        yield put(UserManagementActions.getUserListCompleteAction(usersList));
        yield put(UIAction.showLoader(false));
    } catch (err: any) {
        yield put(UserManagementActions.userErrorAction(true));
        yield put(UIAction.showLoader(false));
    }
}

export function* updateUser(action: ReturnType<typeof UserManagementActions.updateUserAction>) {
    try {
        yield put(UIAction.showLoader(true));
        yield put(UserManagementActions.userErrorAction(false));
        yield call(UserService.getInstance().updateUser, action.payload.userData);
        const usersList:any[] = yield call(UserService.getInstance().getUserList, action.payload.filterPayload)
        yield put(UserManagementActions.getUserListCompleteAction(usersList));
        yield put(UIAction.showLoader(false));
    } catch (err: any) {
        yield put(UserManagementActions.userErrorAction(true));
        yield put(UIAction.showLoader(false));
    }
}

export function* checkIfUserExists(action: ReturnType<typeof UserManagementActions.userExistsCheckAction>) {
    try {
        yield call(UserService.getInstance().getUserByEmail, action.payload);
        yield put(UserManagementActions.userExistsCheckCompleteAction('User already exists'));
    } catch (err: any) {
        const status = err.response.status;
        let message = 'User does not exist';
        if (status === 403) {
            message = 'User already exists';
        }
        yield put(UserManagementActions.userExistsCheckCompleteAction(message));
    }
}