import { connect, useDispatch } from 'react-redux';
import dataList from "../../constants/dataList";
import { GlobalFiltersComponent } from '@fffenterprises/global-filters/lib/global-filters-component';
import {
  UIAction,
  InventoryActions,
  ProductActions,
  ReportActions,
  ProductExpirationAction,
  TransactionActions,
  UserManagementActions,
  DashboardActions
} from '../../redux/actions';
import { Constants } from "../../constants/Constants";
import AdvanceFilters from "../../components/advanceFilters";
import { SanitizeArray } from '../../hooks/functions/SanitizeData';
import { SUPProductPicker } from '@fffenterprises/sup-product-picker/lib/sup-product-picker';

//Component takes in both state props, and "regular" props as "ownProps", passed in from the page where this Container is embedded
const mapStateToProps = (state: any, ownProps: any) => ({
  selectedResource: state.ui.selectedResource,
  selectedInitiatedBy: state.ui.selectedInitiatedBy,
  selectedCustomer: state.ui.selectedCustomer, 
  selectedCabinetType: state.ui.selectedCabinetType,
  selectedExpiryDate: state.ui.selectedExpiryDate,
  selectedDateRange: state.ui.selectedDateRange,
  selectedFilterValues: state.ui.selectedFilterValues, //generic storage for any arbitrary filter that might be listed under "MORE"
  selectedHistoryDate: state.ui.selectedHistoryDate,
  autoCompleteProducts: state.product.autoCompleteList || [],
  allProductsList: state.product.productList || [],
  incidentRules: state.alerts?.incidentRules || [],
  incidentAlertTypeList: state.alerts?.incidents?.alertTypeList || [],
  statesList: state.cabinet?.stateList?.result || [],
  properties: (state.user?.authUser?.record?.properties && state.user.authUser.record.properties instanceof Array) ? state.user.authUser.record.properties : [],
  suppliers: state.supplier.suppliersList?.result
        ? SanitizeArray(state.supplier.suppliersList.result)
        : [],//state.tenants?.tenantsList?.result || [],
  selectedSaveSearchPageName: state.ui.selectedSaveSearchPageName,
  selectedSaveSearchTitle: state.ui.selectedSaveSearchTitle,
  selectedSavedFilter: state.ui.selectedSavedFilter,
  disableSerialNumberFilter: state.ui.disableSerialNumberFilter ? state.ui.disableSerialNumberFilter : false,
  authUser: state.user.authUser,
  dataList: dataList,
  Constants: Constants,
  AdvanceFilters: AdvanceFilters, //saved search component
  isViewPortal: true,
  selectedSupplier: state.ui.selectedSupplier,
  productPicker_productList: state.product?.productList || [],
  productPicker_productLoading: state.ui.productLoading ? state.ui.productLoading : false,
  productPicker_supplierLoading: state.ui.supplierLoading ? state.ui.supplierLoading : false,
  SUPProductPicker: SUPProductPicker,
  regionOptions: state.supplier.regions || [],
  classOfTradeOptions: state.tenants.classOfTradeOptions ? state.tenants.classOfTradeOptions : [],
  facilityTypeOptions: state.tenants.facilityTypeOptions ? state.tenants.facilityTypeOptions : [],
});

const mapDispatchToProps = (dispatch: any) => ({
  handleSupplierSelect: () => {},
  setSelectedSupplier: (supplier: any) => dispatch(UIAction.setSelectedSupplier(supplier)),
  productPicker_getProductList: (filter: any) => dispatch(ProductActions.getProductList(filter)),
  resetAllFilters: () => dispatch(UIAction.resetAllFilters()),
  setSelectedDateRange: (dates: any) => dispatch(UIAction.setSelectedDateRange(dates)),
  setSelectedHistoryDate: (date: any) => {}, //dispatch(UIAction.setSelectedHistoryDate(date)),
  setMoreFilterValue: (payload: any) => dispatch(UIAction.setMoreFilterValue(payload)),
  setSelectedExpiryDate: (expiryDate: any) => dispatch(UIAction.setSelectedExpiryDate(expiryDate)),
  tenantInventoryClearAction: () => {},//dispatch(InventoryManagementActions.tenantInventoryClearAction()),

  clearInventoryList: () => {dispatch(InventoryActions.clearInventoryList()); dispatch(DashboardActions.clearDashboard());},// TODO put a clearDashboard function in shared components
  clearWareHouseData: () => dispatch(ReportActions.clearWareHouseData()),
  clearRegionGraphData: () => dispatch(ReportActions.clearRegionGraphData()),
  clearProductExpirationData: () => dispatch(ProductExpirationAction.clearProductExpirationData()),
  clearTransactions: () => dispatch(TransactionActions.clearTransactions()),
  clearUserList: () => dispatch(UserManagementActions.clearUserList()),
});


const GlobalFiltersComponentContainer = connect(
  mapStateToProps, 
  mapDispatchToProps
)(GlobalFiltersComponent);

export default GlobalFiltersComponentContainer;

