import createReducer from './createReducer';
import { TRANSACTION } from '../constants/actions';
import { TransactionActions } from '../actions';


export const transaction = createReducer({}, {

  [TRANSACTION.SET_TRANSACTIONS](state = {}, action: ReturnType<typeof TransactionActions.setTransactions>) {
    return { ...state, list: action.payload }
  },

  [TRANSACTION.CLEAR_TRANSACTIONS](state = {}, action: ReturnType<typeof TransactionActions.clearTransactions>) {
    return { ...state, list: [] }
  },

});