import * as React from 'react';
import {
  makeStyles, createStyles, Theme,
  TextField, Button, FormControl, Select, MenuItem, InputLabel, Paper, Chip, Zoom, FormControlLabel, Checkbox,
  Modal, IconButton
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { UserRecord, Role, Claims } from '../../redux/models/user/user';
import AuthUser from '../../redux/lib/authUser';
import { UserManagementActions, SupplierActions } from '../../redux/actions';
import { connect } from 'react-redux';
import { HighlightOff } from '@material-ui/icons';
import { nameRegex, emailRegex, getUserResources, getUserRoles } from './users-form.helper';
import { Supplier } from '../../components/autoComplete';
import CloseIcon from '@material-ui/icons/Close';
import ScheduledReportsContainer from  '../scheduledReports/scheduled-reports.container';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    maxHeight:'100%',
    overflowX: 'scroll' as 'scroll'
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #e0e0e0',
      borderRadius: '3px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    input: {
      margin: '0.5em',
      minWidth: '125px'
    },
    addPermBtn: {
      margin: '10px 0 0 10px',
      backgroundColor: '#558db1 !important'
    },
    cancelBtn: {
      margin: '10px 0 0 10px',
      backgroundColor: 'rgba(0, 0, 0, 0.26) !important',
      color: 'white'
    },
    permPaper: {
      marginTop: '1em',
      maringBottom: '0.5em',
      padding: '1em',
      '& h3': {
        margin: '0px 0 0.5em 0'
      }
    },
    deletePermIcon: {
      fontSize: '1.9em',
      color: '#9a9a9a',
      verticalAlign: 'middle',
      marginLeft: '0.3em',
      cursor: 'pointer'
    },
    permissionsError: {
      color: '#f44336',
      fontWeight: 'bold'
    },
    createUserError: {
      color: '#f44336',
      fontWeight: 'bold'
    }
  })
)

interface UserFormProps {
  thisUser: UserRecord
  userExistsCheckResult?: UserRecord | string
  authUser?: AuthUser
  suppliersList: any[]
  isLoading: boolean
  error: boolean
 // searchTenants: (filter: any) => void
  createUser: (user: UserRecord) => void
  updateUser: (user: UserRecord) => void
  userExistsCheck: (email: string) => void
  clearExistingUser: () => void
  close: () => void
  getSupplierList: (keyword:string) =>void
  filterPayload:any;
}

const UserForm: React.FC<UserFormProps> = (props) => {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState(props.thisUser.firstName ?? '' as string);
  const [lastName, setLastName] = React.useState(props.thisUser.lastName ?? '' as string);
  const [email, setEmail] = React.useState(props.thisUser.email ?? '' as string);
  const [loadingUserCheck, setLoadingUserCheck] = React.useState(false);
  const [resources, setResources] = React.useState([] as string[]);
  const [roles, setRoles] = React.useState([] as string[]);
  const [claims, setClaims] = React.useState(props.thisUser.claims ?? {} as Claims);
  const [allTenants, setAllTenants] = React.useState(false as boolean);
  const [newClaimTenant, setNewClaimTenant] = React.useState('' as string);
  const [newClaimResource, setNewClaimResource] = React.useState('' as string);
  const [newClaimRole, setNewClaimRole] = React.useState('' as string);
  const [submitted, setSubmitted] = React.useState(false as boolean);
  const [formErrors, setFormErrors] = React.useState({} as any);
  const isFirstRender = React.useRef(true);
  const [createScheduledReportsForUserModalOpen, setCreateScheduledReportsForUserModalOpen] = React.useState(false);
  const [currentUserWhoseReportsAreBeingEdited, setCurrentUserWhoseReportsAreBeingEdited] = React.useState(null);

  React.useEffect(() => {
    if (props.isLoading) {
      setSubmitted(true);
    }
    if (submitted && !props.isLoading && !props.error) {
      props.close();
    }
    if (loadingUserCheck) {
      if (props.userExistsCheckResult) {
        if (props.userExistsCheckResult === 'User does not exist') {
          if (formErrors.email) {
            const errors = Object.assign({}, formErrors);
            delete errors.email;
            setFormErrors(errors);
          }
        }
        if (props.userExistsCheckResult === 'User already exists') {
          setFormErrors({...formErrors, email: 'A user with this email already exists'})
        }
        props.clearExistingUser();
        setLoadingUserCheck(false);
      }
    }
  }, [props, submitted,formErrors,loadingUserCheck]);

  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      let payload:any = {};
      props.getSupplierList(JSON.stringify(payload));
    }
  })

  const searchSupplierOnTyping = (keyword?: any) => {
    let payload:any = {};
    payload.where = (keyword.length > 0) ? { "supplierName": keyword, "supplierCode": keyword } : undefined;
    props.getSupplierList(JSON.stringify(payload));
  };

  const handleTextFieldChange = (event: any, field: string) => {
    if (field === 'firstName') {
      setFirstName(event.target.value);
      setFormErrors({ ...formErrors, firstName: false });
    }
    if (field === 'lastName') {
      setLastName(event.target.value);
      setFormErrors({ ...formErrors, lastName: false });
    }
    if (field === 'email') {
      setEmail(event.target.value);
      setFormErrors({ ...formErrors, email: false });
    }
    setSubmitted(false);
  }

  const selectAllTenants = (event: any) => {
    const isChecked = event.target.checked ? true : false;

    if (isChecked) {
      setNewClaimTenant('ALL');
      setResources(getUserResources(props.authUser?.record.claims, 'ALL'));
    }
    if (!isChecked) {
      setNewClaimTenant('');
      setNewClaimResource('');
      setNewClaimRole('');
      setResources([]);
      setRoles([]);
    }
    setAllTenants(isChecked);
  }

  const handleTenantSelect = (event: any, value: any) => {
    if (!value) {
      setNewClaimTenant('');
      setResources([]);
      return;
    }
    setNewClaimTenant(value.supplierCode);
    setResources(getUserResources(props.authUser?.record.claims, value.supplierCode));
  }

  const handleResourceSelect = (event: any) => {
    const value = event.target.value ?? ''
    if (!value) {
      setNewClaimResource('');
      setRoles([]);
      return;
    }
    setNewClaimResource(value);
    setRoles(getUserRoles(props.authUser?.record.claims, newClaimTenant, value));
  }

  const handleRoleSelect = (event: any) => {
    const value = event.target.value ?? '';
    if (!value) {
      setNewClaimRole('');
      return;
    }
    setNewClaimRole(value);
  }

  const handleAddClaim = () => {
    if (!newClaimTenant || !newClaimResource || !newClaimRole) {
      return;
    }

    let newClaims = Object.assign({}, claims);
    if (!newClaims[newClaimTenant]) {
      newClaims[newClaimTenant] = {};
    }
    newClaims[newClaimTenant][newClaimResource] = newClaimRole as Role;
    setClaims(newClaims);
    setFormErrors({ ...formErrors, claims: false });
    setSubmitted(false);
  }

  const handleDeleteTenantPerms = (tenantId: string) => {
    let newClaims = Object.assign({}, claims);
    delete newClaims[tenantId];
    setClaims(newClaims);
    setSubmitted(false);
  }

  const handleDeletePerm = (tenantId: string, resource: string) => {
    let newClaims = Object.assign({}, claims);
    delete newClaims[tenantId][resource];
    if (Object.keys(newClaims[tenantId]).length === 0) {
      delete newClaims[tenantId];
    }
    setClaims(newClaims);
  }

  const handleUserExistsCheck = () => {
    if (props.thisUser.email && props.thisUser.email === email) {
      return
    }
    props.userExistsCheck(email)
    setLoadingUserCheck(true);
  }

  const submitUserForm = () => {
    if (!formIsValid()) {
      return;
    }

    const userData: UserRecord = { ...{}, firstName: firstName, lastName: lastName, email: email, claims: claims }

    // console.log('userData----------------------------',userData);
    if (props.thisUser.id) {
      userData.id = props.thisUser.id;
      let payload:any = {userData:userData,filterPayload:props.filterPayload};
      props.updateUser(payload);
      return;
    }
    let payload:any = {userData:userData,filterPayload:props.filterPayload};
    props.createUser(payload);
  }

  const formIsValid = () => {
    let errors: any = {};

    if (!firstName || !nameRegex.test(firstName)) {
      errors.firstName = 'A valid first name is required';
    }

    if (!lastName || !nameRegex.test(lastName)) {
      errors.lastName = 'A valid last name is required';
    }

    if (formErrors.email) {
      errors.email = formErrors.email;
    }

    if (!email || !emailRegex.test(email)) {
      errors.email = 'A valid email is required';
    }

    if (Object.keys(claims).length === 0) {
      errors.claims = 'User permissions must be set';
    }

     setFormErrors(errors);

    return Object.keys(errors).length === 0;
  }

  const handleScheduledReportsForUserModalClose = () => {
    setCreateScheduledReportsForUserModalOpen(false);
  }

  const openScheduledReportsForUserModal = () => {
    // console.log('opening reports editor for:', userToEdit);
    // setCurrentUserWhoseReportsAreBeingEdited(userToEdit);
    setCreateScheduledReportsForUserModalOpen(true);
  }

  const getEditAnotherUsersReportsModal = (userToEdit: any) => {
    return <ScheduledReportsContainer thirdPartyUserBeingEdited={userToEdit} />
  }


  return (
    <>
      <div style={getModalStyle()} className={classes.paper}>
        <div>
          <h2>{props.thisUser.id ? 'Update' : 'Create New'} User</h2>
          <form noValidate autoComplete="off">
            <FormControl className={classes.input}>
              <TextField
                label="Email"
                variant="outlined"
                value={email}
                error={formErrors.email ? true : false}
                helperText={formErrors.email ? formErrors.email : ''}
                onChange={(event) => { handleTextFieldChange(event, 'email') }}
                onBlur={handleUserExistsCheck}
                required
              />
            </FormControl>
            <FormControl className={classes.input}>
              <TextField
                label="First Name"
                variant="outlined"
                value={firstName}
                error={formErrors.firstName ? true : false}
                helperText={formErrors.firstName ? formErrors.firstName : ''}
                onChange={(event) => { handleTextFieldChange(event, 'firstName') }}
                required
              />
            </FormControl>
            <FormControl className={classes.input}>
              <TextField
                label="Last Name"
                variant="outlined"
                value={lastName}
                error={formErrors.lastName ? true : false}
                helperText={formErrors.lastName ? formErrors.lastName : ''}
                onChange={(event) => { handleTextFieldChange(event, 'lastName') }}
                required
              />
            </FormControl>
            <h3>User Permissions</h3>
            {props.authUser?.authorizationCheck({ action: 'create', resource: 'userManagement', tenantId: 'ALL' }) &&
              <div>
                <FormControlLabel
                  control={<Checkbox color="default" checked={allTenants} onChange={selectAllTenants} name="allTenantsSelected" />}
                  label="All Suppliers"
                />
                <br></br>
              </div>
            }
            {!allTenants &&
              <FormControl className={classes.input}>

                <Autocomplete
                  id="customer-combo-box"
                  options={props.suppliersList}
                  getOptionLabel={(option: any) =>
                    "(" + option.supplierCode + ") " + option.supplierName
                  }
                  style={{ width: 280 }}
                  onChange={handleTenantSelect}
                  renderInput={(params: any) =>
                    <TextField
                      value={newClaimTenant}
                      onChange={({ target }) => searchSupplierOnTyping(target.value)}
                      {...params}
                      label="Search Suppliers"
                      variant="filled"
                      helperText={!newClaimTenant && 'Please choose a supplier to grant permissions.'}
                    />
                  }
                />


              </FormControl>
            }
            <FormControl variant="filled" className={classes.input}>
              <InputLabel id="resource-select">Resource</InputLabel>
              <Select
                labelId="resource-select"
                value={newClaimResource}
                onChange={handleResourceSelect}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value={''}>
                  <em>None</em>
                </MenuItem>
                {resources.map((resource, index) => {
                  return <MenuItem key={index} value={resource}>{resource.charAt(0).toUpperCase() + resource.slice(1)}</MenuItem>
                })}
              </Select>
            </FormControl>
            <FormControl variant="filled" className={classes.input}>
              <InputLabel id="role-select">Role</InputLabel>
              <Select
                labelId="role-select"
                value={newClaimRole}
                onChange={handleRoleSelect}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value={''}>
                  <em>None</em>
                </MenuItem>
                {roles.map((role, index) => {
                  return <MenuItem key={index} value={role}>{role}</MenuItem>
                })}
              </Select>
            </FormControl>
            <FormControl className={classes.input}>
              <Button
                className={classes.addPermBtn}
                variant="contained"
                color="secondary"
                onClick={handleAddClaim}>
                ADD
              </Button>
            </FormControl>
            {formErrors.claims && <div className={classes.permissionsError}>{formErrors.claims}</div>}
            {Object.keys(claims).map((tenant) => {
              return (
                <Zoom key={tenant} in={tenant ? true : false} timeout={500}>
                  <Paper className={classes.permPaper}>
                    <h3>
                      {tenant}
                      <HighlightOff
                        className={classes.deletePermIcon}
                        onClick={() => {
                          handleDeleteTenantPerms(tenant)
                        }} />
                    </h3>
                    {Object.keys(claims[tenant]).map((resource, index) => {
                      return (
                        <FormControl key={index} className={classes.input}>
                          <Chip
                            label={`${resource}: ${claims[tenant][resource]}`}
                            onDelete={() => {
                              handleDeletePerm(tenant, resource)
                            }}
                          />
                        </FormControl>
                      )
                    })}
                  </Paper>
                </Zoom>
              )
            })}
            <FormControl className={classes.input}>
              <Button
                className={classes.addPermBtn}
                variant="contained"
                color="secondary"
                onClick={openScheduledReportsForUserModal}
              >
                Create scheduled report for this user
              </Button>
            </FormControl>
            <br></br>
            {submitted && props.error && <p className={classes.createUserError}>Failed to {props.thisUser.id ? 'update' : 'create'} user.</p>}
            <FormControl className={classes.input}>
              <Button
                className={classes.addPermBtn}
                variant="contained"
                color="secondary"
                onClick={submitUserForm}>
                SUBMIT
              </Button>
            </FormControl>
            <FormControl className={classes.input}>
              <Button
                className={classes.cancelBtn}
                variant="contained"
                color="default"
                onClick={props.close}>
                CANCEL
              </Button>
            </FormControl>
          </form>
        </div>
        {loadingUserCheck &&
          <div style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            position: 'absolute',
            top: 0,
            left: 0
          }}>
          </div>
        }
      </div>
      <Modal
        open={createScheduledReportsForUserModalOpen}
        onClose={() => handleScheduledReportsForUserModalClose()}
      >
        <div 
          style={{
            maxHeight: 600,
            padding: 30,
            overflow: 'auto'
          }}
        >
          
          <IconButton 
            aria-label="close"  
            onClick={handleScheduledReportsForUserModalClose}
            style={{
              position: 'absolute',
              right: 18,
              top: 18,
              color: '#aaa',
              backgroundColor: '#fff',
              fontWeight: 800
            }}
          >
            <CloseIcon />
        </IconButton>

          {getEditAnotherUsersReportsModal(props.thisUser)}
        </div>
      </Modal>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  isLoading: state.ui.isLoading,
  error: state.userManagement.error,
  userExistsCheckResult: state.userManagement.existingUser,
  suppliersList:
      state.supplier.suppliers && state.supplier.suppliers.result
        ? state.supplier.suppliers.result
        : [],
});

const mapDispatchToProps = (dispatch: any) => ({
  //searchTenants: (filter: any) => dispatch(TenantsActions.getTenantList(filter)),
  createUser: (userData: UserRecord) => dispatch(UserManagementActions.createUserAction(userData)),
  updateUser: (userData: UserRecord) => dispatch(UserManagementActions.updateUserAction(userData)),
  userExistsCheck: (email: string) => dispatch(UserManagementActions.userExistsCheckAction(email)),
  clearExistingUser: () => dispatch(UserManagementActions.clearUserCheckAction()),
  getSupplierList: (filter: any) => dispatch(SupplierActions.getSuppliers(filter)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserForm);