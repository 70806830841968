import React from "react";
import { connect } from 'react-redux';
import Highcharts from "highcharts/highmaps";
import { Grid } from '@material-ui/core';
import { ReportActions, UIAction } from "../../../redux/actions";
import proj4 from "proj4";
import markerClusters from "highcharts/modules/marker-clusters";
import MakeshiftDrawer from "./drawer"
import WarehouseDrawer from "./warehouseDrawer"
import Chart from "./chart"
import HC_more from "highcharts/highcharts-more"; //module
HC_more(Highcharts); //init module
markerClusters(Highcharts);
window.proj4 = window.proj4 || proj4;
window.Highcharts = Highcharts;

const MarkerGraph = (props: any) => {

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [tableFilter, setTableFilter] = React.useState({} as any);
  const isFirstRender = React.useRef(true);
  let [graphData, setGraphData] = React.useState(props.graphData || []);
  const [pagination, setPaginationData] = React.useState({
    offset: 0,
    limit: 50,
  });
  let [dataListType, setDataListType] = React.useState(props.dataListType);
  let [inventoryRowCount, setInventoryRowCount] = React.useState(0);
  const [selectedPlant, setSelectedPlant] = React.useState('');

  React.useEffect(() => {

    setTableFilter(props.filter)
    setDrawerOpen(props.tableDrawerOpen)
    setDataListType(props.dataListType);
    setSelectedPlant('');

  }, [props.tableDrawerRefresh]);

  const manageInventoryCount = (count: number) => {
    setInventoryRowCount(count)
  }

  const onChangeTableFilter = (filter: any) => {
    setTableFilter(filter)
  }

  React.useEffect(() => {
    setTableFilter(props.filter)
  }, [props.filter]);

  React.useEffect(() => {
    if (props.isWarehouseInventoryPage) {
      setGraphData(props.warehouseData);
    }

    if (isFirstRender.current) {
      Highcharts.charts.forEach((chart: any) => {
        if (chart) { chart.redraw(); }
      });
      isFirstRender.current = false
    }
  });

  const getClusterDetails = (clusterData: any[]) => {

    const clusterDataObj: any = { stateCode: null, cityCode: null, zipCode: null };
    const clusterDataLength = clusterData.length;
    const stateCode = (clusterData[0] && clusterData[0].options && clusterData[0].options.name) ? clusterData[0].options.name : null;
    const zipCode = (clusterData[0] && clusterData[0].options && clusterData[0].options.zipcode) ? clusterData[0].options.zipcode : null;
    const cityCode = (clusterData[0] && clusterData[0].options && clusterData[0].options.city) ? clusterData[0].options.city : null;
    let zipCount = 0;
    let cityCount = 0;
    
    clusterData.forEach((obj: any) => {
      if (obj.options.zipcode === zipCode) {
        zipCount = zipCount + 1;
      }
      if (obj.options.city === cityCode) {
        cityCount = cityCount + 1;
      }
    })

    clusterDataObj.stateCode = stateCode;
    if (clusterDataLength === cityCount) {
      clusterDataObj.cityCode = cityCode;
    }
    if (clusterDataLength === zipCount) {
      clusterDataObj.zipCode = zipCode;
    }

    return clusterDataObj;
  }

  const getWareHouseData = (sortOrder: string = "productLabelName ASC") => {
    getWareHouseDataList(selectedPlant, sortOrder)
  }


  const getWareHouseDataList = (plantId: string = "SCDC", sortOrder: string = "productLabelName ASC") => {
    setPaginationData({
      offset: 0,
      limit: pagination.limit,
    })
    setSelectedPlant(plantId);
    setDataListType(1);
    let payload: any = {};
    payload.supplierId = (props.wareHouseFilter.supplierId) ? props.wareHouseFilter.supplierId : undefined;
    payload.productGroup = (props.wareHouseFilter.productGroup) ? props.wareHouseFilter.productGroup : undefined;
    payload.plantId = plantId;
    payload.sortOrder = sortOrder
    props.getWareHouseDrawerData(payload);
  }


  const openDrawer = (event: any) => {
    setDataListType(2);
    const clusterData = (event.point.clusteredData) ? 
      event.point.clusteredData : 
        ((event.point.options) ? [{ options: event.point.options }] : []);

    let stateList: any = [];
    let cityList: any = [];
    let zipList: any = [];
    clusterData.forEach((item: any) => {
      if (item.options && item.options.name) {
        let index = stateList.findIndex((value: any) => value === item.options.name);
        if (index < 0) {
          stateList.push(item.options.name);
        }
        let zipIndex = zipList.findIndex((value: any) => value === item.options.zipcode);
        if (zipIndex < 0) {
          zipList.push(item.options.zipcode);
        }
      }
    })
    const clusterDetails = getClusterDetails(clusterData);

    if (clusterDetails.stateCode) {
      props.showMiniLoader(true);
      let graphFilter: any = {};
      graphFilter.state = (stateList && stateList.length > 0) ? stateList : undefined;
      graphFilter.postcode = (zipList && zipList.length > 0) ? zipList : undefined;
      graphFilter.supplierId = (props.filter.supplierId) ? props.filter.supplierId : undefined;
      graphFilter.productGroup = (props.filter.productGroup) ? props.filter.productGroup : undefined;
      setTableFilter(graphFilter);
      let fields: any = { productLabelName: true, ndc: true };
      
      if (graphFilter.productItemId) {
        fields.productLabelName = false;
        fields.ndc = false;
      }
      if (graphFilter.state) {
        fields['state'] = true;
        fields['city'] = true;
      }
      if (graphFilter.postcode) {
        fields['lot'] = true;
        fields['postcode'] = true;
        fields['customerName'] = true;
        fields['gpo'] = true;
        fields['idn'] = true;
      }
      let inventoryFilter: any = {
        where: graphFilter,
        fields: fields,
        order: 'state'
      };
      let inventoryPayload = [{ key: 'filter', value: JSON.stringify(inventoryFilter)}]
      if(props.selectedFilterValues['REGION']) inventoryPayload.push({ key: 'regionName', value: props.selectedFilterValues['REGION']})
      setPaginationData({
        offset: 0,
        limit: pagination.limit,
      })
      props.getInventoryList(inventoryPayload);
    }
  }

  React.useEffect(() => {
    Highcharts.charts.forEach((chart: any) => {
      if (chart) { chart.reflow(); }
    });
  }, [drawerOpen]);

  const toggleDrawer = (open: boolean) => {
    props.menuDrawerOpen(!open);
    setDrawerOpen(open);
  }

  const changePaginationOffset = (offset:number) => {
    setPaginationData({
      offset: offset,
      limit: pagination.limit,
    })
  }
  return (
    <Grid container spacing={2} >
      <Grid item xs={12} style={{ border: '1px solid #f2f2f2' }}>
        <Chart 
          changePaginationOffset={changePaginationOffset} 
          onChangeTableFilter={onChangeTableFilter} 
          filter={props.filter} 
          openDrawer={openDrawer} 
          getClusterDetails={getClusterDetails} 
          getWareHouseDataList={getWareHouseDataList} 
          isWarehouseInventoryPage={props.isWarehouseInventoryPage}
        />
      </Grid>
      {(drawerOpen 
        && (props.graphData && props.graphData.length > 0 || props.warehouseData && props.warehouseData.length)) &&
        < Grid item xs={12} style={{ overflow: 'hidden', paddingTop: 0, textAlign: 'center' }}>
          {(dataListType === 2 && !props.isWarehouseInventoryPage) ?
            <MakeshiftDrawer 
              inventoryRowCount={inventoryRowCount} 
              manageInventoryCount={manageInventoryCount} 
              pagination={pagination} 
              open={drawerOpen} 
              toggleDrawer={toggleDrawer} 
              tableFilter={tableFilter} 
            />
            : 
            <WarehouseDrawer 
                selectedPlant={selectedPlant} 
                pagination={pagination} 
                open={drawerOpen} 
                toggleDrawer={toggleDrawer} 
                tableFilter={props.wareHouseFilter} 
                getWareHouseData={getWareHouseData}
              />
          }

        </Grid>
      }
    </Grid >
  );
}
const mapStateToProps = (state: any) => (
  {
    graphData: state.report.graphData ? state.report.graphData : [],
    inventoryData:
      state.report.inventoryData && state.report.inventoryData.result
        ? state.report.inventoryData.result
        : [],
    totalInventoryCount:
      state.report.inventoryData && state.report.inventoryData.totalRecord
        ? state.report.inventoryData.totalRecord
        : 0,
    warehouseData: state.report.warehouseData ? state.report.warehouseData : [],
    selectedFilterValues: state.ui.selectedFilterValues || {},
  }
);

const mapDispatchToProps = (dispatch: any) => ({
  getInventoryList: (filter: any) => dispatch(ReportActions.getDrawerData(filter)),
  showMiniLoader: (isLoader: boolean) => dispatch(UIAction.showMiniLoader(isLoader)),
  getWareHouseDrawerData: (filter: any) => dispatch(ReportActions.getWareHouseDrawerData(filter)),
});


export default connect(mapStateToProps, mapDispatchToProps)(MarkerGraph);
