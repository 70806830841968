import { makeStyles } from '@material-ui/core/styles';
export const CommonStyles = makeStyles((theme) => ({

    root: {
        '& > .fa': {
            margin: theme.spacing(2),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },

    formControl: {
        margin: theme.spacing(1),
        marginLeft: 0,
        marginRight: '10px!important',

    },
    floatRight: {
        float: 'right'
    },
    customerSearchWrap: {
        'margin-bottom': '2em',
        padding: 9,
        background: '#fff',
    },
    searchBox: {
        padding: 9,
        border: '1 solid #f2f2f2',
        marginBottom: '10px!important',
        background: '#fff',
    },
    viewAllBtn: {
        margin: '8px 0 0 10px !important',
        fontSize: '10px !important',
    },
    addMoreButton: {
        width: '40px!important',
        fontSize: '10px!important',
        marginTop: '5px!important'
    },
    dateRange: {
        width: '75%',
        position: 'fixed',
        zIndex: 9999
    },
    expansionHeading: {
        fontWeight: 'bold',
        flexBasis: '40%',
        flexShrink: 0,
        fontSize: 14
    },
    expansionSecondary: {
        fontSize: 14
    },
    viewAllActiveBtn: {
        margin: '8px 0 0 10px !important',
        fontSize: '10px !important',
    },
    labelButton: {
        marginTop: '5px!important'
    },
    groupByWrap: {
        marginBottom: '20px',
    },
    groupByHeading: {
        marginBottom: '10px',
        fontWeight: 600
    },
    groupByLinks: {
        margin: '0 20px 0 0 !important'
    },
    tableHeader: {
        paddingLeft: '24px'
    },
    icons: {
        color: '#558db1'
    },
    tableTreeCell: {
        display: 'flex',
        alignItems: 'center',
        maxHeight: '34px'
    },
    includeWarehouse: {
        marginTop: '20px',
        background: 'rgb(242, 242, 242)',
        float: 'right',
        paddingRight: '12px',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        backgroundColor: '#e0e0e0',
        '& > .MuiTypography-root':{
            fontSize:'14px!important'
        }
    },
    rightAlignFormControl: {
        margin: theme.spacing(1),
        marginLeft: 0,
        marginRight: '0px!important',

    },
    defaultButton: {
        backgroundColor: '#558db1 !important',
    },
    chipStyle:{
        marginRight:5
    },
    centerButton: {
        margin:'0 auto'
    },
    topBackButton:{
        textAlign:'right',
        marginBottom:20
    }
    
}));
export default CommonStyles;