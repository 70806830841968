import React from "react";
import { connect } from "react-redux";

import { useParams } from "react-router-dom";
import { CommonStyles } from "../../../hooks/styles";
import { SupplierRegionsAction } from "../../../redux/actions";
import RegionList from "./regionList";
import EmptyContent from "../../../components/emptyContent";
import { UIAction } from "../../../redux/actions";



const DisplayRegions = (props: any) => {
	const { supplierId }: any = useParams();
	const isFirstRender = React.useRef(true);
	const classes = CommonStyles();

	const fetchRegions = () => {
		let filter: any = {};
		filter.supplierCode =
			props.selectedSupplier && props.selectedSupplier.supplierCode ? props.selectedSupplier.supplierCode : supplierId;
		props.getSupplierRegionsList(filter);
	};
	React.useEffect(() => {
		if (props.suppliersList.length > 0) {
			if (isFirstRender.current && !props.selectedSupplier) {
				props.setSelectedSupplier(props.suppliersList[0]);
				isFirstRender.current = false;
			}
		}
	}, [props.suppliersList]);

	React.useEffect(() => {
		if (props.selectedSupplier) {
			fetchRegions();
		}
	}, [props.selectedSupplier]);

	return (
		<div>
			{props.regionsStatesList && props.regionsStatesList.length > 0 ? (
				<div>
					<div style={{ minHeight: 10 }}></div>
					<RegionList onChangeMode={props.onChangeMode} editedRegion={props.region} />
				</div>
			) : (
				<div>
					<div className={classes.appBarSpacer}></div>
					<EmptyContent message="Select the filter criteria above to view Regions information."></EmptyContent>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	selectedSupplier: state.ui.selectedSupplier ? state.ui.selectedSupplier : null,
	authUser: state.user.authUser,
	regionsStatesList:
		state.supplierRegion &&
		state.supplierRegion.suppliersRegionsList &&
		state.supplierRegion.suppliersRegionsList.regionState
			? state.supplierRegion.suppliersRegionsList.regionState
			: [],
	suppliersList:
		state.supplier.suppliersList && state.supplier.suppliersList.result ? state.supplier.suppliersList.result : [],
});

const mapDispatchToProps = (dispatch: any) => ({
	getSupplierRegionsList: (filter: any) => dispatch(SupplierRegionsAction.getSupplierRegionsList(filter)),
	setSelectedSupplier: (data: any) => dispatch(UIAction.setSelectedSupplier(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DisplayRegions);
