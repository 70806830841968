import createReducer from './createReducer';
import { UserManagementActions } from '../actions';
import { USER_MANAGEMENT } from '../constants/actions';

export const userManagement = createReducer({}, {
  [USER_MANAGEMENT.LIST_RESPONSE](state = {}, action: ReturnType<typeof UserManagementActions.getUserListCompleteAction>) {
    return {...state, userList : action.payload }
  },
  [USER_MANAGEMENT.ERROR](state = {}, action: ReturnType<typeof UserManagementActions.userErrorAction>) {
    return { ...state, error: action.payload }
  },
  [USER_MANAGEMENT.USER_EXISTS_RESPONSE](state = {}, action: ReturnType<typeof UserManagementActions.userExistsCheckCompleteAction>) {
    return {...state, existingUser: action.payload}
  },
  [USER_MANAGEMENT.CLEAR_USER_CHECK](state = {}, action: ReturnType<typeof UserManagementActions.clearUserCheckAction>) {
    return {...state, existingUser: null}
  },
  [USER_MANAGEMENT.CLEAR_USER_LIST](state = {}, action: ReturnType<typeof UserManagementActions.clearUserList>) {
    return {...state, userList : action.payload }
  },
});