import React from 'react';
import { Alert } from '@material-ui/lab';
import { connect } from 'react-redux';
import { UserActions } from '../../redux/actions';
import { Snackbar } from '@material-ui/core';
import { isUndefined } from 'lodash';

type SnackbarProps = {
  snackbarMessage: string,
  isSnackbarOpen: boolean,
  setSnackbarOpen: Function,
  snackbarStatus: any,
  snackbarAutohide: any
}

const SnackbarComponent: React.FC<SnackbarProps> = (props) => {
  const handleClose = () => props.setSnackbarOpen(false);
  const messageWithBreaks = props.snackbarMessage ? props.snackbarMessage.split('\n') : [''];

  return (
    <Snackbar 
      open={props.isSnackbarOpen} 
      onClose={handleClose}
      autoHideDuration={props.snackbarAutohide}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity={props.snackbarStatus} style={{ width: '100%' }}>
        {messageWithBreaks.map((line) => <div>{line}</div>)}
      </Alert>
    </Snackbar>
  )
}

const mapStateToProps = (state: any) => ({
  snackbarMessage: state.user.snackbarMessage,
  snackbarStatus: state.user.snackbarStatus || 'info', //error, warning, info, success
  isSnackbarOpen: state.user.isSnackbarOpen,
  snackbarAutohide: isUndefined(state.user.snackbarAutohide) ? 5000 : state.user.snackbarAutohide //state.user.snackbarAutohide = null keeps open until user closes
})

const mapDispatchToProps = (dispatch: any) => ({
  setSnackbarOpen: (isOpen: boolean) => dispatch(UserActions.setSnackbarOpen(isOpen))
})

const SnackbarConnected = connect(mapStateToProps, mapDispatchToProps)(SnackbarComponent);
export default SnackbarConnected;
