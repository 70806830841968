import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText, Collapse, List } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { SideBarStyles } from '../../../hooks/styles';
import { Constants } from '../../../constants/Constants';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { UIAction,InventoryActions, ReportActions } from '../../../redux/actions';
import StorageIcon from '@material-ui/icons/Storage';
import ApartmentIcon from '@material-ui/icons/Apartment';
import Loading from '../../../components/loading'
import LanguageIcon from '@material-ui/icons/Language';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

const SideMenuBar = (props: any) => {
  const classes = SideBarStyles();
  const [open, setOpen] = React.useState(false);
  const isFirstRender = useRef(true);
  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  const navigateMenu = (title: string, url: string) => {
    props.dispatch(InventoryActions.clearInventoryList());
    props.dispatch(ReportActions.clearRegionGraphData());
    props.dispatch(ReportActions.clearWareHouseData());
    props.dispatch(UIAction.setApiError(null));
    props.dispatch(
      UIAction.setNavigationState({
        title: title,
        url: url,
      })
    );
  };

  const generateMenuActiveClass = (menuKey: string) => {
    return (props.pageNameKey && props.pageNameKey === menuKey)
      ? classes.menuItemActive
      : classes.menuItem;
  };

  /*
  * use key passed in to do highlighting
  * that way two links with same label won't "steal" each other's highlight
  */
  const getNavLink = (
    key: string,
    path: string,
    label: string,
    Icon: any | null
  ) => (
    <div key={key}>
      <Link className={classes.link} to={path}>
        <ListItem button className={generateMenuActiveClass(key)}>
          <ListItemIcon className={classes.menuItemIcon}>
            {Icon ? <Icon /> : ""}
          </ListItemIcon>
          <ListItemText
            primary={label}
            onClick={() => navigateMenu(label, path)}
          />
        </ListItem>
      </Link>
    </div>
  );

  const handleExpand = (navGroup: string) => {
    const navState = props.navState || {};
    const newState = props.open ? !navState[navGroup] : true;
    props.dispatch(UIAction.setNavState({ ...navState, [navGroup]: newState }));
  };

  if (props.userNav) {
    return (
      <div>
        {props.userNav.includes("dashboard") &&
          getNavLink(
            "dashboard",
            "/dashboard",
            Constants.PAGE_TITLE.DASHBOARD,
            DashboardIcon
          )}
        {props.userNav.includes("inventory") && (
          <>
            <ListItem button onClick={() => handleExpand("inventory")}>
              <ListItemIcon
                style={{ minWidth: 40 }}
                onClick={() => props.setOpen(true)}
              >
                <StorageIcon />
              </ListItemIcon>
              <ListItemText primary="FDI Inventory" />
              {props.navState?.inventory ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={props.open && props.navState?.inventory}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {getNavLink(
                  "inventory",
                  "/inventory",
                  Constants.PAGE_TITLE.INVENTORY,
                  null
                )}
                {props.userNav.includes("inventoryLocator") &&
                  getNavLink(
                    "inventoryLocator",
                    "/inventory-locator",
                    Constants.PAGE_TITLE.REGION_GRAPH,
                    null
                  )}
                {props.userNav.includes("productExpiration") &&
                  getNavLink(
                    "expiry",
                    "/expiry",
                    Constants.PAGE_TITLE.PRODUCT_EXPIRATION,
                    null
                  )}
              </List>
            </Collapse>
          </>
        )}
        {props.userNav.includes("inventory") && (
          <>
            <ListItem button onClick={() => handleExpand("warehouseInventory")}>
              <ListItemIcon
                style={{ minWidth: 40 }}
                onClick={() => props.setOpen(true)}
              >
                <ApartmentIcon />
              </ListItemIcon>
              <ListItemText primary="Warehouse Inventory" />
              {props.navState?.warehouseInventory ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse
              in={props.open && props.navState?.warehouseInventory}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {getNavLink(
                  "warehouseInventory",
                  "/warehouse-inventory",
                  Constants.PAGE_TITLE.WAREHOUSE_INVENTORY,
                  null
                )}

                {getNavLink(
                  "warehouseInventoryLocator",
                  "/warehouse-inventory-locator",
                  Constants.PAGE_TITLE.WAREHOUSE_INVENTORY_LOCATOR,
                  null
                )}
              </List>
            </Collapse>
          </>
        )}
        {props.userNav.includes("inventory") &&
          getNavLink(
            "transactions",
            "/transactions",
            Constants.PAGE_TITLE.TRANSACTIONS,
            AttachMoneyIcon
          )}
        {props.userNav.includes("region") &&
          getNavLink(
            "regions",
            "/regions",
            Constants.PAGE_TITLE.REGIONS,
            LanguageIcon
          )}
        {props.userNav.includes("userManagement") &&
          getNavLink(
            "users", 
            "/users", 
            Constants.PAGE_TITLE.USERS, PeopleIcon
        )}
        
        {getNavLink(
          "scheduledReports",
          "/scheduled-reports",
          Constants.PAGE_TITLE.SCHEDULED_REPORTS,
          AssignmentIcon
        )}
        {getNavLink(
          "providerProductConfigReport",
          "/provider-product-config",
          Constants.PAGE_TITLE.PROVIDER_PRODUCT_CONFIG,
          SupervisedUserCircleIcon
        )}
      </div>
    );
  }
  return (
    <div>
      <Loading message="Logging In..." />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  navState: state.ui.navState,
  activeMenu: state.ui.menuState,
  userNav: state.user.nav,
});
export default connect(mapStateToProps)(SideMenuBar);