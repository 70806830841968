import React,{useEffect,useRef} from 'react';
import { useDispatch } from 'react-redux';
import BasePage from '../common/base';
import { Container } from '@material-ui/core';
import { DashboardStyles } from '../../hooks/styles';
import {Constants} from "../../constants/Constants";
import ScheduledReportsContainer from  './scheduled-reports.container'; 

const ScheduledReports = () => {
  const classes = DashboardStyles();

  return (
    <div>
      <BasePage 
        title={Constants.PAGE_TITLE.SCHEDULED_REPORTS} 
        drawerOpen={true}
        pageNameKey="scheduledReports"      
      >
        <div className={classes.appBarSpacer}></div>
        <Container maxWidth={false} className={classes.container}>
          <ScheduledReportsContainer />
        </Container>
      </BasePage>
    </div>
  )
}

export default ScheduledReports;