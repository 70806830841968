import React from 'react';
import { connect } from 'react-redux';
import { Grid, Paper, Tab, Tabs } from '@material-ui/core';
import { DashboardStyles } from '../../hooks/styles'
import HeatChart from '../../components/highcharts/heatChart';
import { HeatChartObj } from '../../redux/models/dashboard';
import { NumberFormatterWithCommas } from '../../hooks/functions';
import Skeleton from '@material-ui/lab/Skeleton';
import { TextLabels } from '../../constants/dataList';
import { isArray } from 'highcharts';

interface HeatChartProps {
  consignment: any;
  bigQueryFilter: any;
  nonConsignment: any;
  revenue: any;
  stateInventoryLoader: boolean;
  inventoryRevenueLoader: boolean;
  regionInventory: any;
  showRegionInventoryLoader: boolean;
  userNav: any[];
}
enum CurrentInventory {
  STATE_INVENTORY = 'STATE_INVENTORY',
  REGION_INVENTORY = 'REGION_INVENTORY',
}

const HeatChartSection = (props: HeatChartProps) => {

  const classes = DashboardStyles();
  const [inventoryDisplay, setInventoryDisplay] = React.useState<CurrentInventory>(CurrentInventory.STATE_INVENTORY);
  const handleInventoryDisplay = (event: any, newValue: 'STATE_INVENTORY' | 'REGION_INVENTORY') => {
    if (newValue === inventoryDisplay) {
      return;
    }
    setInventoryDisplay(newValue as CurrentInventory);
  }

  const dollarSign: string = (props.bigQueryFilter && props.bigQueryFilter.valueType && props.bigQueryFilter.valueType === "1") ? '$' : '';
  
  const generateHeatMapData = (rowData: any, type?: any) => {
    let heatChartData: HeatChartObj[] = [];
    if (!isArray(rowData)) {
      return heatChartData
    }
    const wareHouseType: any = [{ state: 'CA', warehouse: 'SCDC' }, { state: 'TX', warehouse: 'TXDC' }, { state: 'NC', warehouse: 'NCDC' }]
    
    rowData.forEach((obj: any) => {
      if (obj.value && obj.value > 0) {
        let heatChartDataobject: HeatChartObj = {};
        heatChartDataobject.code = `us-${obj.state?.toLowerCase()}`;
        heatChartDataobject.value = parseInt(obj.value);
        heatChartData.push(heatChartDataobject);
      }
    });
    return heatChartData;
  }

  const createSkeleton = () => {
    return (
      <Skeleton variant="rect" width="100%" style={{ borderRadius: '1%' }} animation="wave">
        <div style={{ paddingTop: '52%' }} />
      </Skeleton>
    )
  }

  const generateInventoryByStateMapData = (rowData: any[]) => {

    return rowData.map((row: any) => (
      {
        code: `us-${row.state?.toLowerCase()}`,
        value: parseInt(row.value),
        cost: `$${NumberFormatterWithCommas(parseInt(row.cost))}`
      }
    ))
  }

  const inventoryByStateTooltipFormatter = (thisChart: any) => {
    return `<b>${TextLabels.SellableUnits}</b>: ${thisChart.point.value}<br/><b>Cost</b>: ${thisChart.point.cost}`;
  }

  const generateRevenueMapData = (rowData: any[]) => {

    return rowData.map((row: any) => (
      {
        code: `us-${row.state?.toLowerCase()}`,
        units: parseInt(row.value),
        value: parseInt(row.cost),
        cost: `$${NumberFormatterWithCommas(parseInt(row.cost))}`
      }
    ))
  }

  const inventoryRevenueTooltipFormatter = (thisChart: any) => {
    return `<b>Avg. Product Acquisition Cost</b>: ${thisChart.point.cost}<br/><b>Units</b>: ${thisChart.point.units}`;
  }

  return (
    <div>
      <Grid container spacing={2} >
        <Grid item xs={6}>
          <h4 className={classes.titleHeading}>Current Forward Deployed Inventory by State</h4>
          {(props.inventoryRevenueLoader) ? createSkeleton()
            : <Paper className={classes.gridPaper}>
              {props.consignment?.length && <HeatChart 
                tooltipFormatter={inventoryByStateTooltipFormatter} 
                consignment={(props.consignment) ? generateHeatMapData(props.consignment) : [{}]} 
                graphData={generateInventoryByStateMapData(props.consignment || [{}])} 
              />}
            </Paper>
          }
        </Grid>
        <Grid item xs={6}>
          <h4 className={classes.titleHeading}>Last 12 Months: Total Unit Usage &amp; Avg. Product Acquisition Cost</h4>
          {(props.inventoryRevenueLoader) ? createSkeleton()
            : <Paper className={classes.gridPaper}>
                {props.revenue?.length && props.consignment?.length && 
                  <HeatChart 
                    tooltipFormatter={inventoryRevenueTooltipFormatter} 
                    pointNumberSign={'$'} 
                    graphData={(props.revenue) ? generateRevenueMapData(props.revenue) : []}
                    consignment={(props.consignment) ? generateHeatMapData(props.consignment) : []}             
                /> 
              }
            </Paper>
          }
        </Grid>
      </Grid>
    </div >
  )
}
const mapStateToProps = (state: any) => (
  {
    consignment: (state.dashboard.stateInventoryData && state.dashboard.stateInventoryData.consignment) ?
      state.dashboard.stateInventoryData.consignment : [],
    revenue: (state.dashboard.inventoryRevenueData) ? state.dashboard.inventoryRevenueData : [],
    stateInventoryLoader: (typeof state.dashboard.stateInventoryLoader === 'undefined') ? false : state.dashboard.stateInventoryLoader,
    inventoryRevenueLoader: (typeof state.dashboard.inventoryRevenueLoader === 'undefined') ? false : state.dashboard.inventoryRevenueLoader,
    showRegionInventoryLoader: (typeof state.dashboard.showRegionInventoryLoader === 'undefined') ? false : state.dashboard.showRegionInventoryLoader,
    nonConsignment: (state.dashboard.stateInventoryData && state.dashboard.stateInventoryData.nonConsignment) ?
      state.dashboard.stateInventoryData.nonConsignment : [],
    regionInventory: (state.dashboard && state.dashboard.regionInventory) ? state.dashboard.regionInventory : [],
    userNav: (state.user.nav) ? state.user.nav : [],
    isDashboardLoading: state.dashboard.isDashboardLoading || false
  }
);
export default connect(mapStateToProps)(HeatChartSection);
