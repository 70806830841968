import createReducer from './createReducer';
import { SupplierActions } from '../actions'
import { SUPPLIER } from '../constants/actions';


export const supplier = createReducer({}, {

  [SUPPLIER.SUCCESS_SUPPLIER_LIST](state = {}, action: ReturnType<typeof SupplierActions.successSupplierList>) {
    return { ...state, suppliersList: action.payload }
  },
  [SUPPLIER.SUCCESS_SUPPLIERS](state = {}, action: ReturnType<typeof SupplierActions.successSuppliers>) {
    return { ...state, suppliers: action.payload }
  },
  [SUPPLIER.SUCCESS_REGIONS_LIST](state = {}, action: ReturnType<typeof SupplierActions.successRegionsList>) {
    const regionList = action.payload.map((region: any) => {return {label: region.regionName, value:region.regionName}})
    return { ...state, regions: regionList }
  },
});