import { makeStyles } from '@material-ui/core/styles';
export const Styles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        marginLeft: 0,
        marginRight: '20px!important',
        minWidth: 200,
    },
    Btn: {
        marginTop:10,
        backgroundColor: '#558db1 !important',
        fontSize: '10px',
    },
    profileInput:{
        width:'300px',
        fontSize: '16px',
    },
    profileFormControl: {
        margin: theme.spacing(1),
        marginLeft: 0,
        marginRight: '5px!important',
        minWidth: 200,
    },
    profileBox:{
        boxShadow:'0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        padding:30,
        paddingBottom:50
    },
    inputLabel:{
        fontSize: '16px!important',
    },
    iconFormControl: {
        padding:16,
        margin: theme.spacing(1),
        marginLeft: 0,
        cursor:'pointer',
        color:'#558db1'
    },
    formLabel:{
        padding:20,
        color:'black',
        fontSize: '14px!important',
    },
    btnFormControl: {
        margin: theme.spacing(1),
        minWidth: 60,
    },
    permPaper: {
        marginTop: '1em',
        maringBottom: '0.5em',
        padding: '1em',
        '& h3': {
          margin: '0px 0 0.5em 0'
        }
      },
      deletePermIcon: {
        fontSize: '1.9em',
        color: '#9a9a9a',
        verticalAlign: 'middle',
        marginLeft: '0.3em',
        cursor: 'pointer'
      },
      input: {
        margin: '0.5em',
        minWidth: '125px'
      },
}));
export default Styles;