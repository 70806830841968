import React, { useEffect } from "react";
import { InputLabel, Select, MenuItem, ListSubheader, FormHelperText } from "@material-ui/core";
import { Styles } from "./styles";

interface selectBoxProps {

    listItems?: any[];
    inputLabel?: string;
    emptyItemLabel?: string;
    emptyItemvalue?: string;
    onChangeItem: (practice: any) => void;
    className?: string;
    disabled?: boolean;
    refresh?: any;
    style?: any;
    selected?: any;
    hideEmptyOption?: boolean;
    error?: boolean;
    helperText?: string;
    componentId?: string;
    getOptionLabel?: (option: any) => void;
    getOptionValue?: (option: any) => void;
}

const SelectBox = (props: selectBoxProps) => {

    const [selected, setSelected] = React.useState<any>(props.selected || ``);
    const classes = Styles();
    const onChangeItem = (event: any) => {
        if (event) {
            handleOnChange(event.target.value !== null && event.target.value !== "null" ? event.target.value : "")
        } else {
            handleOnChange("")
        }
    }

    React.useEffect(() => {
        if (props.refresh === true) {
            setSelected('')
        }
    }, [props.refresh])

    const handleOnChange = (value: any) => {
        setSelected(value)
        props.onChangeItem(value);
    }


    const onChangeItemCallback = React.useCallback(handleOnChange, []);
    useEffect(() => {
        onChangeItemCallback((props.selected) ? props.selected : null);
    }, [props.refresh, onChangeItemCallback, props.selected]);

    return (
        <span>
            <InputLabel id={(props.componentId)?props.componentId+'-input-label':'select-box-input-label'}>
                {props.inputLabel ? props.inputLabel : 'Select Box'}
            </InputLabel>
            <Select style={(props.style) && props.style} className={props.className ? props.className : classes.formControlSelect}
                disabled={props.disabled}
                labelId={(props.componentId)?props.componentId+'-label':'select-box-label'}
                id={(props.componentId)?props.componentId:'select-box'}
                label={props.inputLabel ? props.inputLabel : 'Select Box'}
                onChange={onChangeItem}
                value={(selected) ? selected : ''}
                error={props.error}
                //helperText={(props.error) && ((props.helperText) ? props.helperText : 'Please choose a product')}

            >
                {(props.hideEmptyOption && props.hideEmptyOption!== true) && <MenuItem value={props.emptyItemvalue ? props.emptyItemvalue : undefined}>{props.emptyItemLabel ? props.emptyItemLabel : 'Choose'}</MenuItem>
                }
                {props.listItems && props.listItems.map((item: any, key: number) => {
                    const optionLabel: string = (props.getOptionLabel) ? props.getOptionLabel(item) : item.label;
                    const optionValue: string = (props.getOptionValue) ? props.getOptionValue(item) : item.value;
                    return [
                        (item.listSubheader) ? <ListSubheader style={{ color: '#548db1' }}>{optionLabel}</ListSubheader> : <MenuItem value={optionValue}>{optionLabel}</MenuItem>,
                    ];
                })}
            </Select>
            {
            (props.error && props.helperText) && <FormHelperText >{props.helperText}</FormHelperText>
            }
        </span>
    )
}
export default SelectBox;

