import { createTheme } from '@material-ui/core/styles';

export const themeNames = {
  LIGHT: 'light',
  DARK: 'dark'
}

export const globalColors = {
  FFF_BLUE: '#51B8FF',//'#51b8e8', //was #6dadd2 
  FFF_DARK_BLUE: '#0064A1', //'#005988', //was #030e18 
  EMPHASIS_PURPLE: '#3f51b5',
  TABLE_WARNING: '#ff9999',
  DARK_GRAY: '#303030',
  MEDIUM_GRAY: '#424242',
  LIGHT_GRAY: '#616161',
  ERROR_RED: '#f44336',
  FFF_BLACK: '#1C2130', 
  FFF_ORANGE: 'FF6F19'
}

const breakpointVals = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

/***** component overrides applicable to all themes *****/
const globalOverrides = {
  MUIDataTable: {
    responsiveScroll: { 
      height: '600px' 
    }
  },
  MUIDataTableToolbar: {
    root: {
      paddingLeft: 16,
      minHeight: 'unset'
    }
  },
  MUIDataTableBodyCell: { 
    root: {
      fontSize: 12,
      backgroundColor: "transparent !important", 
      color: "inherit"
    }
  },
  MUIDataTableHeadCell: {
    root: {
      fontSize: 12
    },
    hintIconAlone: {
      fontSize: '1.2em !important',
      marginTop: '3px !important',
      color: '#757575'
    }
  },
  MUIDataTableSelectCell: {
    checked: {
      color: `${globalColors.FFF_BLUE} !important`
    },
  },
  MUIDataTableToolbarSelect: {
    root: {
      justifyContent: 'flex-start'
    }
  },
  MuiPickersToolbar: {
    toolbar: {
      backgroundColor: globalColors.FFF_BLUE,
    },
  }
};

const cssBaselineGlobals = {
  '.roleCheckboxWrapper .MuiFormControlLabel-label': {
    fontSize: '11px !important'
  },
  '.MuiTableHead-root .MuiButton-root, .groupByLink .MuiButton-root, .MuiCardHeader-root': {
    backgroundColor: 'inherit !important'
  },
  '.MuiCollapse-root .MuiPaper-root': {
    width: '100%'
  },
  '.MuiAccordionSummary-root': {
    minHeight: 60
  },
  'a': {
    color: 'inherit !important'
  },
  '.side-menu-bar .MuiListItem-gutters': {
    paddingRight: '6px !important'
  }
}

const buttonGlobals = {
  root: {
    color: '#fff',
    backgroundColor: globalColors.FFF_BLUE,
    '&:hover': {
      backgroundColor: globalColors.FFF_BLUE,
      '&.lightButton': {
        backgroundColor: '#fff'
      }
    },
    '&:disabled': {
      backgroundColor: globalColors.LIGHT_GRAY
    },
    '&.lightButton': {
      backgroundColor: '#fff',
    },
    '&.backButton': {
      fontSize: '10px',
      backgroundColor: globalColors.FFF_BLUE
    }
  },
  contained: {
    backgroundColor: globalColors.FFF_BLUE,
    '&.MuiButton-containedPrimary': {
      backgroundColor: globalColors.FFF_BLUE,
      '&:hover': {
        backgroundColor: globalColors.FFF_BLUE
      },
    },
    '&.MuiButton-containedSecondary': {
      backgroundColor: globalColors.FFF_BLUE,
      '&:hover': {
        backgroundColor: globalColors.FFF_BLUE
      },
    },
    '&:hover': {
      backgroundColor: globalColors.FFF_BLUE
    },
    '&:disabled': {
      backgroundColor: globalColors.LIGHT_GRAY
    }
  }
};

const themeDefinitions: any = {
  'light': {
    breakpoints: {
      values: {
        ...breakpointVals
      },
    },
    palette: {
      type: "light",
      primary: {
        main: globalColors.EMPHASIS_PURPLE
      },
      secondary: {
        main: globalColors.FFF_BLUE,
        contrastText: '#fff'
      },
      text: {
        primary: 'rgba(0, 0, 0, 0.87)',
        secondary: 'rgba(0, 0, 0, 0.54)'
      },
      background: {
        paper: '#fff',
        default: '#fafafa'
      }
    },
    overrides: {
      ...globalOverrides,
      MuiCssBaseline: {
        '@global': {
         ...cssBaselineGlobals,
         '.MuiTabs-indicator': {            
            backgroundColor: `${globalColors.FFF_DARK_BLUE} !important`
          },
          '.MuiTableHead-root .MuiButton-label, .groupByLink .MuiButton-label': {
            color: 'rgba(0, 0, 0, 0.87) !important'
          } 
        }
      },
      MuiAppBar: {
        root: {
          backgroundColor: `${globalColors.FFF_BLUE} !important`
        },
        primary: {
          main: globalColors.FFF_BLUE
        }
      },
      MuiContainer: {
        root: {
          backgroundColor: '#fafafa',
        },
      },
      MUIDataTableBodyRow: {
        root: {
          '&:nth-child(odd)': { 
            backgroundColor: '#eff4fb'
          },
          '&.mui-row-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.08) !important'
          }
        }
      },
      MuiList: {
        padding: {
          paddingTop: '5px',
          paddingBottom: '4px'
        }
      },
      MuiAvatar: {
        root: {
          border: '3px solid rgba(0, 0, 0, 0.54)',
          width: 50,
          height: 50
        },
        colorDefault: {
          color: 'rgba(0, 0, 0, 0.87)',
          backgroundColor: '#fff',
        }
      },
      MuiTabs: {
        root: {
          backgroundColor: globalColors.FFF_BLUE,
        }
      },
      MuiTab: {
        wrapper: {
          color: '#fff'
        } 
      },
      MuiButton: {
         ...buttonGlobals,
        label: {
          color: "#fff",
          '&.lightButton': {
            color: 'rgba(0, 0, 0, 0.87)'
          }
        }
      }
    }
  },

  'dark': {
    breakpoints: {
      values: {
        ...breakpointVals
      },
    },
    palette: {
      type: "dark",
      primary: {
        main: globalColors.FFF_BLUE,
      },
      secondary: {
        main: globalColors.FFF_BLUE,
        contrastText: '#fff'
      },
      text: {
        primary: '#fff',
        secondary: '#fff',

      },
      background: {
        paper: globalColors.FFF_DARK_BLUE,
        default: globalColors.DARK_GRAY
      }
    },
    overrides: {
      ...globalOverrides,
      MuiCssBaseline: {
        '@global': {
          ...cssBaselineGlobals,
          body: {
            backgroundColor: globalColors.DARK_GRAY,
          },
          '.MuiInput-underline:after': {
            borderColor: `${globalColors.FFF_BLUE} !important`
          }
        }
      },
      MuiContainer: {
        root: {
          backgroundColor: globalColors.DARK_GRAY,
        }
      },
      MuiFormLabel: {
        root: {
          '&.Mui-focused': {
            color: globalColors.FFF_BLUE
          }
        },
        wrapper: {
          color: globalColors.FFF_BLUE
        }
      },
      MuiGrid: {
        root: {
          color: '#fff'
        }
      },
      MuiAppBar: {
        root: {
          backgroundColor: `${globalColors.FFF_DARK_BLUE} !important`
        },
        primary: {
          main: globalColors.FFF_DARK_BLUE
        }
      },
      MuiAvatar: {
        root: {
          border: '3px solid #fff',
          width: 50,
          height: 50
        },
        colorDefault: {
          color: '#fff',
          backgroundColor: globalColors.FFF_DARK_BLUE,
        }
      },
      MUIDataTableBodyRow: {
        root: {
          '&:nth-child(odd)': { 
            backgroundColor: globalColors.MEDIUM_GRAY
          },
          '&:nth-child(even)': { 
            backgroundColor: globalColors.DARK_GRAY
          },
          '&.mui-row-selected': {
            backgroundColor: `${globalColors.FFF_DARK_BLUE} !important`
          }
        }
      },
      MuiPrivateTabIndicator: {
        root: {
          backgroundColor: '#fff'
        }
      },
      MuiTab: {
        wrapper: {
          color: '#fff'
        }
      },
      MuiListItem: {
        root: {
          color: '#fff'
        }
      },
      MuiButton: {
        ...buttonGlobals,
        label: {
          color: "#fff",
          '&.lightButton': {
            color: '#fff'
          }
        }
      }
    }
  }  
}

export const getMuiTheme = (themeVersion: string=themeNames.LIGHT) => {
  if (themeDefinitions[themeVersion]) {
    return createTheme(themeDefinitions[themeVersion]) as any;
  }
  else {
    console.error('Could not find theme definition:', themeVersion);
    return createTheme() as any;
  }
}


export default getMuiTheme;




