import { call, put } from 'redux-saga/effects';
import { SaveSearchActions, UIAction } from '../actions/index';
import { SaveSearchService } from '../services';
import { AuthCheck } from "../lib/authCheck";
import { Constants } from "../../constants/Constants";

export function* saveAdvanceSearch(action: ReturnType<typeof SaveSearchActions.saveAdvanceSearch>) {
    const operation:string = (action.payload && action.payload.uuid)?'UPDATE':'CREATE';
    try {
        yield put(SaveSearchActions.saveSearchError(null));
        yield put(UIAction.showLoader(true));
        yield call(SaveSearchService.getInstance().saveAdvanceSearch, action.payload);
        yield put(UIAction.showLoader(false));
        yield put(SaveSearchActions.saveSearchError({ message: 'Success', severity: 'info', title: "Saved",operation:operation }));
    } catch (err: any) {
        yield put(UIAction.showLoader(false));
        const errorMessage = err.response?.data?.error?.message?.message;
        yield put(SaveSearchActions.saveSearchError({ message: errorMessage, severity: 'error', title: "Error",operation:operation }));
    }
}

/*
* request to get current user's saved searches
*/
export function* getAdvanceSearch(action: ReturnType<typeof SaveSearchActions.getAdvanceSearch>) {
    try {
        yield put(UIAction.setApiError(null));
        yield put(UIAction.showLoader(true));
        const {data} = yield call(SaveSearchService.getInstance().fetchAdvanceSearch, action.payload);
        yield put(SaveSearchActions.setAdvanceSearch(data));
        yield put(UIAction.showLoader(false));
    } catch (err: any) {
        yield put(UIAction.showLoader(false));
    }
}

export function* scheduleSaveRequest(action: ReturnType<typeof SaveSearchActions.scheduleSaveRequest>) {
    const operation:string = 'UPDATE';
    try {
        yield put(SaveSearchActions.saveSearchError(null));
        yield put(UIAction.showLoader(true));
        yield call(SaveSearchService.getInstance().scheduleSaveRequest, action.payload);
        yield put(UIAction.showLoader(false));
        yield put(SaveSearchActions.saveSearchError({ message: 'Success', severity: 'info', title: "Saved",operation:operation }));
    } catch (err: any) {
        yield put(UIAction.showLoader(false));
        const errorMessage = (err.message)?err.message:err.response?.data?.error?.message?.message;
        yield put(SaveSearchActions.saveSearchError({ message: errorMessage, severity: 'error', title: "Error",operation:operation }));
    }
}













