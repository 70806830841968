import createReducer from './createReducer';
import { ProductExpirationAction } from '../actions'
import { PRODUCT_EXPIRATION } from '../constants/actions';


export const productExpiration = createReducer({}, {

  [PRODUCT_EXPIRATION.SUCCESS_EXPIRY_DATALIST](state = {}, action: ReturnType<typeof ProductExpirationAction.successExpiryDatalist>) {
    return { ...state, expiryDatalist: action.payload }
  },

  [PRODUCT_EXPIRATION.SUCCESS_GRAPH_DATA](state = {}, action: ReturnType<typeof ProductExpirationAction.successExpiryGraphData>) {
    return { ...state, expiryGraphData: action.payload }
  },

  [PRODUCT_EXPIRATION.SET_SELECTED_STATE](state = {}, action: ReturnType<typeof ProductExpirationAction.setSelectedState>) {
    return { ...state, selectedState: action.payload }
  },

  [PRODUCT_EXPIRATION.CLEAR_PRODUCT_EXPIRATION_DATA](state = {}, action: ReturnType<typeof ProductExpirationAction.clearProductExpirationData>) {
    return { ...state, expiryDatalist: [], expiryGraphData: [] }
  },

});