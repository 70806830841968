import createReducer from './createReducer';
import { ReportActions } from '../actions'
import { REPORT } from '../constants/actions';


export const report = createReducer({}, {

  [REPORT.REGION_GRAPH.REGION_GRAPH_DATA_SUCCESS](state = {}, action: ReturnType<typeof ReportActions.regionGraphDataSuccess>) {
    return { ...state, graphData: action.payload }
  },


  [REPORT.REGION_GRAPH.CLEAR_REGION_GRAPH_DATA](state = {}, action: ReturnType<typeof ReportActions.clearRegionGraphData>) {
    return { ...state, graphData: [] }
  },

 
  [REPORT.DRAWER_DATA.SUCCESS_DRAWER_DATA](state = {}, action: ReturnType<typeof ReportActions.successDrawerData>) {
    return { ...state, inventoryData: action.payload }
  },

  [REPORT.REGION_GRAPH.SUCCESS_WAREHOUSE_DATA](state = {}, action: ReturnType<typeof ReportActions.successWareHouseData>) {

    return { ...state, warehouseData: action.payload }
  },

  [REPORT.REGION_GRAPH.CLEAR_WAREHOUSE_DATA](state = {}, action: ReturnType<typeof ReportActions.clearRegionGraphData>) {
    return { ...state, warehouseData: [] }
  },

  [REPORT.DRAWER_DATA.SUCCESS_WAREHOUSE_DRAWER_DATA](state = {}, action: ReturnType<typeof ReportActions.successWareHouseDrawerData>) {
    return { ...state, inventoryWareHouseData: action.payload }
  },



});