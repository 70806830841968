import { call, put, delay } from 'redux-saga/effects';
import { InventoryActions, UIAction } from '../actions/index';
import { InventoryService } from '../services';
import { Constants } from "../../constants/Constants";
import { AuthCheck } from "../lib/authCheck";

export function* getInventoryList(action: ReturnType<typeof InventoryActions.getInventoryList>) {
    try {
        yield put(UIAction.showLoader(true));
        yield put(UIAction.setApiError(null));
        yield call(AuthCheck.getInstance().check,{ action: 'read', resource: 'inventory', tenantId: JSON.parse(action.payload).where.supplierCode });
        const { data } = yield call(InventoryService.getInstance().getInventory, action.payload);
        yield put(InventoryActions.successInventoryList(data));
        yield put(UIAction.showLoader(false));
        yield put(UIAction.showMiniLoader(false));
        if (!data || !data.result || data.result.length === 0) {
            yield put(UIAction.setApiError({ errorMessage: Constants.ALERT.NO_RECORD_FOUND, severity: 'info', title: "Info" }));
        }
    } catch (err: any) {
        yield put(UIAction.showMiniLoader(false));
        yield put(UIAction.showLoader(false));
        const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
        yield put(UIAction.setApiError({ errorMessage: errorMessage, severity: 'error', title: "Error" }));
        yield delay(Constants.ALERT.DELAY);
        yield put(UIAction.setApiError(null));
    }
}


export function* getInventoryDetailList(action: ReturnType<typeof InventoryActions.getInventoryDetailList>) {
    try {
        yield put(UIAction.showLoader(true));
        const { data } = yield call(InventoryService.getInstance().getInventory, action.payload);
        const payload: any = { response: data, request: action.payload };
        yield put(InventoryActions.successInventoryDetailList(payload));
        yield put(UIAction.showLoader(false));
    } catch (err: any) {
        yield put(UIAction.showLoader(false));
    }
}


export function* getInventory(action: ReturnType<typeof InventoryActions.getInventory>) {
    try {
        yield put(UIAction.showLoader(true));
        const { data } = yield call(InventoryService.getInstance().getInventory, action.payload);
        const payload: any = { response: data, request: action.payload };

        const emptyPayload: any = { response: [], request: action.payload };

        yield put(InventoryActions.successInventory(emptyPayload));
        yield put(InventoryActions.successInventory(payload));
        yield put(UIAction.showLoader(false));
    } catch (err: any) {
        yield put(UIAction.showLoader(false));
    }
}


export function* getGroupInventory(action: ReturnType<typeof InventoryActions.getInventory>) {
    try {
        yield put(UIAction.showLoader(true));
        const { data } = yield call(InventoryService.getInstance().getInventory, action.payload);
        const payload: any = { response: data, request: action.payload };
        yield put(InventoryActions.successGroupInventory(payload));
        yield put(UIAction.showLoader(false));
    } catch (err: any) {
        yield put(UIAction.showLoader(false));
    }
}


export function* getLotLevelInventory(action: ReturnType<typeof InventoryActions.getLotLevelInventory>) {
    try {
        yield put(UIAction.showLoader(true));
        const { data } = yield call(InventoryService.getInstance().getInventoryDetail, action.payload);
        const payload: any = { response: data, request: action.payload };
        yield put(InventoryActions.successLotLevelInventory(payload));
        yield put(InventoryActions.successCurrentInventory(data));
        yield put(UIAction.showLoader(false));
    } catch (err: any) {
        yield put(UIAction.showLoader(false));
    }
}


export function* getWareHouseInventory(action: ReturnType<typeof InventoryActions.getWareHouseInventory>) {
    try {
        yield put(UIAction.showLoader(true));
        const { data } = yield call(InventoryService.getInstance().getWareHouseInventory, action.payload);
        yield put(InventoryActions.successWareHouseInventory(data));
        yield put(UIAction.showLoader(false));
    } catch (err: any) {
        yield put(UIAction.showLoader(false));
    }
}

export function* getExportInventory(action: ReturnType<typeof InventoryActions.getExportInventory>) {
    try {
        yield put(UIAction.showLoader(true));
        const supplier = JSON.parse(action.payload).where.supplierCode;
        const { data } = yield call(InventoryService.getInstance().getExportInventory, action.payload);
        yield put(UIAction.showLoader(false));
        var blob = new Blob([data]);
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `inventory_report_${supplier}.csv`;
        link.click();
        return
    } catch (err: any) {
        yield put(UIAction.showLoader(false));
    }
}

export function* getRegionGroupInventory(action: ReturnType<typeof InventoryActions.getRegionGroupInventory>) {
    try {
        yield put(UIAction.showLoader(true));
        yield put(UIAction.setApiError(null));
        yield call(AuthCheck.getInstance().check,{ action: 'read', resource: 'inventory', tenantId: action.payload.supplierId });
        const { data } = yield call(InventoryService.getInstance().getRegionGroupInventory, action.payload);
        let inventory = [];
        if(data && data.length > 0){
            inventory = data.map((item:any) => {
                item['onHandQty'] = item.value;
                return item;
            })
        }
        yield put(InventoryActions.successRegionGroupInventory(inventory));
        yield put(UIAction.showLoader(false));
    } catch (err: any) {
        yield put(UIAction.showLoader(false));
        const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
        yield put(UIAction.setApiError({ errorMessage: errorMessage, severity: 'error', title: "Error" }));
        yield delay(Constants.ALERT.DELAY);
        yield put(UIAction.setApiError(null));
    }
}


export function* getParData(action: ReturnType<typeof InventoryActions.getParData>) {
    try {
        yield put(UIAction.showMiniLoader(true));
        const { data } = yield call(InventoryService.getInstance().fetchParData, action.payload);
        const payload: any = { response: data, request: action.payload };
        yield put(InventoryActions.successParData(payload));
        yield put(UIAction.showMiniLoader(false));
    } catch (err: any) {
        const payload: any = { response: [], request: action.payload };
        yield put(InventoryActions.successParData(payload));
        yield put(UIAction.showMiniLoader(false));
    }
}






