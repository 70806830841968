import { TenantsActions } from '../actions';
import { TENANTS } from '../constants/actions';
import createReducer from './createReducer';


export const tenants = createReducer({}, {

  [TENANTS.SET_CLASS_OF_TRADE_OPTIONS](state = {}, action: ReturnType<typeof TenantsActions.setClassOfTradeOptions>) {
    const classList = action.payload.map((classOfTrade: any) => {return {label: classOfTrade, value:classOfTrade}})
    return { ...state, classOfTradeOptions: classList }
  },

  [TENANTS.SET_FACILITY_TYPE_OPTIONS](state = {}, action: ReturnType<typeof TenantsActions.setFacilityTypeOptions>) {
    const facilityList = action.payload.map((facilityType: any) => {return {label: facilityType, value:facilityType}})
    return { ...state, facilityTypeOptions: facilityList }
  },

});