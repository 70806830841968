import { action } from 'typesafe-actions';
import { SAVE_SEARCH_ACTIONS } from '../constants/actions';
import { ResultSet } from '../models/common/result.set';

export const saveAdvanceSearch   = (data: any) => action(SAVE_SEARCH_ACTIONS.CREATE.REQUEST_CREATE_SEARCH, data);
export const saveSearchError   = (data: {message:string, severity:string, title:string,operation?:string} | null) => action(SAVE_SEARCH_ACTIONS.CREATE.ERROR_CREATE_SEARCH, data);

export const getAdvanceSearch   = (data: any) => action(SAVE_SEARCH_ACTIONS.LIST.REQUEST_LIST_SEARCH, data);
export const setAdvanceSearch   = (data: ResultSet<any>) => action(SAVE_SEARCH_ACTIONS.LIST.RESPONSE_LIST_SEARCH, data);

export const scheduleSaveRequest   = (data: any) => action(SAVE_SEARCH_ACTIONS.SCHEDULE.SCHEDULE_SAVE_REQUEST, data);


