

//import { TenantsFilter, TenantsList} from "../models/tenants/tenants";
import { GET_PRODUCT_EXPIRY_BIGQUERY, GET_PRODUCT_EXPIRY_LIST_BIGQUERY } from '../constants/api.url';
import { HttpResponse, ResultSet } from "../models/common/result.set";
import Api from "../lib/api";


export class ProductExpiryService {

    private static instance: ProductExpiryService;
    private constructor() { }

    public static getInstance(): ProductExpiryService {
      if (!ProductExpiryService.instance) {
        ProductExpiryService.instance = new ProductExpiryService();
      }
      return ProductExpiryService.instance;
    }

    public getExpiryGraphData = async (payload: any | null): Promise<HttpResponse<ResultSet<any>>> => {
      return await Api.get(`${GET_PRODUCT_EXPIRY_BIGQUERY.URL}`, payload)
    }
    

    public getExpiryDataList = async (payload: any | null): Promise<HttpResponse<ResultSet<any>>> => {
      return await Api.get(`${GET_PRODUCT_EXPIRY_LIST_BIGQUERY.URL}`, payload)
    }

    

}