import React from 'react';
import { connect } from 'react-redux';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highmaps";
import usAll from "../../../static/useAll";
import Annotations from 'highcharts/modules/annotations';

import { NumberFormatter, NumberFormatterWithCommas } from '../../../hooks/functions';
import proj4 from "proj4";
window.proj4 = window.proj4 || proj4;
window.Highcharts = Highcharts;
Annotations(Highcharts);
interface HeatChartProps {
    graphData: any;
    pointNumberSign?: string;
    nonConsignment?: any;
    tooltipFormatter?: any;
    consignment?: any;
}

const HeatChart = (props: HeatChartProps) => {

    const createTickInterval = (graphData: any) => {
        const maxValue = (graphData) ? Math.max.apply(Math, graphData.map(function (o: any) { return o.value; })) : 1;
        const tickInterval = Math.round(maxValue / 5);
        return tickInterval;
    }

    const defaultTooltipFormatter = (thisChart: any) => {
        let pointNumberSign: string = (props.pointNumberSign) ? props.pointNumberSign : '';
        let value: any = (thisChart?.point?.value) ? NumberFormatterWithCommas(thisChart.point.value) : 0;
        return `${pointNumberSign}${value}`;
    }
   
    const chartOption = {
        chart: {
            map: usAll,
            //panning: false,
            margin: [20, 20, 20, -80],
            events: {
                // render: function (this: any) {
                // }
            }
        },
        title: {
            text: ""
        },
        subtitle: {
            /*text:''*/
        },
        legend: {
            enabled: true,
            layout: 'vertical',
            align: 'right',
            floating: true,
            x: -40,
            backgroundColor: (
                Highcharts.defaultOptions &&
                Highcharts.defaultOptions.legend &&
                Highcharts.defaultOptions.legend.backgroundColor
            ) || 'rgba(255, 255, 255, 0.85)',
        },
        mapNavigation: {
            enableMouseWheelZoom: true,
            enableDoubleClickZoomTo: false,
            enabled: false,
            buttonOptions: {
                verticalAlign: 'bottom'
            }
        },


        colorAxis: [{
            maxColor: '#0063a1',
            minColor: '#51b8e8',
            tickInterval: createTickInterval(props.graphData),
            
            labels: {
                formatter: function (this: any) {
                    let pointNumberSign: string = (props.pointNumberSign) ? props.pointNumberSign : '';
                    let value: any = NumberFormatter(this.value, 1);
                    return `<span style="color:#51b8e8">${pointNumberSign}${value}<span>`;
                }
            }
        }],
        tooltip: {
            useHtml: true,
            formatter: function (this: any) {
                return props.tooltipFormatter ? props.tooltipFormatter(this) : defaultTooltipFormatter(this)
            }
        },
        plotOptions: {
            series: {
                turboThreshold: 0,
                dataLabels: {
                    enabled: false
                },
            },
        },
        series: [
            {
                //visible: false,
                index: 1,
                legend: {
                    enabled: false,
                },
                //colorAxis: 1,
                data: (props.consignment?.length && props.graphData?.length) ? props.consignment : [], //avoiding giving empty array *MAY* help avoid internal Highcharts render errors??
                joinBy: ['hc-key', 'code'],
                name: 'Heat Map Non Consignment',
                dataLabels: {
                    enabled: true,
                    useHTML: true,
                    allowOverlap: true,
                    formatter(this:any) {
                        if(this?.point?.id === 'us-nc' || this?.point?.id === 'us-ca' || this?.point?.id === 'us-tx'){
                            return '<i class="fas fa-warehouse" style="color:black"></i>';
                        }else{
                            return '';
                        }
                    }
                  },
                showInLegend: false,
                tooltip: {
                    valueSuffix: ''
                },
                borderWidth: 0.5,
                states: {
                    hover: {
                        color: '#a4edba'
                    }
                },
                shadow: false
            },
            {
                index: 2,
                showInLegend: false,
                data: (props.consignment?.length && props.graphData?.length) ? props.graphData : [], //avoiding giving empty array *MAY* help avoid internal Highcharts render errors??

                joinBy: ['hc-key', 'code'],
                name: 'Heat Map',
                tooltip: {
                    valueSuffix: ''
                },
                borderWidth: 0.5,
                states: {
                    hover: {
                        color: '#a4edba'
                    }
                },
                shadow: false
            },


            

        ],
        credits: {
            enabled: false
        },
        annotations: [{
            labelOptions: {
                backgroundColor: 'black',
                borderColor: '#0063a1',
                shape: 'connector',
                //align: 'left',
                justify: false,
                crop: false,
                verticalAlign: 'top',
                y: 55,
                style: {
                    stroke: '#0063a1',
                    fontSize: '10px',
                    textOutline: '1px white',
                    backgroundColor: 'black!important',
                },
                
            },
            labels: [{
                point: 'us-ca',
                text: '{point.text}'
            }, {
                point: 'us-tx',
                text: '{point.text}'
            },
            {
                point: 'us-nc',
                text: '{point.text}'
            }]
        }]

    };

    return (
       <>
            { props.consignment?.length && props.graphData?.length ? 
                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOption}
                    constructorType={"mapChart"}
                    containerProps={{ style: { height: "250px", width: '100%' } }}
                /> 
                : 
                <div style={{
                    margin: 30,
                    fontSize: 18,
                    fontWeight: 800
                }}>
                    No data.
                </div>
            }
        </>
    )
}
const mapStateToProps = (state: any) => (
    {}
);
export default connect(mapStateToProps)(HeatChart);