import React from 'react';
import { Alert, Skeleton } from '@material-ui/lab';
import { connect } from 'react-redux';

type EmptyContentProps = {
    message: string,
    apiError: any
}

const EmptyContentComponent: React.FC<EmptyContentProps> = (props) => {
    return (
        <div>
            {!props.apiError &&
                <div>
                    <Alert severity="info">{props.message}</Alert>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    apiError: state.ui.errorMessage
})

const EmptyContent = connect(mapStateToProps)(EmptyContentComponent);
export default EmptyContent;
