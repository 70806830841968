

//import { TenantsFilter, TenantsList} from "../models/tenants/tenants";
import { GET_TRANSACTIONS } from '../constants/api.url';
import { HttpResponse, ResultSet } from "../models/common/result.set";
import Api from "../lib/api";


export class TransactionService {

  private static instance: TransactionService;
  private constructor() { }

  public static getInstance(): TransactionService {
    if (!TransactionService.instance) {
        TransactionService.instance = new TransactionService();
    }
    return TransactionService.instance;
  }

  public getTransactions = async (filter: any | null): Promise<HttpResponse<ResultSet<any>>> => {
    // filter = {paramKey1: paramValue1, paramKey2: paramValue2, etc...};
    let paramString: string = '';
    filter && Object.keys(filter).forEach(function (key: any, index: number) {
      paramString += (index > 0) ? '&' + key + '=' + filter[key] : '?' + key + '=' + filter[key]
    })
    return await Api.get(`${GET_TRANSACTIONS.URL}${paramString}`, [])
  }

}