import firebase from '../../auth/firebase';
import config from '../../config';
import { UserService } from './user.service';

export class FirebaseUserService {

    private static instance: FirebaseUserService;
    private constructor() { }

    public static getInstance(): FirebaseUserService {
        if (!FirebaseUserService.instance) {
            FirebaseUserService.instance = new FirebaseUserService();
        }
        return FirebaseUserService.instance;
    }

    public getToken = async (): Promise<any> => {
        if (firebase.auth().currentUser) {
            const token = await firebase.auth().currentUser?.getIdToken();
			const uid = firebase.auth().currentUser?.uid;
			return { token: token, uid: uid };
        }

        const accessToken: string | null = localStorage.getItem('access_token')
        const userInfo: string | null = localStorage.getItem('user_info')
        if (!accessToken || !userInfo) {
            throw new Error('User is not authenticated.')
        }

        const customTokenRepsonse = await UserService.getInstance().getCustomToken({accessToken: accessToken})
        await firebase.auth().signInWithCustomToken(customTokenRepsonse.data.result);
        const token = await firebase.auth().currentUser?.getIdToken();
        const uid = firebase.auth().currentUser?.uid
        return { token: token, uid: uid };
    }

    public refreshIdToken = async (): Promise<any> => {
        return new Promise((resolve, reject) => {
            firebase.auth().onAuthStateChanged(async (user: any) => {
                if (!user) reject('Firebase user is not authenticated')
                const token = await user?.getIdToken(true);
                const uid = user?.uid;
                resolve({ token: token, uid: uid });
            })
        })
    }

    public setupUser = async (userInfo: any): Promise<any> => {
        const response = await firebase.functions()
            .httpsCallable('setupPingUser')(userInfo);
        console.log('PING SETUP RESPOSNE', response)
    }
}