import React from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { connect } from 'react-redux';
import MUIDataTable from "mui-datatables";
import { Waypoint } from "react-waypoint";
import { ReportActions, UIAction } from "../../../redux/actions";
import { Scrollbars } from 'react-custom-scrollbars';
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const WarehouseDrawer = (props: any) => {

  const classes = useStyles();
  const [showMore, setShowMore] = React.useState<boolean>(true);
  const [sortOrder, setSortOrder] = React.useState("productLabelName ASC");
  const [pagination, setPaginationData] = React.useState(props.pagination);

  let [inventoryWareHouseData, setInventoryWareHouseData] = React.useState(props.inventoryWareHouseData);

  React.useEffect(() => {
    props.getWareHouseData(sortOrder);
  }, [sortOrder, props.tableFilter])

  React.useEffect(() => {
    setInventoryWareHouseData(props.inventoryWareHouseData);
  }, [props.inventoryWareHouseData]);

  const customBodyRenderFunction = (value?: any, tableMeta?: any, updateValue?: any) => {
    if ('product_name' === tableMeta.columnData.name) {
      return (<div>
        <div>{value}</div>
        <div>({tableMeta.rowData[4]})</div>
      </div>);
    } else {
      return value;
    }
  }

  const getColumns = () => {
    const columns = [

      {
        label: "Product",
        name: "product_name",
      },
      {
        label: "Location",
        name: "name"
      },
      {
        label: "Lot",
        name: "lot"
      },
      {
        label: "On hand",
        name: "onHandQty"
      },
      {
        label: "Ndc",
        name: "ndc"
      },

    ];
    let columnsList = columns.map((item, index) => {
      return {
        name: item.name,
        label: item.label,
        options: {
          display: true,
          customBodyRender: customBodyRenderFunction
        }
      }
    });
    columnsList[4].options.display = false;
    return columnsList;
  }

  const options = {
    search: false,
    filter: false,
    download: false,
    print: false,
    selectableRows: 'none',
    serverSide: true,
    viewColumns: false,
    count: (props.totalInventoryCount),
    responsive: "vertical",
    fixedHeader: true,
    pagination: false,
    onTableChange: (tableAction: any, tableState: any) => {
      if (tableAction == 'sort') {
        setSortOrder(`${tableState.sortOrder.name} ${tableState.sortOrder.direction}`)
      }
    }
  };



  return (
    <Slide direction="left" in={props.open} >
      <div className={classes.root} style={{ maxHeight: 500, overflow: 'hidden' }} >
        
          <Scrollbars style={{ height: 450 }}>
              <MUIDataTable style={{ height: '400px' }}
                data={inventoryWareHouseData}
                columns={getColumns()}
                options={options}
              />
              {props.isMiniLoading && !props.isLoading &&
                <div><div style={{ textAlign: 'center' }}>
                  <div>Loading...</div>
                  <CircularProgress disableShrink />
                </div>

                </div>
              }
              {(!props.isMiniLoading && inventoryWareHouseData.length > 0 && props.inventoryWareHouseData.length == 0) && <h4 style={{ textAlign: 'center' }}>No more records found!</h4>}
          </Scrollbars>
        
      </div>
    </Slide>
  );
}

const mapStateToProps = (state: any) => (
  {
    inventoryWareHouseData:
      state.report.inventoryWareHouseData && state.report.inventoryWareHouseData
        ? state.report.inventoryWareHouseData
        : [],
    totalInventoryCount:
      state.report.inventoryData && state.report.inventoryData.totalRecord
        ? state.report.inventoryData.totalRecord
        : 0,
    isMiniLoading: state.ui.isMiniLoading,
    isLoading: state.ui.isLoading,
  }
);

const mapDispatchToProps = (dispatch: any) => ({
  showMiniLoader: (isLoader: boolean) => dispatch(UIAction.showMiniLoader(isLoader))
});

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseDrawer);