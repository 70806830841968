export const formatCabinetTypeForReport = (cabinetType: string) => {
	const cabinetTypes: any = {
    'EM': 'RightNow Ready',
    'RFID': 'RightNow Verified',
    'Virtual': 'RightNow Cloud',
    'WEIGHT': 'RightNow Flex',
    'VISION' : 'RightNow Secure'
  };

  return cabinetTypes[cabinetType] || ''
}