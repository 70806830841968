

import { SAVE_SEARCH,SAVE_SEARCH_SCHEDULE } from '../constants/api.url';
import Api from "../lib/api";


export class SaveSearchService {

  private static instance: SaveSearchService;
  private constructor() { }
  public static getInstance(): SaveSearchService {
    if (!SaveSearchService.instance) {
      SaveSearchService.instance = new SaveSearchService();
    }
    return SaveSearchService.instance;
  }

  public saveAdvanceSearch = async (payload: any | null): Promise<any> => {
    if(payload.uuid && payload.action && payload.action == 'DELETE'){
      const uuid = payload.uuid;
      return await Api.delete(`${SAVE_SEARCH.URL}/${uuid}`,[]) 
    }
    if(payload.uuid){
      const uuid = payload.uuid;
      payload.uuid = undefined;
      return await Api.patch(`${SAVE_SEARCH.URL}/${uuid}`, payload) 
    }
    return await Api.post(`${SAVE_SEARCH.URL}`, payload)
  }

  public fetchAdvanceSearch = async (filter: any | null): Promise<any> => {
    const params = [{ key: 'filter', value: filter }];
    return await Api.get(`${SAVE_SEARCH.URL}`, params)
  }

  public scheduleSaveRequest = async (payload: any | null): Promise<any> => {
    const schedulerPayload = {schedule: payload.frequency,fileType:payload.fileType};
    return await Api.patch(`${SAVE_SEARCH_SCHEDULE.URL}/${payload.uid}`, schedulerPayload)
  }



}