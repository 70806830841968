import { call, put } from 'redux-saga/effects';
import { TransactionActions,UIAction  } from '../actions/index';
import { TransactionService } from '../services';



export function* getTransactions(action: ReturnType<typeof TransactionActions.getTransactions>) {
    try {
        yield put(UIAction.showLoader(true));
        const { data } = yield call(TransactionService.getInstance().getTransactions, action.payload);
        yield put(UIAction.showLoader(false));
        if (action.payload.exportOptions) {
            var blob = new Blob([data]);
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `dispensed_report_${action.payload.dates.replace(',', '-')}.csv`;
            link.click();
            return
        }
        yield put(TransactionActions.setTransactions(data));
    } catch (err: any) {
        yield put(UIAction.showLoader(false));
        console.log("saga error" + err);
    }
}






