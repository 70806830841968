import { GET_PRODUCT_LIST, GET_PROVIDER_PRODUCT_CONFIG_DATA, GET_PRODUCT_NAMES } from '../constants/api.url';
import { HttpResponse, ResultSet } from "../models/common/result.set";
import Api from "../lib/api";


export class ProductService {

  private static instance: ProductService;
  private constructor() { }

  public static getInstance(): ProductService {
    if (!ProductService.instance) {
      ProductService.instance = new ProductService();
    }
    return ProductService.instance;
  }

  public getProducts = async (filter: any | null): Promise<HttpResponse<ResultSet<any>>> => {
    //const params = [{key: 'filter', value: filter}];
    let paramString: string = '';
    Object.keys(filter).forEach(function (key: any, index: number) {
      paramString += (index > 0) ? '&' + key + '=' + filter[key] : '?' + key + '=' + filter[key]
    })
    return await Api.get(`${GET_PRODUCT_LIST.URL}${paramString}`, [])
  }

  public getProductNames = async (filter: any | null): Promise<HttpResponse<ResultSet<any>>> => {
    //const params = [{key: 'filter', value: filter}];
    let paramString: string = '';
    Object.keys(filter).forEach(function (key: any, index: number) {
      paramString += (index > 0) ? '&' + key + '=' + filter[key] : '?' + key + '=' + filter[key]
    }) 
    return await Api.get(`${GET_PRODUCT_NAMES.URL}${paramString}`, []) 
  }

  public getProviderProductConfigData = async (customerId: string, filter?: any): Promise<HttpResponse<any>> => {

    return await Api.get(`${GET_PROVIDER_PRODUCT_CONFIG_DATA.URL}?customerId=${customerId}`, [])
  }

}