import React from 'react';
import { Button, Dialog, Checkbox, FormControlLabel, FormGroup, TextField, FormControl } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CommonStyles } from '../../../hooks/styles'
import { DialogTitle, DialogContent, DialogActions } from '../dialogProps';

export default function MapRegionsDialogs(props: any) {

  const [open, setOpen] = React.useState(props.open);
  const classes = CommonStyles();

  const handleClose = () => {
    setOpen(false);
    props.handleClickClose();
  };

  const top100Films = [
    { title: 'Florida', year: 1994 },
    { title: 'Alabama', year: 1972 },
    { title: 'Hawaii', year: 1974 },
  ];

  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open])

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Supplier Regions
        </DialogTitle>
        <DialogContent dividers style={{ width: '500px', height: '300px', fontSize: 12 }}>

          <FormGroup row style={{ marginBottom: 10 }}>
            <FormControl className={classes.formControl} style={{width:'100%',margin:'0 auto'}}>
              <TextField id="standard-basic" label="State Code" />
            </FormControl>
          </FormGroup>


          <FormGroup row style={{ marginBottom: 10 }}>
          <FormControl className={classes.formControl} style={{width:'100%',margin:'0 auto'}}>
              <TextField id="standard-basic" label="State Name" />
            </FormControl>
          </FormGroup>

          <FormGroup row style={{ marginBottom: 10 }}>
            <FormControlLabel
              style={{ fontSize: 12 }}
              control={
                <Checkbox
                  //checked={}
                  //onChange={handleChange}
                  name="checkedB"
                  color="primary"
                />
              }
              label="All Regions"
            />
          </FormGroup>

          <FormGroup row>
          <FormControl className={classes.formControl} style={{width:'100%',margin:'0 auto'}}>
              <Autocomplete
                multiple
                id="tags-standard"
                options={top100Films}
                getOptionLabel={(option) => option.title}
                defaultValue={[top100Films[2]]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Supplier Regions"
                  />
                )}
              />
            </FormControl>
          </FormGroup>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose}
            type="button"
            className={classes.viewAllBtn}
            variant="contained"
            color="primary"
          >Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}











