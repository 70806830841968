
export interface SaveSearchPayloadType {
    pageName?: string;
    title?: string;
    userEmail?: string;
    data?: any;
    uuid?:string;
}
export interface SaveFilterProps {
    open: boolean;
    handleClose: () => void;
    saveSearch:(paylaod:SaveSearchPayloadType) => void;
    pageName: string;
    filters: any;
    authUser: any;
    error:any;
    title?:string;
    uuid?:string;
    getAdvanceSearch:(filter:any) => void;
}
export class SaveSearchPayload {
    pageName?: string;
    title?: string;
    userEmail?: string;
    data?: any;
    uuid?:string;

}

export interface ScheduleFilterProps {
    open: boolean;
    handleClose: () => void;
    pageName: string;
    title: string;
    authUser: any;
    error?:any;
    uuid?:string;
    scheduleSearch:(paylaod:any) => void;
    getAdvanceSearch:(filter:any) => void;
    frequency:any[];
    fileType: string;
}