import React from 'react';
import { connect } from 'react-redux';
import { Container, Button } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { getMuiTheme } from '../../../hooks/styles';

import { CommonStyles } from '../../../hooks/styles'
import BasePage from '../../common/base'
import { Constants } from '../../../constants/Constants';
import MapRegionsDialogs from './mapRegionsDialogs';

const States = (props: any) => {

    const classes = CommonStyles();
    const [open, setOpen] = React.useState(false);



    const customBodyRenderFunction = (value?: any, tableMeta?: any) => {
        if ('action' === tableMeta.columnData.name) {
            return (<Button
                onClick={handleClickOpen}
                type="button"
                className={classes.viewAllBtn}
                variant="contained"
                color="primary"
            >Edit</Button>)
        } else {
            return value;
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickClose = () => {
        setOpen(false);
    };


    const getColumns = () => {

        const columns = [
            {
                label: "State Code",
                name: "stateCode",
            },
            {
                label: "State Name",
                name: "stateName",
            },

            {
                label: "Region",
                name: "region"
            },

            {
                label: "Action",
                name: "action"
            },
        ];
        let columnsList = columns.map((item, index) => {
            return {
                name: item.name,
                label: item.label,
                options: {
                    display: true,
                    customBodyRender: customBodyRenderFunction
                }
            }
        });
        return columnsList;
    }

    


    

    const tableOptions = {
        search: false,
        filter: false,
        download: false,
        print: false,
        serverSide: true,
        viewColumns: false,
        selectableRows: 'none',
        count: 0,
        responsive: "vertical",
        pagination: true,
        onTableChange: (tableAction: any, tableState: any) => {
            // console.log('tableAction', tableAction)
        },
    };

    return (
		<BasePage title={Constants.PAGE_TITLE.STATES} drawerOpen={true}>
			<Container maxWidth={false} className={classes.container}>
				<div className={classes.appBarSpacer}></div>

				<div className={classes.appBarSpacer}></div>

				{/*<MuiThemeProvider theme={getMuiTheme({ tableHeadBackground: '#f1f1f1!important', tableHeadCellFontSize: '12px' })} >*/}
				<MUIDataTable
					data={[
						{
							stateCode: "ES",
							stateName: "East",
							region: "North, South, East",
							action: "North, South, East",
						},
						{
							stateCode: "WS",
							stateName: "West",
							region: "North, South, East",
							action: "North, South, East",
						},
						{
							stateCode: "NH",
							stateName: "North",
							region: "North, South, East",
							action: "North, South, East",
						},
						{
							stateCode: "SW",
							stateName: "South West",
							region: "North, South, East",
							action: "North, South, East",
						},
					]}
					columns={getColumns()}
					options={tableOptions}
				/>
				{/*</MuiThemeProvider>*/}
				<MapRegionsDialogs
					open={open}
					handleClickClose={handleClickClose}
				/>
			</Container>
		</BasePage>
	);
}
const mapStateToProps = (state: any) => (
    {}
);
export default connect(mapStateToProps)(States);