import { action } from "typesafe-actions";
import { MAPPING, SUPPLIER_REGIONS } from "../constants/actions";
import { ResultSet } from "../models/common/result.set";
import { createRegionParams, updateRegionParams } from "../services/supplier.regions.service";

export const getSupplierRegionsList = (data: any) => action(MAPPING.SUPPLIER_REGIONS.GET_SUPPLIER_REGIONS, data);
export const successSupplierRegionsList = (data: ResultSet<any>) =>
	action(MAPPING.SUPPLIER_REGIONS.SUCCESS_SUPPLIER_REGIONS, data);

export const getRegionsStatesList = (data: any) => action(MAPPING.REGIONS_STATES.GET_REGIONS_STATES, data);
export const successRegionsStateList = (data: ResultSet<any>) =>
	action(MAPPING.REGIONS_STATES.SUCCESS_REGIONS_STATES, data);

export const getStatesList = (data: any) => action(MAPPING.STATE_LIST.GET_STATES_LIST, data);
export const successStateList = (data: ResultSet<any>) => action(MAPPING.STATE_LIST.SUCCESS_STATES_LIST, data);

export const updateRegionState = (data: any) => action(MAPPING.REGIONS_STATES.UPDATE_REGIONS_STATES, data);
export const updateRegionName = (data: any) => action(MAPPING.SUPPLIER_REGIONS.UPDATE_SUPPLIER_REGIONS, data);
export const createRegion = (data: any) => action(MAPPING.SUPPLIER_REGIONS.CREATE_SUPPLIER_REGIONS, data);
export const deleteRegion = (data: any) => action(MAPPING.SUPPLIER_REGIONS.DELETE_SUPPLIER_REGIONS, data);

// New Region CRUD
export const createRegionAction = (data: createRegionParams) => action(SUPPLIER_REGIONS.CREATE_REGION, data);
export const readRegionAction = (filter: any) => action(SUPPLIER_REGIONS.GET_REGION, filter);
export const successReadRegionAction = (data: ResultSet<any>) => action(SUPPLIER_REGIONS.SUCCESS_GET_REGION, data);
export const updateRegionAction = (data: updateRegionParams) => action(SUPPLIER_REGIONS.UPDATE_REGION, data);
export const deleteRegionAction = (regionId: string) => action(SUPPLIER_REGIONS.DELETE_REGION, regionId);
export const clearRegionData = () => action(SUPPLIER_REGIONS.CLEAR_REGION_DATA);
export const getAvailableRegionDataAction = (regionType: string) =>
	action(SUPPLIER_REGIONS.GET_AVAILABLE_REGION_DATA, regionType);
export const successGetAvailableRegionDataAction = (data: ResultSet<any>) =>
	action(SUPPLIER_REGIONS.SUCCESS_GET_AVAILABLE_REGION_DATA, data);

export const uploadRegionDataCSV = (data: any) => action(SUPPLIER_REGIONS.UPLOAD_REGION_DATA_CSV, data);
export const downloadRegionDataCSVTemplates = () => action(SUPPLIER_REGIONS.DOWNLOAD_REGION_DATA_CSV_TEMPLATES);
