import React from 'react';
import { connect } from 'react-redux';
import { Container } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { getMuiTheme } from '../../../hooks/styles';

import { CommonStyles } from '../../../hooks/styles'
import BasePage from '../../common/base'
import { Constants, List } from '../../../constants/Constants';
import MapRegionsDialogs from './mapRegionsDialogs';
import { SupplierRegionsAction } from '../../../redux/actions';

const Suppliers = (props: any) => {

    const classes = CommonStyles();
    const isFirstRender = React.useRef(true);
    const [pagination, setPagination] = React.useState({
        offset: 0,
        limit: 10,
        page: 0
    });


    const fetchSupplier = () => {
        let filter: any = {};
        filter.limit = pagination.limit;
        filter.offset = pagination.offset;
        props.getSupplierRegionsList(filter);
    }

    /*React.useEffect(() => {
        if (isFirstRender.current) {
            fetchSupplier();
            isFirstRender.current = false;
        }
    })*/

    const changePageCallback = React.useCallback(fetchSupplier, [pagination]);
    React.useEffect(() => {

        changePageCallback();

    }, [changePageCallback]);








    const getColumns = () => {

        const columns = [
            {
                label: "Supplier Code",
                name: "supplier_code",
            },
            {
                label: "Supplier Name",
                name: "supplier_name",
            },

            {
                label: "Regions",
                name: "regions"
            },




        ];
        let columnsList = columns.map((item, index) => {
            return {
                name: item.name,
                label: item.label,
                options: {
                    display: true,
                    //customBodyRender: customBodyRenderFunction
                }
            }
        });
        return columnsList;
    }

    const mapRegionPopup = () => {

    }


    const customToolBar = () => {
        return (
            <div style={{ marginRight: '20px', marginBottom: '2px' }}>
                <MapRegionsDialogs />
            </div>
        );
    }

    const tableOptions = {
        search: false,
        filter: false,
        download: false,
        print: false,
        serverSide: true,
        viewColumns: false,
        count: props.totalRecord,
        responsive: "vertical",
        pagination: true,
        page: pagination.page,
        rowsPerPageOptions: List.PageLimitOptions,
        onTableChange: (tableAction: any, tableState: any) => {
            switch (tableAction) {

                case 'changeRowsPerPage':
                    setPagination({ offset: 0, limit: tableState.rowsPerPage, page: 0 });
                    break;
                case 'changePage':
                    if (tableState.page > pagination.page) {
                        setPagination({ offset: pagination.offset + pagination.limit, limit: pagination.limit, page: tableState.page });
                    }
                    else if (tableState.page < pagination.page) {
                        setPagination({ offset: pagination.offset - pagination.limit, limit: pagination.limit, page: tableState.page });
                    }
                    break;
            }
        },
        //customToolbar: () => customToolBar(),
        customToolbarSelect: (selectedRows: any) => customToolBar()
    };

    return (
		<BasePage title={Constants.PAGE_TITLE.SUPPLIERS} drawerOpen={true}>
			<Container maxWidth={false} className={classes.container}>
				<div className={classes.appBarSpacer}></div>
				{/*<MuiThemeProvider theme={getMuiTheme({ tableHeadBackground: '#f1f1f1!important', tableHeadCellFontSize: '12px' })} >*/}
				<MUIDataTable
					data={props.suppliersRegionsList}
					columns={getColumns()}
					options={tableOptions}
				/>
				{/*</MuiThemeProvider>*/}
			</Container>
		</BasePage>
	);
}
const mapStateToProps = (state: any) => (
    {
        suppliersRegionsList: (state.supplierRegion && state.supplierRegion.suppliersRegionsList && state.supplierRegion.suppliersRegionsList.result) ?
            state.supplierRegion.suppliersRegionsList.result : [],
        totalRecord: (state.supplierRegion && state.supplierRegion.suppliersRegionsList && state.supplierRegion.suppliersRegionsList.total) ?
            state.supplierRegion.suppliersRegionsList.total : []
    }
);

const mapDispatchToProps = (dispatch: any) => ({
    getSupplierRegionsList: (filter: any) => dispatch(SupplierRegionsAction.getSupplierRegionsList(filter)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Suppliers);