import { call, put } from 'redux-saga/effects';
import { LocationActions  } from '../actions/index';
import { LocationService } from '../services';



export function* getLocationList(action: ReturnType<typeof LocationActions.getLocationList>) {
    try {
        
        const { data } = yield call(LocationService.getInstance().getLocation, action.payload);
        yield put(LocationActions.locationListSuccess(data));
    } catch (err) {
        console.log("saga error" + err);
    }
}






