import { call, put } from 'redux-saga/effects';
import { SupplierActions,UIAction  } from '../actions/index';
import { SupplierService } from '../services';



export function* getSupplierList(action: ReturnType<typeof SupplierActions.getSupplierList>) {
    try {
        yield put(UIAction.showSupplierLoading(true));
        const { data } = yield call(SupplierService.getInstance().getSuppliers, action.payload);
        yield put(SupplierActions.successSupplierList(data));
        yield put(UIAction.showSupplierLoading(false));
    } catch (err: any) {
        yield put(UIAction.showSupplierLoading(false));
        console.log("saga error" + err);
    }
}

export function* getSuppliers(action: ReturnType<typeof SupplierActions.getSuppliers>) {
    try {
        const { data } = yield call(SupplierService.getInstance().getSuppliers, action.payload);
        yield put(SupplierActions.successSuppliers(data));
        
    } catch (err: any) {
        console.log("saga error" + err);
    }
}







