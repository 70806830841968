import { Constants } from "../constants/Constants";
export default {
  
  ScheduledReportTemplates: [
    { "label": "Dispensed Report", "value": "SUP_DispensedReport" },
    { "label": "Usage DOH Report", "value": "SUP_UsageDOHReport" },
    { "label": "Current Inventory Report", "value": "SUP_CurrentInventoryReport" },
    { "label": "Custom Supplier Stocking/Vending Activity Report", "value": "SUP_TransactionsActivityReport" },
    { "label": "Provider Product Configuration Report", "value": "SUP_ProviderProductConfigReport" }
  ],
  AdminScheduledReportTemplates: [],
  ScheduledReportFormats: [
    { "label": "csv", "value": "csv" },
    { "label": "pdf", "value": "pdf" },
    { "label": "xlsx", "value": "xlsx" }
  ],
  ScheduledReportDeliveryMethods: [
    { "label": "email download link", "value": "Email" },
    { "label": "email attachment", "value": "EmailAttachment"}
  ],
  ScheduledReportDeliveryFrequencies: [
    { "label": "Daily", "value": "Daily" },
    { "label": "Weekly", "value": "Weekly" },
    { "label": "Monthly", "value": "Monthly" }
  ],
  ScheduledReportAdditionalFilters: {
    "SUP_DispensedReport": ['productGroup', 'customerName', 'gpo', 'idn', 'region'],
    "SUP_UsageDOHReport": ['region', 'productName'],  
    "SUP_CurrentInventoryReport": ['productGroup', 'customerName', 'gpo', 'idn', 'region'],
    "SUP_TransactionsActivityReport": ['productGroup', 'customerName', 'cabinetType', 'region'], //"customer" is distinct from global "supplier" filter
    "SUP_ProviderProductConfigReport": ['region']
  },

  //VIEW: Filter Dispense, Product expiration and inventory Scheduled Reports by GPO & IDN
  DayOfWeekChoices: [
    { "label": "Monday", "value": 1 },
    { "label": "Tuesday", "value": 2 },
    { "label": "Wednesday", "value": 3 },
    { "label": "Thursday", "value": 4 },
    { "label": "Friday", "value": 5 },
    { "label": "Saturday", "value": 6 },
    { "label": "Sunday", "value": 7 },
  ],
  DayOfWeekMap: {
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
    7: "Sunday"
  },
  CabinetTypeOptions: [
    { "label": "Any", "value": undefined },
    { "label": "RightNow Ready", "value": 'EM' },
    { "label": "RightNow Verified", "value": 'RFID' },
  ],
  ConsignmentTypeDropdownList: [
    { "label": 'All', "value": "" },
    { "label": 'Non-Consignment', "value": "1" },
    { "label": 'Consignment', "value": "2" } //we only have Consignment data right now(??) so typically default this to 2 or no value
  ],
  FilterConfig: {
    'DASHBOARD': {
      'SUPPLIER_NAME': {
        required: true
      },
      'MORE_FILTERS': {
        'PRODUCT_NAME': {
          name: 'PRODUCT_NAME',
          displayName: 'Product Name',
          autoShow: true
        },
        'REGION': {
          name: 'REGION',
          displayName: 'Region',
          autoShow: true
        },
      }
    },
    'INVENTORY': {
      'SHOW_EXPORT_BUTTON': true,
      'SUPPLIER_NAME': {
        required: true
      },
      'EXPIRY_DATE': {
        required: true
      },
      'MORE_FILTERS': {
        'PRODUCT_NAME': {
          name: 'PRODUCT_NAME',
          displayName: 'Product Name'
        },
        'LOT_NUMBER': {
          name: 'LOT_NUMBER',
          displayName: 'Lot Number'
        },
        'GPO': {
          name: 'GPO',
          displayName: 'GPO'
        },
        'IDN': {
          name: 'IDN',
          displayName: 'IDN'
        },
        'CLASS_OF_TRADE': {
          name: 'CLASS_OF_TRADE',
          displayName: 'Class of Trade'
        },
        'FACILITY_TYPE': {
          name: 'FACILITY_TYPE',
          displayName: 'Facility Type'
        },
        'CUSTOMER_STATE': {
          name: "CUSTOMER_STATE",
          displayName: "State",
        },
        'REGION': {
          name: 'REGION',
          displayName: 'Region',
          autoShow: true
        },
      }
    },
    'INVENTORY_LOCATOR': {
      'SUPPLIER_NAME': {
        required: true
      },
      'MORE_FILTERS': {
        'PRODUCT_NAME': {
          name: 'PRODUCT_NAME',
          displayName: 'Product Name',
        },
        'REGION': {
          name: 'REGION',
          displayName: 'Region',
          autoShow: true
        },
        // 'CONSIGNMENT_TYPE': {
        //   name: 'CONSIGNMENT_TYPE',
        //   displayName: 'WHS & Consignment',
        //   autoShow: true
        // },
      }
    },
    'PRODUCT_EXPIRATION': {
      'SUPPLIER_NAME': {
        required: true
      },
      'EXPIRY_DATE': {
        required: true
      },      
      'MORE_FILTERS': {
        'PRODUCT_NAME': {
          name: 'PRODUCT_NAME',
          displayName: 'Product Name',
          autoShow: true
        },
        'LOT_NUMBER': {
          name: 'LOT_NUMBER',
          displayName: 'Lot Number'
        },
        // 'CONSIGNMENT_TYPE': {
        //   name: 'CONSIGNMENT_TYPE',
        //   displayName: 'WHS & Consignment',
        //   autoShow: true,
        //   disallowAll: true
        // },
        'GPO': {
          name: 'GPO',
          displayName: 'GPO'
        },
        'IDN': {
          name: 'IDN',
          displayName: 'IDN'
        },
        'CLASS_OF_TRADE': {
          name: 'CLASS_OF_TRADE',
          displayName: 'Class of Trade'
        },
        'FACILITY_TYPE': {
          name: 'FACILITY_TYPE',
          displayName: 'Facility Type'
        },
        'REGION': {
          name: 'REGION',
          displayName: 'Region',
          autoShow: true
        },
      }
    },
    'USAGE_REPORT': {
      'SHOW_EXPORT_BUTTON': true,
      'SUPPLIER_NAME': {
        required: true
      },
      'DATE_RANGE': {
        required: true
      },
      'MORE_FILTERS': {
        'PRODUCT_NAME': {
          name: 'PRODUCT_NAME', 
          displayName: 'Product Name'
        },
        'CUSTOMER_NAME': {
          name: 'CUSTOMER_NAME',
          displayName: 'Customer Name'
        },
        'PROVIDER_ID': {
          name: 'PROVIDER_ID',
          displayName: 'Provider ID'
        },
        'REGION': {
          name: 'REGION',
          displayName: 'Region',
          autoShow: true
        },
        'GPO': {
          name: 'GPO',
          displayName: 'GPO'
        },
        'IDN': {
          name: 'IDN',
          displayName: 'IDN'
        },
        'CLASS_OF_TRADE': {
          name: 'CLASS_OF_TRADE',
          displayName: 'Class of Trade'
        },
        'FACILITY_TYPE': {
          name: 'FACILITY_TYPE',
          displayName: 'Facility Type'
        },
      }
    },
    'PROVIDER_PRODUCT_CONFIG': {
      'SHOW_EXPORT_BUTTON': true,
      'SUPPLIER_NAME': {
        required: true
      }
    },
    'USERS': {
      'SUPPLIER_NAME': {},
      'MORE_FILTERS': {
        'USER_FIRST_NAME': {
          name: 'USER_FIRST_NAME',
          displayName: 'First name'
        },
        'USER_LAST_NAME': {
          name: 'USER_LAST_NAME',
          displayName: 'Last name'
        },
        'USER_EMAIL': {
          name: 'USER_EMAIL',
          displayName: 'Email'
        }
      }
    },
    'WAREHOUSE_INVENTORY': {
      'SHOW_EXPORT_BUTTON': true,
      'SUPPLIER_NAME': {
        required: true
      },
      'EXPIRY_DATE': {
        required: true
      },
      'MORE_FILTERS': {
        'PRODUCT_NAME': {
          name: 'PRODUCT_NAME',
          displayName: 'Product Name'
        },
        'LOT_NUMBER': {
          name: 'LOT_NUMBER',
          displayName: 'Lot Number'
        },
      }
    },
  },


  DefaultFilterVals: {
    'CABINET_ID': undefined,
    'PRODUCT_ID': undefined,
    'LOT_NUMBER': undefined,
    'ACTION': undefined,
    'REASON': undefined,
    'INVOICED': undefined,
    'SERIAL_NUMBER': undefined,
    'PRODUCT_NAME': undefined,
    'CABINET_TYPE': undefined,
    'CABINET_STATE': undefined,
    'ORDER_STATUS': undefined,
    'SHIPMENT_STATUS': undefined,
    'WAREHOUSE': undefined,
    'INCIDENT_STATUS': undefined,
    'INCIDENT_SEVERITY': undefined,
    'TELEMETRY_ALERT_LEVEL': undefined,
    'USER_FIRST_NAME': undefined,
    'USER_LAST_NAME': undefined, 
    'USER_EMAIL': undefined,
    'CUSTOMER_SETUP_STATUS': undefined,
    'CUSTOMER_STATE': undefined,
    'CUSTOMER_ZIP_CODE': undefined,
    'CUSTOMER_CREATED_DATE': undefined,
    'SALES_TYPE': undefined,
    'DOSE_TYPE': undefined,
    'TRANSACTION_STATUS': undefined,
    'ORDER_TYPE': undefined,
    'TRANSACTION_TYPE': undefined,
    'CONSIGNMENT_TYPE': undefined,
    'REGION': undefined
  },
   DateFormats: [
    { "label": "YYYY-MM-DD", "value": "yyyy-MM-DD" },
    { "label": "YY-MM-DD", "value": "YY-MM-DD" },
    { "label": "MM-DD-YYYY", "value": "MM-DD-yyyy" },
    { "label": "MM-DD-YY", "value": "MM-DD-YY" },
    { "label": "DD-MM-YYYY", "value": "DD-MM-yyyy" },
    { "label": "DD-MM-YY", "value": "DD-MM-YY" },
  ],

  PickerDateFormats: [
    { "label": "YYYY-MM-DD", "value": "yyyy-MM-dd" },
    { "label": "YY-MM-DD", "value": "yy-MM-dd" },
    { "label": "MM-DD-YYYY", "value": "MM-dd-yyyy" },
    { "label": "MM-DD-YY", "value": "MM-dd-yy" },
    { "label": "DD-MM-YYYY", "value": "dd-MM-yyyy" },
    { "label": "DD-MM-YY", "value": "dd-MM-yy" },
  ],

  TimeFormats: [
    { "label": "24 Hour", "value": "HH:mm:ss zz" },
    { "label": "12 Hour", "value": "hh:mm:ssA zz" },
  ],

};

//reusable text strings 
export const TextLabels = {
  'SellableUnits': 'Sellable Units'
}; 




