import { action } from 'typesafe-actions';
import { SUPPLIER } from '../constants/actions';
import { ResultSet } from '../models/common/result.set';

export const getSupplierList   = (data: any) => action(SUPPLIER.GET_SUPPLIER_LIST, data);
export const successSupplierList   = (data: any) => action(SUPPLIER.SUCCESS_SUPPLIER_LIST, data);

export const getSuppliers   = (data: any) => action(SUPPLIER.GET_SUPPLIERS, data);
export const successSuppliers   = (data: any) => action(SUPPLIER.SUCCESS_SUPPLIERS, data);

export const successRegionsList   = (data: any) => action(SUPPLIER.SUCCESS_REGIONS_LIST, data);
