import React from 'react';
import { Container, Typography, CircularProgress } from '@material-ui/core/';
import './loading.css';

type LoadingProps = {
  message: string
}

const Loading: React.FC<LoadingProps> = ({ message }) => {
  return (
    <Container maxWidth="sm">
      <div className="page-wrap">
        <CircularProgress />
        <Typography variant="h6" align="center">{message}</Typography>
      </div>
    </Container>
  )
}

export default Loading;