import React from 'react';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Container, Button, Grid, FormGroup, FormControl, TextField, Chip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CommonStyles } from '../../../hooks/styles'
import BasePage from '../../common/base'
import { Constants } from '../../../constants/Constants';
//import MapRegionsDialogs from './mapRegionsDialogs';
import { SupplierRegionsAction } from '../../../redux/actions';
import { useParams } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ClearIcon from "@material-ui/icons/Clear";

const grid: any = 10;
const getListStyle = (isDraggingOver: any) => ({
    //background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: grid,
    height: 400,
    border: '1px solid #f2f2f2'
});

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    userSelect: 'none',
    padding: 10,
    margin: `0 10px ${grid}px 0`,
    background: isDragging ? '#558db1' : '#f2f2f2',
    color: isDragging ? '#fff' : 'black',
    ...draggableStyle
});

const EditRegions = (props: any) => {

    const { regionCode,supplierId } : any = useParams();

    
    const classes = CommonStyles();
    let [dataListLeft, setDataListLeft] = React.useState([{ "state_code": "", "state_name": "" }]);
    let [dataListRight, setDataListRight] = React.useState([{ "state_code": "", "state_name": "" }]);
    let [componentKey, setComponentKey] = React.useState('1');
    let [selectedRegion, setSelectedRegion] = React.useState(null as any);
    const [validationRequired, setValidationRequired] = React.useState<boolean>(false);
    const [regionNameRequired, setRegionNameRequired] = React.useState<boolean>(false);
    const [isChanges, setIsChanges] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>('Please a choose a region first');
    const isFirstRender = React.useRef(true);
    const LEFT: string = 'LEFT';
    const RIGHT: string = 'RIGHT';
    let [supplierRegionList, setSupplierRegionList] = React.useState(props.suppliersRegionsList);
    let [regionName, setRegionName] = React.useState<string>(regionCode);

    const onSaveChanges = () => {
        if (!regionName) {
            setRegionNameRequired(true);
            return;
        }
        setValidationRequired(false);
        setIsChanges(false);
    }


    const fetchRegionState = () => {
        let payload: any = {};
        payload.type = LEFT;
        payload.filter = regionCode;
        props.getRegionsStatesList(payload);
    }


    const fetchRegions = () => {
        let filter: any = {};
        filter.limit = 10;
        filter.offset = 0;
        props.getSupplierRegionsList(filter);
    }
    

    React.useEffect(() => {
        setDataListLeft(props.regionsStatesLeft);
    }, [props.regionsStatesLeft])

    React.useEffect(() => {

        let index = props.suppliersRegionsList.findIndex((obj: { region: string; }) => obj.region === regionCode);
        props.suppliersRegionsList.splice(index, 1);
        setSupplierRegionList(props.suppliersRegionsList);
    }, [props.suppliersRegionsList])



    React.useEffect(() => {
        setDataListRight(props.regionsStatesRight);
    }, [props.regionsStatesRight])


    React.useEffect(() => {
        if (isFirstRender.current) {
            fetchRegions();
            fetchRegionState();
            isFirstRender.current = false;
        }
    })





    const onRegionChange = (event?: any, value?: any) => {

        
        if (isChanges) {
            componentKey = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            setComponentKey(componentKey);
            setValidationRequired(true);
            setErrorMessage('Please save changes first');
            return false;
        }
        if (!value) {
            dataListRight = [
                { "state_code": "", "state_name": "" }
            ];
            setDataListRight(dataListRight);
        } else {
            let payload: any = {};
            payload.type = RIGHT;
            payload.filter = value.region;
            props.getRegionsStatesList(payload);
            setValidationRequired(false);
        }
        setSelectedRegion(value);


    }

    const reorder = (list: any, startIndex: any, endIndex: any) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result: any) => {

        const { source, destination } = result;
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            if (source.droppableId === LEFT) {
                const items: any = reorder(
                    dataListLeft,
                    source.index,
                    destination.index
                );
                setDataListLeft(items);
            } else if (source.droppableId === RIGHT) {
                const items: any = reorder(
                    dataListRight,
                    source.index,
                    destination.index
                );
                setDataListRight(items);
            }
        } else {
            if (source.droppableId === LEFT) {

                setValidationRequired(false);
                if (!selectedRegion) {
                    setValidationRequired(true);
                    return;
                }
                setIsChanges(true);
                const sourceClone = Array.from(dataListLeft);
                const destClone = Array.from(dataListRight);
                const [removed] = sourceClone.splice(source.index, 1);
                destClone.splice(destination.index, 0, removed);
                setDataListLeft(sourceClone);
                setDataListRight(destClone);
            } else if (source.droppableId === RIGHT) {
                setIsChanges(true);
                const sourceClone = Array.from(dataListRight);
                const destClone = Array.from(dataListLeft);
                const [removed] = sourceClone.splice(source.index, 1);
                destClone.splice(destination.index, 0, removed);
                setDataListLeft(destClone);
                setDataListRight(sourceClone);
            }
        }
    }


    return (
		<BasePage title={Constants.PAGE_TITLE.REGIONS} drawerOpen={true}>
			<div className={classes.appBarSpacer}></div>
			<Container maxWidth={false} className={classes.container}>
				<DragDropContext onDragEnd={onDragEnd}>
					<Grid container spacing={2}>
						<Grid item xs>
							<FormGroup row style={{ marginBottom: 10 }}>
								<FormControl
									className={classes.formControl}
									style={{ width: "100%" }}
								>
									<TextField
										error={regionNameRequired}
										helperText={
											regionNameRequired &&
											"Region Name is required"
										}
										InputProps={{
											value: regionName,
											endAdornment: regionName && (
												<ClearIcon
													style={{
														cursor: "pointer",
													}}
													onClick={() => {
														setRegionName("");
													}}
												/>
											),
										}}
										autoComplete="off"
										id="outlined-basic"
										label={"Region Name"}
										onChange={({ target }) => {
											setRegionName(target.value);
											setRegionNameRequired(false);
										}}
									/>
								</FormControl>
							</FormGroup>
						</Grid>
						<Grid item xs={1}></Grid>
						<Grid item xs>
							<FormGroup row style={{ marginBottom: 10 }}>
								<FormControl
									className={classes.formControl}
									style={{ width: "100%" }}
								>
									<Autocomplete
										key={componentKey}
										onChange={onRegionChange}
										//multiple
										id="tags-standard"
										options={supplierRegionList}
										getOptionLabel={(option) =>
											option.region
										}
										//defaultValue={selectedRegion}
										value={selectedRegion}
										renderInput={(params) => (
											<TextField
												error={validationRequired}
												helperText={
													validationRequired &&
													errorMessage
												}
												{...params}
												variant="standard"
												label="Supplier Regions"
											/>
										)}
									/>
								</FormControl>
							</FormGroup>
						</Grid>
					</Grid>

					<Grid container spacing={2}>
						<Grid item xs>
							<Droppable droppableId={LEFT}>
								{(provided, snapshot) => (
									<div
										ref={provided.innerRef}
										style={getListStyle(
											snapshot.isDraggingOver
										)}
									>
										{dataListLeft.map(
											(item: any, index: any) => {
												if (item.state_code === "") {
													return;
												}
												return (
													<Draggable
														key={item.state_code}
														draggableId={
															item.state_code
														}
														index={index}
													>
														{(
															provided: any,
															snapshot: any
														) => (
															<Chip
																label={
																	item.state_name
																}
																ref={
																	provided.innerRef
																}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																style={getItemStyle(
																	snapshot.isDragging,
																	provided
																		.draggableProps
																		.style
																)}
															/>
														)}
													</Draggable>
												);
											}
										)}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</Grid>
						<Grid item xs={1}>
							<div
								style={{
									textAlign: "center",
									transform: "translateY(34%)",
									height: "100%",
								}}
							>
								<div>
									<ArrowBackIcon />
								</div>
								<div className={classes.appBarSpacer}></div>
								<div>
									<ArrowForwardIcon />
								</div>
							</div>
						</Grid>
						<Grid item xs>
							<Droppable droppableId={RIGHT}>
								{(provided, snapshot) => (
									<div
										ref={provided.innerRef}
										style={getListStyle(
											snapshot.isDraggingOver
										)}
									>
										{dataListRight.map(
											(item: any, index: any) => {
												if (item.state_code === "") {
													return;
												}
												return (
													<Draggable
														key={item.state_code}
														draggableId={
															item.state_code
														}
														index={index}
													>
														{(
															provided: any,
															snapshot: any
														) => (
															<Chip
																label={
																	item.state_name
																}
																ref={
																	provided.innerRef
																}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																style={getItemStyle(
																	snapshot.isDragging,
																	provided
																		.draggableProps
																		.style
																)}
															/>
														)}
													</Draggable>
												);
											}
										)}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</Grid>

						{isChanges && (
							<Grid item xs={12}>
								<FormControl
									className={classes.formControl}
									style={{ float: "right" }}
								>
									<Button
										onClick={onSaveChanges}
										type="button"
										className={classes.viewAllBtn}
										variant="contained"
										color="primary"
									>
										Save Changes{" "}
									</Button>
								</FormControl>
							</Grid>
						)}
					</Grid>
				</DragDropContext>
			</Container>
		</BasePage>
	);
}

const mapStateToProps = (state: any) => (
    {
        regionsStatesLeft: (state.supplierRegion && state.supplierRegion[`LEFT`]) ?
            state.supplierRegion['LEFT'] : [],
        regionsStatesRight: (state.supplierRegion && state.supplierRegion[`RIGHT`]) ?
            state.supplierRegion['RIGHT'] : [],

        suppliersRegionsList: (state.supplierRegion && state.supplierRegion.suppliersRegionsList) ?
            state.supplierRegion.suppliersRegionsList : [],

    }
);

const mapDispatchToProps = (dispatch: any) => ({
    getRegionsStatesList: (filter: any) => dispatch(SupplierRegionsAction.getRegionsStatesList(filter)),
    getSupplierRegionsList: (filter: any) => dispatch(SupplierRegionsAction.getSupplierRegionsList(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditRegions);