import { action } from 'typesafe-actions';
import { INVENTORY } from '../constants/actions';
import { ResultSet } from '../models/common/result.set';

export const getInventoryList   = (data: any) => action(INVENTORY.GET_INVENTORY_LIST, data);
export const successInventoryList   = (data: ResultSet<any>) => action(INVENTORY.SUCCESS_INVENTORY_LIST, data);
export const successCurrentInventory = (data: ResultSet<any>) => action(INVENTORY.SUCCESS_CURRENT_INVENTORY, data);

export const getInventoryDetailList   = (data: any) => action(INVENTORY.GET_INVENTORY_DETAIL_LIST, data);
export const successInventoryDetailList   = (data: ResultSet<any>) => action(INVENTORY.SUCCESS_INVENTORY_DETAIL_LIST, data);



export const getInventory   = (data: any) => action(INVENTORY.GET_INVENTORY, data);
export const successInventory   = (data: ResultSet<any>) => action(INVENTORY.SUCCESS_INVENTORY, data);

export const clearInventoryList   = () => action(INVENTORY.CLEAR_INVENTORY_LIST);



export const getGroupInventory   = (data: any) => action(INVENTORY.GET_GROUP_INVENTORY, data);
export const successGroupInventory   = (data: ResultSet<any>) => action(INVENTORY.SUCCESS_GROUP_INVENTORY, data);


export const getLotLevelInventory   = (data: any) => action(INVENTORY.GET_LOT_LEVEL_INVENTORY, data);
export const successLotLevelInventory   = (data: ResultSet<any>) => action(INVENTORY.SUCCESS_LOT_LEVEL_INVENTORY, data);

export const getWareHouseInventory   = (data: any) => action(INVENTORY.GET_WAREHOUSE_INVENTORY, data);
export const successWareHouseInventory   = (data: ResultSet<any>) => action(INVENTORY.SUCCESS_WAREHOUSE_INVENTORY, data);


export const getExportInventory   = (data: any) => action(INVENTORY.GET_EXPOPRT_INVENTORY, data);

export const getRegionGroupInventory   = (data: any) => action(INVENTORY.GET_REGION_GROUP_INVENTORY, data);
export const successRegionGroupInventory   = (data: ResultSet<any>) => action(INVENTORY.SUCCESS_REGION_GROUP_INVENTORY, data);

export const getParData   = (data: any) => action(INVENTORY.GET_PAR_DATA, data);
export const successParData   = (data: ResultSet<any>) => action(INVENTORY.SUCCESS_PAR_DATA, data);