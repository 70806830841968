import { 
        GET_INVENTORY_REGION_DATA,
        GET_INVENTORY_ROLL_UP,
        GET_STATE_INVENTORY,GET_INVENTORY_REVENUE,GET_COMPARISION_FOR_SALE,
        GET_REGION_INVENTORY,
        GET_CUSTOMER_CABINET_COUNTS
       } from '../constants/api.url';
import { HttpResponse, ResultSet } from "../models/common/result.set";
import Api from "../lib/api";


export class DashboardService {

  private static instance: DashboardService;
  private constructor() { }

  public static getInstance(): DashboardService {
    if (!DashboardService.instance) {
      DashboardService.instance = new DashboardService();
    }
    return DashboardService.instance;
  }

  

  public getInventoryRegionData = async (filter: any | null): Promise<HttpResponse<ResultSet<any>>> => {
    const params = [{ key: 'filter', value: filter }];
    return await Api.get(`${GET_INVENTORY_REGION_DATA.URL}`, params)
  }

  public getCustomerCabinetCounts = async (filters: any | null): Promise<HttpResponse<ResultSet<any>>> => {
    const params = Object.keys(filters).filter((key: string) => filters[key] ? true : false).map((key: string) => {
      return {key: key, value: filters[key]}
    })
    // const params: any[] = [{ key: 'supplierId', value: filters.supplierId }];
    // console.log('FILTERS', filters)
    // filters.productGroup && params.push([{ key: 'productGroup', value: filters.productGroup }])
    // filters.regionName && params.push([{ key: 'regionName', value: filters.regionName }])
    return await Api.get(`${GET_CUSTOMER_CABINET_COUNTS.URL}`, params)
  }


  public getInventoryRollUp = async (filter: any | null): Promise<HttpResponse<ResultSet<any>>> => {
    let paramString: string = '';
    Object.keys(filter).forEach(function (key: any, index: number) {
      if (typeof filter[key] !== undefined && typeof filter[key] !== 'undefined') {
        paramString += (index > 0) ? '&' + key + '=' + filter[key] : '?' + key + '=' + filter[key]
      }
    })
    return await Api.get(`${GET_INVENTORY_ROLL_UP.URL}${paramString}`, [])
  }

  public getStateInventory = async (filter: any | null): Promise<HttpResponse<ResultSet<any>>> => {
    let paramString: string = '';
    Object.keys(filter).forEach(function (key: any, index: number) {
      if (typeof filter[key] !== undefined && typeof filter[key] !== 'undefined') {
        paramString += (index > 0) ? '&' + key + '=' + filter[key] : '?' + key + '=' + filter[key]
      }
    })
    return await Api.get(`${GET_STATE_INVENTORY.URL}${paramString}`, [])
  }


  public getInventoryRevenue = async (filter: any | null): Promise<HttpResponse<ResultSet<any>>> => {
    let paramString: string = '';
    Object.keys(filter).forEach(function (key: any, index: number) {
      if (typeof filter[key] !== undefined && typeof filter[key] !== 'undefined') {
        paramString += (index > 0) ? '&' + key + '=' + filter[key] : '?' + key + '=' + filter[key]
      }
    })
    return await Api.get(`${GET_INVENTORY_REVENUE.URL}${paramString}`, [])
  }



  public getComparisionForSale = async (filter: any | null): Promise<HttpResponse<ResultSet<any>>> => {
    let paramString: string = '';
    Object.keys(filter).forEach(function (key: any, index: number) {
      if (typeof filter[key] !== undefined && typeof filter[key] !== 'undefined') {
        paramString += (index > 0) ? '&' + key + '=' + filter[key] : '?' + key + '=' + filter[key]
      }
    })
    return await Api.get(`${GET_COMPARISION_FOR_SALE.URL}${paramString}`, [])
  }


  public getRegionInventory = async (filter: any | null): Promise<HttpResponse<ResultSet<any>>> => {
    let paramString: string = '';
    Object.keys(filter).forEach(function (key: any, index: number) {
      if (typeof filter[key] !== undefined && typeof filter[key] !== 'undefined') {
        paramString += (index > 0) ? '&' + key + '=' + filter[key] : '?' + key + '=' + filter[key]
      }
    })
    return await Api.get(`${GET_REGION_INVENTORY.URL}${paramString}`, [])
  }



}