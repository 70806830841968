import React from "react";
import { connect } from "react-redux";
import { Container } from "@material-ui/core";
import { CommonStyles } from "../../../hooks/styles";
import BasePage from "../../common/base";
import { Constants } from "../../../constants/Constants";
import { SupplierRegionsAction } from "../../../redux/actions";

import DisplayRegions from "./displayMode";
import EditRegions from "./editMode";
import { Region } from "./region.interface";

const Regions = (props: any) => {
	const classes = CommonStyles();
	const DISPLAY: string = "DISPLAY";
	const EDIT: string = "EDIT";
	const [pageMode, setPageMode] = React.useState(DISPLAY);
	const [editRegion, setEditRegion] = React.useState({});
	const onChangeMode = (mode: string, region?: Region) => {
		if (mode === EDIT && !region) {
			console.error("No Region object was given.  Editing a region requires a region object.");
			setPageMode(DISPLAY);
			return -1;
		}

		if (region) {
			setEditRegion(region);
		}
		setPageMode(mode);
	};
	return (
		<BasePage 
			title={Constants.PAGE_TITLE.REGIONS} 
			drawerOpen={true}
			pageNameKey="regions"
		>
			<Container maxWidth={false} className={classes.container}>
				<div className={classes.appBarSpacer}></div>
				{pageMode === DISPLAY ? (
					<DisplayRegions
						onChangeMode={onChangeMode}
						region={editRegion}
					/>
				) : (
					<EditRegions
						onChangeMode={onChangeMode}
						region={editRegion}
					/>
				)}
			</Container>
		</BasePage>
	);
};

const mapStateToProps = (state: any) => ({
	regionsStatesList:
		state.supplierRegion && state.supplierRegion.suppliersRegionsList ? state.supplierRegion.suppliersRegionsList : [],
});

const mapDispatchToProps = (dispatch: any) => ({
	getSupplierRegionsList: (filter: any) => dispatch(SupplierRegionsAction.getSupplierRegionsList(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Regions);
