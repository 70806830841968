import React from 'react';
import { UserRecord } from '../../redux/models/user/user';
import { makeStyles, Theme, createStyles, Chip } from '@material-ui/core';
import { GridContainer } from '../../hooks/functions';
import { UsersStyles } from './users.styles';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    minWidth: '40%',
    'overflow-wrap':'break-word'
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #e0e0e0',
      borderRadius: '3px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    }
  })
)

interface UserDetailsProps {
  thisUser: UserRecord,
  close: () => void
}

export const UserDetails: React.FC<UserDetailsProps> = (props: UserDetailsProps) => {
  const classes = useStyles();
  const userClasses = UsersStyles();

  const renderClaims = () => {
    if (!props.thisUser.claims || Object.keys(props.thisUser.claims).length === 0) {
      return (
        <div>
          <h3>Permissions</h3>
          <div>None</div>
        </div>
      );
    }
    const permissions = Object.keys(props.thisUser.claims).map((tenant) => {
      const permissionsChips = Object.keys(props.thisUser.claims[tenant]).map((resource, index) => {
        return (
          <Chip className={userClasses.chip} key={index} label={`${resource}: ${props.thisUser.claims[tenant][resource]}`} />
        )
      })
      return {
        'label': tenant,
        'value': permissionsChips,

      }
    })
    return (
      <div>
        <h3>Permissions</h3>
        {permissions.map((permission, index) => {
          return <div key={index}>{GridContainer([permission])}</div>
        })}
      </div>
    )
  }

  return (
    <div style={getModalStyle()} className={classes.paper}>
      <div>
        <h2>User Details</h2>
        {GridContainer([
          { "label": "Name", "value": `${props.thisUser.firstName} ${props.thisUser.lastName}` },
          { "label": "Email", "value": props.thisUser.email },
        ])}
        {renderClaims()}
      </div>
    </div>
  )
}