import React from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import SaveFilter from './saveFilter';
import FilterList from './filterList';
import { useStyles } from './styles'

interface FiltersProps {
  pageName: string;
  filters: any;
  changeFilter: (filter: any) => void;
  componentKey?:any;
}

const AdvanceFilters = (props: FiltersProps) => {

  const classes = useStyles();
  const [openSaveFilter, setOpenSaveFilter] = React.useState(false);
  const handleClose = () => {
    setOpenSaveFilter(false);
  }
  const handleSaveFilter = () => {
    if(props.filters){
      setOpenSaveFilter(true);
    }
  }
  const changeFilter = (value:any) =>{
    props.changeFilter(value);
  }
  return (
    <div>
      <div className={classes.saveSearchLeft}>
        <Button
          onClick={handleSaveFilter}
          type="button"
          className={classes.saveSeachButton}
          variant="contained"
        >Save As</Button>
      </div>
      <div className={classes.saveSearchRight}>
        <FilterList componentKey={props.componentKey} changeFilter={changeFilter} pageName={props.pageName}/>
      </div>
      <SaveFilter filters={props.filters} pageName={props.pageName} open={openSaveFilter} handleClose={handleClose} />
    </div>
  )
}
const mapStateToProps = (state: any) => {
  return {};
}
const mapDispatchToProps = (dispatch: any) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(AdvanceFilters);