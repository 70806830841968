import React from 'react';
import { connect } from 'react-redux';
import { Container, Paper, Tabs, Tab, Typography, Box } from '@material-ui/core';
import BasePage from '../common/base';
import { CommonStyles } from '../../hooks/styles';
import Profile from './profile';
import PersonPinIcon from '@material-ui/icons/PersonPin';
const TabPanel = (props: any) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const a11yProps = (index: any) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const UserProfile = (props: any) => {
    const classes = CommonStyles();
    const [value, setValue] = React.useState(0);
    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };
    return (
		<BasePage title={"Profile"} drawerOpen={true}>
			<div>
				<div style={{ minHeight: "100px" }}></div>
				<Container maxWidth={false} className={classes.container}>
					<Paper square>
						<Tabs
							value={value}
							onChange={handleChange}
							aria-label="simple tabs example"
						>
							<Tab
								label="General"
								{...a11yProps(0)}
								icon={<PersonPinIcon />}
							/>
						</Tabs>
					</Paper>
					<TabPanel value={value} index={0}>
						<Profile />
					</TabPanel>
				</Container>
			</div>
		</BasePage>
	);
};
const mapStateToProps = (state: any) => (
    {}
);
export default connect(mapStateToProps)(UserProfile);