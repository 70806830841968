import {
	GET_SUPPLIER_REGIONS,
	GET_REGIONS_STATES,
	GET_STATES_LIST,
	UPDATE_REGIONS_STATES,
	UPDATE_SUPPLIER_REGIONS,
	CREATE_SUPPLIER_REGIONS,
	DELETE_SUPPLIER_REGIONS,
	REGIONS,
} from "../constants/api.url";
import { HttpResponse, ResultSet } from "../models/common/result.set";
import Api from "../lib/api";

export class SupplierRegionsService {
	private static instance: SupplierRegionsService;
	private constructor() {}

	public static getInstance(): SupplierRegionsService {
		if (!SupplierRegionsService.instance) {
			SupplierRegionsService.instance = new SupplierRegionsService();
		}
		return SupplierRegionsService.instance;
	}

	public getSupplierRegionsList = async (filter: any | null): Promise<HttpResponse<ResultSet<any>>> => {
		return await Api.get(`${GET_SUPPLIER_REGIONS.URL}/${filter.supplierCode}`, []);
	};

	public getRegionsStatesList = async (filter: any | null): Promise<HttpResponse<ResultSet<any>>> => {
		const params = [{ key: "filter", value: filter }];
		return await Api.get(`${GET_REGIONS_STATES.URL}`, params);
	};

	public getStatesList = async (filter: any | null): Promise<HttpResponse<ResultSet<any>>> => {
		return await Api.get(`${GET_STATES_LIST.URL}/${filter.supplierCode}`, []);
	};

	public updateRegionState = async (params: any | null): Promise<HttpResponse<ResultSet<any>>> => {
		return await Api.post(`${UPDATE_REGIONS_STATES.URL}`, params);
	};

	public updateRegionName = async (params: any | null): Promise<HttpResponse<ResultSet<any>>> => {
		return await Api.post(`${UPDATE_SUPPLIER_REGIONS.URL}`, params);
	};

	public createRegion = async (params: any | null): Promise<HttpResponse<ResultSet<any>>> => {
		return await Api.post(`${CREATE_SUPPLIER_REGIONS.URL}`, params);
	};

	public deleteRegion = async (params: any | null): Promise<HttpResponse<ResultSet<any>>> => {
		return await Api.delete(`${DELETE_SUPPLIER_REGIONS.URL}/${params.id}`, []);
	};

	// New Region CRUD
	public create = async (params: createRegionParams): Promise<HttpResponse<ResultSet<any>>> => {
		return await Api.post(`${REGIONS.CREATE}`, params);
	};
	public read = async (filter: any): Promise<HttpResponse<ResultSet<any>>> => {
		const params = [{key: 'filter', value: JSON.stringify(filter)}]
		const result = await Api.get(`${REGIONS.READ}`, params);
		return result;
	};
	public update = async (params: updateRegionParams): Promise<HttpResponse<ResultSet<any>>> => {
		return await Api.put(`${REGIONS.UPDATE}/${params.id}`, params);
	};
	public delete = async (regionId: string): Promise<HttpResponse<ResultSet<any>>> => {
		return await Api.delete(`${REGIONS.DELETE}/${regionId}`, null);
	};

	public getAvailableRegionData = async (regionType: string): Promise<HttpResponse<ResultSet<any>>> => {
		return await Api.get(`${REGIONS.GET_AVAILABLE_DATA}`, [{ key: "regionType", value: regionType }]);
	};

	public uploadRegionDataCSV = async (payload: any): Promise<HttpResponse<ResultSet<any>>> => {
		return await Api.put(`${REGIONS.UPLOAD_CSV}/${payload.regionType}/${payload.regionName}`, payload.formData);
	};

	public downloadRegionDataCSVTemplates = async (): Promise<HttpResponse<ResultSet<any>>> => {
		const params = {option:  {content_type: "zip"} };
		return await Api.post(`${REGIONS.DOWNLOAD_CSV_TEMPLATES}`, params);
	};
}

export interface getRegionResult {
	id: string;
	userEmail: string;
	regionName: string;
	regionType: string;
	regionData: Array<string>;
}

export interface createRegionParams {
	regionName: string;
	regionType: string;
	regionData: Array<string>;
}

export interface updateRegionParams {
	id: string;
	regionName?: string;
	regionType?: string;
	regionData?: string;
}
