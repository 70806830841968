import React from 'react';
import { connect } from 'react-redux';
import { Grid, Paper } from '@material-ui/core';
import { DashboardStyles } from '../../hooks/styles'
import { NumberFormatter, NumberFormatterWithCommas, warehousePermission } from '../../hooks/functions';
import Skeleton from '@material-ui/lab/Skeleton';

const InventoryRollUp = (props: any) => {

  const classes = DashboardStyles();
  const createInventoryRollUp = (inventoryRollUpList: any[], colWidth?: any) => {
    const rollupLabelMap: any = { NCDC: "DC: Kernersville, NC", TXDC: "DC: Flower Mound, TX", SCDC: "DC: Temecula, CA", customer: 'Forward Deployed Inventory' }

    let list: any = []
    Object.keys(rollupLabelMap).forEach((labelId: string) => {
      const listItem = inventoryRollUpList.find((listItem: any) => listItem.id === labelId)
      if (listItem) {
        list.push({
          ...listItem,
          label: rollupLabelMap[labelId]
        })
      }
      if (!listItem) {
        list.push({id: labelId, value: 0, cost: 0, label: rollupLabelMap[labelId]})
      }
    })

    const cabinetTypeMap: any = {
      'EM': 'RightNow Ready',
      'RFID': 'RightNow Verified',
      'Virtual': 'RightNow Cloud',
      'WEIGHT': 'RightNow Flex',
      'total': 'Total'
    }

    const getWarehouseInventorySummary = () => {
      const warehouses = ['NCDC', 'TXDC', 'SCDC']
      return (
        warehouses.map((whId: string) => {
          const warehouseObj = list.find((obj: any) => obj.id === whId)
          return (
            <Grid item sm={true} key={whId} style={{ margin: '0 auto', width: '100%' }}>
              <Paper className={`${classes.gridPaper} ${classes.inventoryRollUpGridPaper}`}>
                <div className={classes.infoBox}>
                  <div className={classes.labelInfo}>{warehouseObj.label}</div>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center'
                     }}>
                      <div style={{marginRight: 8 }}>
                        Sellable Units: <span style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: 16}}>{NumberFormatterWithCommas(warehouseObj.value)}</span>
                      </div>
                      <div style={{marginLeft: 8 }}>
                        Avg. Product Acquisition Cost: <span style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: 16}}>${NumberFormatterWithCommas(+warehouseObj.cost)}</span>
                      </div>
                    </div>
                </div>
              </Paper>
            </Grid>
          )
        })
      )
    }

    const getFDISummary = () => {
      const customerObj = list.find((obj: any) => obj.id === 'customer')
      return (
        <>
          <Grid item sm={true} style={{ margin: '0 auto', width: '100%' }}>
            <Paper className={`${classes.gridPaper} ${classes.inventoryRollUpGridPaper}`}>
              <div className={classes.infoBox}>
                <div className={classes.labelInfo}>Inventory</div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                    }}>
                    <div style={{marginRight: 8 }}>
                      Sellable Units: <span style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: 16}}>{NumberFormatterWithCommas(customerObj.value)}</span>
                    </div>
                    <div style={{marginLeft: 8 }}>
                      Avg. Product Acquisition Cost: <span style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: 16}}>${NumberFormatterWithCommas(+customerObj.cost)}</span>
                    </div>
                  </div>
              </div>
            </Paper>
          </Grid>
          <Grid item sm={true} style={{ margin: '0 auto', width: '100%' }}>
            <Paper className={`${classes.gridPaper} ${classes.inventoryRollUpGridPaper}`}>
              <div className={classes.infoBox}>
                <div className={classes.labelInfo}>Customers</div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                    }}>
                    <div style={{marginRight: 8 }}>
                      Total Customers: <span style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: 16}}>{NumberFormatterWithCommas(props.customerCabinetCounts?.customerCount?.customers)}</span>
                    </div>
                    <div style={{marginRight: 8 }}>
                      Total States: <span style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: 16}}>{NumberFormatterWithCommas(props.customerCabinetCounts?.customerCount?.states)}</span>
                    </div>
                  </div>
              </div>
            </Paper>
          </Grid>
          <Grid item sm={true} style={{ margin: '0 auto', width: '100%' }}>
            <Paper className={`${classes.gridPaper} ${classes.inventoryRollUpGridPaper}`}>
              <div className={classes.infoBox}>
                <div className={classes.labelInfo}>Technology</div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0 10%'
                    }}>
                    {Object.keys(props.customerCabinetCounts?.cabinetCount || {}).map((cabinetType: string) => {
                      return <div style={{marginRight: 8 }}>
                        {cabinetTypeMap[cabinetType]}: <span style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: 16}}>{props.customerCabinetCounts?.cabinetCount[cabinetType]}</span>
                      </div>
                    })}
                  </div>
              </div>
            </Paper>
          </Grid>
        </>
      )
    }

    return (
      <>
      <h4 className={classes.titleHeading}>Warehouse Inventory Summary</h4>
        <Grid container spacing={1} >
          {getWarehouseInventorySummary()}
          {/* {
            list.map((obj: any, key: number) => {
              return (
                <Grid item sm={obj.id === 'customer' ? 12 : true} key={key} style={{ margin: '0 auto', width: '100%' }}>
                  <Paper className={`${classes.gridPaper} ${obj.label === 'Forward Deployed Inventory' ? classes.fdiRollUpGridPaper : classes.inventoryRollUpGridPaper}`}>
                    <div className={classes.infoBox} style={obj.label === 'Forward Deployed Inventory' ? {transform: 'none', paddingTop: 15} : {}}>
                      <div className={classes.labelInfo}>{obj.label}</div>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <div style={{marginRight: 8 }}>
                          Sellable Units: <span style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: 16}}>{NumberFormatterWithCommas(obj.value)}</span>
                        </div>
                        <div style={{marginLeft: 8 }}>
                          Avg. Product Acquisition Cost: <span style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: 16}}>${NumberFormatterWithCommas(+obj.cost)}</span>
                        </div>
                      </div>
                      {obj.label === 'Forward Deployed Inventory' && <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                          <div style={{marginRight: 8 }}>
                            Total Customers: <span style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: 16}}>{props.customerCabinetCounts?.customerCount}</span>
                          </div>
                        </div>}
                        {obj.label === 'Forward Deployed Inventory' && <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        {Object.keys(props.customerCabinetCounts?.cabinetCount || {}).map((cabinetType: string) => {
                          return <div style={{marginRight: 8 }}>
                            {cabinetTypeMap[cabinetType]}: <span style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: 16}}>{props.customerCabinetCounts?.cabinetCount[cabinetType]}</span>
                          </div>
                        })}
                      </div>}
                    </div>
                  </Paper>
                </Grid>
              )
            })
          } */}
        </Grid>
        <h4 className={classes.titleHeading}>Forward Deployed Inventory Summary</h4>
        <Grid container spacing={1}>
          {getFDISummary()}
        </Grid>
      </>
    )
  }
  const createInventoryRollUpSkeleton = (total: number) => {
    return (
      <Grid container spacing={1} >
        {Array.apply(0, Array(total)).map(function (x, i) {
          return (
            <Grid item md style={{ margin: '0 auto', width: '100%' }}>
              <Skeleton variant="rect" height="120px" width="100%" className={classes.inventoryRollUpSkeleton} animation="wave">
                <div style={{ paddingTop: '62%' }} />
              </Skeleton>
            </Grid>
          )
        })}
      </Grid>
    )
  }
  return (
    <div>
      {
        (props.isLoading) ? createInventoryRollUpSkeleton(props.inventoryRollUpData.length) : createInventoryRollUp(props.inventoryRollUpData)
      }
    </div>
  )
}
const mapStateToProps = (state: any) => (
  {
    inventoryRollUpData: (state.dashboard.inventoryRollUpData) ?
      state.dashboard.inventoryRollUpData : [],
    total: (state.dashboard.dashboardDataItems && state.dashboard.dashboardDataItems.total) ?
      state.dashboard.dashboardDataItems.total : [],
    customerCabinetCounts: (state.dashboard.customerCabinetCounts) ?
      state.dashboard.customerCabinetCounts : {},
    isLoading: (typeof state.dashboard.inventoryRoolUpLoader === 'undefined') ? false : state.dashboard.inventoryRoolUpLoader,
    authUser: (state.user.authUser) ? state.user.authUser : [],
    supplierCode: (state.ui.selectedSupplier && state.ui.selectedSupplier.supplierCode) ? state.ui.selectedSupplier.supplierCode : null,
  }
);
export default connect(mapStateToProps)(InventoryRollUp);