import { useTheme, fade, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 13,
        },
        button: {
            width: '100%',
            textAlign: 'left',
            paddingBottom: 8
        },
        tag: {
            marginTop: 3,
            height: 20,
            padding: '.15em 4px',
            fontWeight: 600,
            lineHeight: '15px',
            borderRadius: 2,
        },
        popper: {
            border: '1px solid rgba(27,31,35,.15)',
            boxShadow: '0 3px 12px rgba(27,31,35,.15)',
            borderRadius: 3,
            width: 300,
            zIndex: 99999,
            fontSize: 13,
            color: '#586069',
            backgroundColor: '#f6f8fa',
        },
        header: {
            borderBottom: '1px solid #e1e4e8',
            padding: '8px 10px',
            fontWeight: 600,
            minHeight: 40,
            cursor: 'pointer'
        },
        inputBase: {
            padding: 10,
            width: '100%',
            borderBottom: '1px solid #dfe2e5',
            '& input': {
                borderRadius: 4,
                backgroundColor: theme.palette.common.white,
                padding: 8,
                transition: theme.transitions.create(['border-color', 'box-shadow']),
                border: '1px solid #ced4da',
                fontSize: 14,
                '&:focus': {
                    boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        paper: {
            boxShadow: 'none',
            margin: 0,
            color: '#586069',
            fontSize: 13,
        },
        option: {
            minHeight: 'auto',
            alignItems: 'flex-start',
            padding: 8,
            '&[aria-selected="true"]': {
                backgroundColor: 'transparent',
            },
            '&[data-focus="true"]': {
                backgroundColor: theme.palette.action.hover,
            },
        },
        popperDisablePortal: {
            position: 'relative',
        },
        iconSelected: {
            width: 17,
            height: 17,
            marginRight: 5,
            marginLeft: -2,
        },
        color: {
            width: 14,
            height: 14,
            flexShrink: 0,
            borderRadius: 3,
            marginRight: 8,
            marginTop: 2,
        },
        text: {
            flexGrow: 1,
        },
        close: {
            opacity: 0.6,
            width: 18,
            height: 18,
        },
        expandButton: {
            cursor: 'pointer',
            marginTop: 8,
            background: '#e0e0e0',
            color: '#6a9ab9',
            height: 29,
            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
        },
        popperStyle: {
            width: '200px'
        },
        saveSeachButton: {
            fontSize: '10px!important',
            marginTop: '8px!important'
        },
        saveSearchLeft: {
            float: 'left',
            marginLeft: '15px',
        },
        saveSearchRight: {
            marginLeft: '5px',
            float: 'right'
        },
        saveSearchError: {
            color: '#f44336',
            fontWeight: 'bold'
        },
        filterFormControl: {
            width: '100%', 
            margin: '0 auto'
        },
        saveSearchFormGroup:{
            marginBottom: 10
        },
        dialogActions:{
            padding: 20
        },
        scheduleDialogContent:{
            width: '500px', height: '300px', fontSize: 12
        }


    }),
);