import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Slide, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import MUIDataTable from "mui-datatables";
import { Waypoint } from "react-waypoint";
import { ReportActions, UIAction } from "../../../redux/actions";
import { Scrollbars } from 'react-custom-scrollbars';
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const MakeshiftDrawer = (props: any) => {
  const classes = useStyles();
  const [inventoryData, setInventoryData] = React.useState(props.inventoryData);
  const [pagination, setPaginationData] = React.useState(props.pagination);
  const [sortOrder, setSortOrder] = React.useState("productLabelName ASC");
  const [showMore, setShowMore] = React.useState<boolean>(true);
  React.useEffect(() => {
    fetchData()
  }, [sortOrder])

  React.useEffect(() => {
    setInventoryData(props.inventoryData);
  }, [props.inventoryData]);


  const fetchData = () => {
    let fields: any = { productLabelName: true, ndc: true };
    fields['ndc'] = true;
    fields['state'] = true;
    fields['city'] = true;
    fields['lot'] = true;
    fields['postcode'] = true;
    fields['customerName'] = true;
    fields['gpo'] = true;
    fields['idn'] = true;

    let inventoryFilter: any = {
      where: props.tableFilter,
      fields: fields,
      order: sortOrder.split(' ')[0],
      orderType: sortOrder.split(' ')[1]
    };
    let inventoryPayload = [{ key: 'filter', value: JSON.stringify(inventoryFilter)}]
    if(props.selectedFilterValues['REGION']) inventoryPayload.push({ key: 'regionName', value: props.selectedFilterValues['REGION']})
    props.getInventoryList(inventoryPayload);
  }

  const customBodyRenderFunction = (value?: any, tableMeta?: any, updateValue?: any) => {
  if ('productLabelName' === tableMeta.columnData.name) {
      return (
        <div>
          {tableMeta.rowData[9] ? `${value} (${tableMeta.rowData[9]})` : value}
        </div>);
    } else if ('state' === tableMeta.columnData.name) {
      return (
        <div>
          {(tableMeta.rowData[5] && tableMeta.rowData[6]) ? `${tableMeta.rowData[5]}, ${value} ${tableMeta.rowData[6]}` : value}
        </div>
      );

    } else {
      return value;
    }
  }

  const getColumns = () => {
    const columns = [

      {
        label: "Product",
        name: "productLabelName",
      },

      {
        label: "Customer",
        name: "customerName",
      },
      {
        label: "GPO",
        name: "gpo",
      },
      {
        label: "IDN",
        name: "idn",
      },

      {
        label: "Location",
        name: "state"
      },
      {
        label: "City",
        name: "city"
      },
      {
        label: "Zip",
        name: "postcode"
      },
      {
        label: "On hand",
        name: "onHandQty"
      },
      {
        label: "In-Transit",
        name: "inTransit"
      },
      {
        label: "Ndc",
        name: "ndc"
      },
      {
        label: "Lot",
        name: "lot"
      },
    ];
    let columnsList = columns.map((item, index) => {
      return {
        name: item.name,
        label: item.label,
        options: {
          display: false,
          customBodyRender: customBodyRenderFunction
        }
      }
    });

    columnsList[0].options.display = true;
    columnsList[2].options.display = false;
    columnsList[3].options.display = false;
    columnsList[7].options.display = true;
    columnsList[8].options.display = false;

    if (props.tableFilter && props.tableFilter.productItemId) {
      columnsList[0].options.display = false;

    }
    if (props.tableFilter && props.tableFilter.postcode) {
      columnsList[10].options.display = true;
      columnsList[1].options.display = true;
    } else {
      columnsList[10].options.display = false;
      columnsList[1].options.display = false;
    }
    columnsList[4].options.display = true;
    return columnsList;
  }

  const options = {
    search: false,
    filter: false,
    download: false,
    print: false,
    selectableRows: 'none',
    serverSide: true,
    viewColumns: props.tableFilter?.postcode ? true : false,
    count: (props.totalInventoryCount),
    responsive: "simple",
    fixedHeader: true,
    pagination: false,
    onTableChange: (tableAction: any, tableState: any) => {
      if (tableAction == 'sort') {
        setSortOrder(`${tableState.sortOrder.name} ${tableState.sortOrder.direction}`)
      }
    }
  };

  return (
    <Slide direction="left" in={props.open} >
      <div className={classes.root} style={{ maxHeight: 500, overflow: 'hidden' }} >
        <Scrollbars style={{ height: 450 }}>
            <MUIDataTable style={{ height: '400px' }}
              data={inventoryData}
              columns={getColumns()}
              options={options}
            />
            {props.isMiniLoading && !props.isLoading &&
              <div><div style={{ textAlign: 'center' }}>
                <div>Loading...</div>
                <CircularProgress disableShrink />
              </div>

              </div>
            }
            {(!props.isMiniLoading && inventoryData.length > 0 && props.inventoryData.length == 0) && <h4 style={{ textAlign: 'center' }}>No more records found!</h4>}
        </Scrollbars>
      </div>
    </Slide>
  );
}

const mapStateToProps = (state: any) => (
  {
    inventoryData:
      state.report.inventoryData && state.report.inventoryData.result
        ? state.report.inventoryData.result
        : [],
    inventoryWareHouseData:
      state.report.inventoryWareHouseData && state.report.inventoryWareHouseData
        ? state.report.inventoryWareHouseData
        : [],
    totalInventoryCount:
      state.report.inventoryData && state.report.inventoryData.totalRecord
        ? state.report.inventoryData.totalRecord
        : 0,
    isMiniLoading: state.ui.isMiniLoading,
    isLoading: state.ui.isLoading,
    selectedFilterValues: state.ui.selectedFilterValues || {},
  }
);

const mapDispatchToProps = (dispatch: any) => ({
  getInventoryList: (filter: any) => dispatch(ReportActions.getDrawerData(filter)),
  showMiniLoader: (isLoader: boolean) => dispatch(UIAction.showMiniLoader(isLoader))
});

export default connect(mapStateToProps, mapDispatchToProps)(MakeshiftDrawer);