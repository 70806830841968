import React,{useEffect,useRef} from 'react';
import { useDispatch } from 'react-redux';
import BasePage from '../common/base';
import TransactionComponent from './transactions.component';
import { Container } from '@material-ui/core';
import { DashboardStyles } from '../../hooks/styles';
import {Constants} from "../../constants/Constants";

const Transactions = () => {
  const classes = DashboardStyles();

  const isFirstRender = useRef(true)
  const dispatch = useDispatch();
  useEffect(() => {
    isFirstRender.current = false
  }, [dispatch])

  return (
		<div>
			<BasePage
				title={Constants.PAGE_TITLE.TRANSACTIONS}
				drawerOpen={true}
				pageNameKey="transactions"
			>
				<div className={classes.appBarSpacer}></div>
				<Container maxWidth={false} className={classes.container}>
					<TransactionComponent />
				</Container>
			</BasePage>
		</div>
  );
}

export default Transactions;