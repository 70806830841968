import React from 'react';
import { connect } from 'react-redux';
import { Grid, Paper } from '@material-ui/core';
import { DashboardStyles } from '../../hooks/styles'
import Skeleton from '@material-ui/lab/Skeleton';
import LineChart from '../../components/highcharts/lineChart';
import { Alert } from '@material-ui/lab';
interface PieChartProps {
  comparisionForSaleData: any;
  comparisionForSaleLoader: boolean;
  bigQueryFilter: any;
}

const OnHandPieChartSection = (props: PieChartProps) => {

  const classes = DashboardStyles();
  let [comparisionForSaleData,setComparisionForSaleData] = React.useState([]);
  const monthList: any[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const dollarSign: string = (props.bigQueryFilter && props.bigQueryFilter.valueType && props.bigQueryFilter.valueType === "1") ? '$' : '';
  const createSkeleton = () => {
    return (
      <Skeleton variant="rect" width="100%" style={{ borderRadius: '1%' }} animation="wave">
        <div style={{ paddingTop: '57%' }} />
      </Skeleton>
    )
  }

  const getComparisionForSaleData = (comparisionForSaleDataList: any[]) => {
    let data: any = [];
    let countValue = 0;
    
    comparisionForSaleDataList.forEach((item: { year: string, month: string, value: string, cost: string }) => {
      const index = data.findIndex((obj: { name: string, data: any[] }) => obj.name == item.year);
      
      if (index == -1) {
        let dataObj: any = {};
        dataObj['name'] = `${item.year}`;
        dataObj['data'] = [];
        dataObj.data.push({y: Number(item.cost), units: Number(item.value)});
        data.push(dataObj);
      } 
      else {
        data[index].data.push({y: Number(item.cost), units: Number(item.value)});
      }

      countValue = countValue + Number(item.cost);
    });

    if (countValue > 0 && data[0] && data[1]) {
      data[0].color = '#0063a1';
      data[1].color = '#1c2130';
      return data;
    }

    return [];
  }

  React.useEffect(() => {
    comparisionForSaleData = [];
    if (props.comparisionForSaleData && props.comparisionForSaleData.length > 0) {
      comparisionForSaleData = getComparisionForSaleData(props.comparisionForSaleData);
    }
    setComparisionForSaleData(comparisionForSaleData);
  }, [props.comparisionForSaleData]);


  return (
    <div>
      <Grid container spacing={2} >
        <Grid item xs={12}>
          <h4>Year over Year Unit Usage &amp; Avg. Product Acquisition Cost</h4>
          <Paper className={classes.gridPaper}>
            {(props.comparisionForSaleLoader) ? createSkeleton()
              : <Grid container >
                  <Grid item md={12}>
                    {(props.comparisionForSaleData?.length && comparisionForSaleData && comparisionForSaleData.length > 0 && monthList) ?
                      <LineChart
                        datalabel={'$'}
                        yAxisTitleText={'Sales'}
                        categories={monthList}
                        series={comparisionForSaleData}
                      />
                      : <Alert severity={'error'} className={classes.normalErrorColor}>Not enough data available</Alert>
                    }
                  </Grid>
              </Grid>
            }
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}
const mapStateToProps = (state: any) => (
  {
    comparisionForSaleLoader: (typeof state.dashboard.comparisionForSaleLoader === 'undefined') ? false : state.dashboard.comparisionForSaleLoader,
    comparisionForSaleData: (state.dashboard && state.dashboard.comparisionForSaleData) ? state.dashboard.comparisionForSaleData : [],
  }
);
export default connect(mapStateToProps)(OnHandPieChartSection);