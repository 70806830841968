

//import { TenantsFilter, TenantsList} from "../models/tenants/tenants";
import { GET_REGION_GRAPH_DATA, GET_INVENTORY_LIST, GET_INVENTORY_WAREHOUSE_DATA, GET_INVENTORY_WAREHOUSE_DRAWER_DATA } from '../constants/api.url';
import { HttpResponse, ResultSet } from "../models/common/result.set";
import Api from "../lib/api";


export class ReportService {

  private static instance: ReportService;
  private constructor() { }

  public static getInstance(): ReportService {
    if (!ReportService.instance) {
      ReportService.instance = new ReportService();
    }
    return ReportService.instance;
  }

  public getRegionGraph = async (payload: any | null): Promise<HttpResponse<ResultSet<any>>> => {
    return await Api.get(`${GET_REGION_GRAPH_DATA.URL}`, payload)
  }

  public getInventory = async (payload: any | null): Promise<HttpResponse<ResultSet<any>>> => {
    return await Api.get(`${GET_INVENTORY_LIST.URL}`, payload)
  }

  public getWareHouseData = async (filter: any | null): Promise<HttpResponse<ResultSet<any>>> => {
    let paramString: string = '';
    Object.keys(filter).forEach(function (key: any, index: number) {
      if (filter[key] !== undefined && filter[key] !== 'undefined')
        paramString += (index > 0) ? '&' + key + '=' + filter[key] : '?' + key + '=' + filter[key]
    })
    return await Api.get(`${GET_INVENTORY_WAREHOUSE_DATA.URL}${paramString}`, [])
  }

  public getWareHouseDrawerData = async (filter: any | null): Promise<HttpResponse<ResultSet<any>>> => {
    let paramString: string = '';
    Object.keys(filter).forEach(function (key: any, index: number) {
      if (filter[key] !== undefined && filter[key] !== 'undefined') {
        paramString += (index > 0) ? '&' + key + '=' + filter[key] : '?' + key + '=' + filter[key]
      }
    })
    return await Api.get(`${GET_INVENTORY_WAREHOUSE_DRAWER_DATA.URL}${paramString}`, [])
  }


}