import { call, delay, put } from "redux-saga/effects";
import { SupplierActions, SupplierRegionsAction, UIAction } from "../actions/index";
import { SupplierRegionsService, SupplierService } from "../services";
import { AuthCheck } from "../lib/authCheck";
import { Constants } from "../../constants/Constants";

const empty: any = [];
export function* getSupplierRegionsList(action: ReturnType<typeof SupplierRegionsAction.getSupplierRegionsList>) {
	try {
		yield put(UIAction.setApiError(null));
		yield put(UIAction.showLoader(true));
		yield call(AuthCheck.getInstance().check, {
			action: "read",
			resource: "region",
			tenantId: action.payload.supplierCode,
		});
		const { data } = yield call(SupplierRegionsService.getInstance().getSupplierRegionsList, action.payload);
		yield put(SupplierRegionsAction.successSupplierRegionsList(data));
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		yield put(SupplierRegionsAction.successSupplierRegionsList(empty));
		yield put(UIAction.showLoader(false));
		const errorMessage = err.message.includes("403") ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
		yield put(UIAction.setApiError({ errorMessage: errorMessage, severity: "error", title: "Error" }));
	}
}

export function* getRegionsStatesList(action: ReturnType<typeof SupplierRegionsAction.getRegionsStatesList>) {
	try {
		yield put(UIAction.showLoader(true));
		const { data } = yield call(SupplierRegionsService.getInstance().getRegionsStatesList, action.payload.filter);
		let result: any = [];
		result.data = data;
		result.type = action.payload.type;
		yield put(SupplierRegionsAction.successRegionsStateList(result));
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		yield put(UIAction.showLoader(false));
	}
}

export function* getStatesList(action: ReturnType<typeof SupplierRegionsAction.getStatesList>) {
	try {
		yield put(UIAction.showLoader(true));
		const { data } = yield call(SupplierRegionsService.getInstance().getStatesList, action.payload);
		yield put(SupplierRegionsAction.successStateList(data));
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		yield put(UIAction.showLoader(false));
	}
}

export function* updateRegionState(action: ReturnType<typeof SupplierRegionsAction.updateRegionState>) {
	try {
		yield put(UIAction.showLoader(true));
		let updatePayload: any = action.payload;
		const { data } = yield call(SupplierRegionsService.getInstance().updateRegionState, updatePayload);
		yield put(SupplierRegionsAction.successSupplierRegionsList(data));
		yield put(UIAction.showLoader(false));
	} catch (err) {
		yield put(UIAction.showLoader(false));
	}
}

export function* updateRegionName(action: ReturnType<typeof SupplierRegionsAction.updateRegionName>) {
	try {
		let updatePayload: any = action.payload;
		const { data } = yield call(SupplierRegionsService.getInstance().updateRegionName, updatePayload);
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		yield put(UIAction.showLoader(false));
	}
}

export function* createRegion(action: ReturnType<typeof SupplierRegionsAction.createRegion>) {
	try {
		yield put(UIAction.showLoader(true));
		yield call(SupplierRegionsService.getInstance().createRegion, action.payload);
		let listPayload: any = {};
		listPayload.supplierCode = action.payload.supplierCode;
		yield put(SupplierRegionsAction.getSupplierRegionsList(listPayload));
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		yield put(UIAction.showLoader(false));
	}
}

export function* deleteRegion(action: ReturnType<typeof SupplierRegionsAction.deleteRegion>) {
	try {
		yield put(UIAction.showLoader(true));
		yield call(SupplierRegionsService.getInstance().deleteRegion, action.payload);
		let listPayload: any = {};
		listPayload.supplierCode = action.payload.supplierCode;
		yield put(SupplierRegionsAction.getSupplierRegionsList(listPayload));
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		yield put(UIAction.showLoader(false));
	}
}

export function* createRegionSaga(action: ReturnType<typeof SupplierRegionsAction.createRegionAction>) {
	try {
		yield put(UIAction.showLoader(true));
		yield call(SupplierRegionsService.getInstance().create, action.payload);
		//get new list of user regions for filter
        const {data} = yield call(SupplierService.getInstance().getRegions)
        yield put(SupplierActions.successRegionsList(data))
		action.payload.callback()
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		console.error("Error Creating region in saga: ", err);
		yield put(UIAction.showLoader(false));
	}
}

export function* readRegionSaga(action: ReturnType<typeof SupplierRegionsAction.readRegionAction>) {
	try {
		yield put(UIAction.showLoader(true));
		const { data } = yield call(SupplierRegionsService.getInstance().read, action.payload);
		yield put(SupplierRegionsAction.successReadRegionAction(data));
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		console.error("Error Reading region in saga: ", err);
		yield put(UIAction.showLoader(false));
	}
}

export function* updateRegionSaga(action: ReturnType<typeof SupplierRegionsAction.updateRegionAction>) {
	try {
		yield put(UIAction.showLoader(true));
		yield call(SupplierRegionsService.getInstance().update, action.payload);
		const { data } = yield call(SupplierRegionsService.getInstance().read, {});
		yield put(SupplierRegionsAction.successReadRegionAction(data));
		yield put(UIAction.showLoader(false));
		action.payload.callback()
	} catch (err: any) {
		console.error("Error Updating region in saga: ", err);
		yield put(UIAction.showLoader(false));
	}
}

export function* deleteRegionSaga(action: ReturnType<typeof SupplierRegionsAction.deleteRegionAction>) {
	try {
		yield put(UIAction.showLoader(true));
		yield call(SupplierRegionsService.getInstance().delete, action.payload);
		//get new list of user regions for filter
        const {data} = yield call(SupplierService.getInstance().getRegions)
        yield put(SupplierActions.successRegionsList(data))
		//reset filters
		yield put(UIAction.resetAllFilters())
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		console.error("Error Deleting region in saga: ", err);
		yield put(UIAction.showLoader(false));
	}
}

export function* getAvailableRegionDataSaga(
	action: ReturnType<typeof SupplierRegionsAction.getAvailableRegionDataAction>
) {
	try {
		yield put(UIAction.showLoader(true));
		const { data } = yield call(SupplierRegionsService.getInstance().getAvailableRegionData, action.payload);
		yield put(SupplierRegionsAction.successGetAvailableRegionDataAction(data));
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		console.error("Error getting available region data in saga: ", err);
		yield put(UIAction.showLoader(false));
	}
}

export function* uploadRegionDataCSV(action: ReturnType<typeof SupplierRegionsAction.uploadRegionDataCSV>) {
	try {
		yield put(UIAction.showLoader(true));
		yield call(SupplierRegionsService.getInstance().uploadRegionDataCSV, action.payload);
		yield put(UIAction.showLoader(false));
		action.payload.callback()
	} catch (err: any) {
		console.error("Error Uploading Region Data CSV in saga: ", err);
		yield put(UIAction.showLoader(false));
		yield put(UIAction.setApiError({ errorMessage: Constants.ALERT.REGIONS_CSV_ERROR, severity: 'error', title: "Error" }));
        yield delay(Constants.ALERT.DELAY);
        yield put(UIAction.setApiError(null));
	}
}

export function* downloadRegionDataCSVTemplates(action: ReturnType<typeof SupplierRegionsAction.downloadRegionDataCSVTemplates>) {
	try {
		yield put(UIAction.showLoader(true));
		const { data } = yield call(SupplierRegionsService.getInstance().downloadRegionDataCSVTemplates);
		var blob = new Blob([data], {type: "application/zip"});
		var link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = "region_data_templates.zip";
		link.click();
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		console.error("Error Uploading Region Data CSV in saga: ", err);
		yield put(UIAction.showLoader(false));
		yield put(UIAction.setApiError({ errorMessage: Constants.ALERT.REGIONS_CSV_ERROR, severity: 'error', title: "Error" }));
        yield delay(Constants.ALERT.DELAY);
        yield put(UIAction.setApiError(null));
	}
}
