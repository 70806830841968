import React,{useEffect,useRef} from 'react';
import { useDispatch } from 'react-redux';
import BasePage from '../common/base';
import UsersContainer from './users.container';
import { Container } from '@material-ui/core';
import { DashboardStyles } from '../../hooks/styles';
import {Constants} from "../../constants/Constants";
const Users = () => {
  const classes = DashboardStyles();

  const isFirstRender = useRef(true)
  const dispatch = useDispatch();
  useEffect(() => {
    isFirstRender.current = false
  }, [dispatch])

  return (
		<div>
			<BasePage 
				title={Constants.PAGE_TITLE.USERS} 
				drawerOpen={true}
				pageNameKey="users"
			>
				<div className={classes.appBarSpacer}></div>
				<Container maxWidth={false} className={classes.container}>
					<UsersContainer />
				</Container>
			</BasePage>
		</div>
  );
}

export default Users;