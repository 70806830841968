import { call, put, delay, all, select } from 'redux-saga/effects';
import { ProductExpirationAction, UIAction } from '../actions/index';
import { ProductExpiryService } from '../services';
import { Constants } from "../../constants/Constants";
import { AuthCheck } from "../lib/authCheck";
const empty: any = [];


const generateGraphData = (rowData: any, filter: any) => {
    let regionGraphData: any = [];
    rowData.forEach((item: any) => {
        let graphDataObj: any = {};
        if (item.state) {
            // console.log('ITEM', item)
            graphDataObj = {
                "plant": (item.plant) ? item.plant : null,
                "code": `us-${item.state.toLowerCase()}`,
                "value": (item.onHandQty) ? item.onHandQty : (item.onHnadQty) ? item.onHnadQty : 0,
                "filter": filter,
                "stateCode": item.state
            };
            regionGraphData.push(graphDataObj);
        }
    });
    return regionGraphData;
}


export function* getExpiryDataList(action: ReturnType<typeof ProductExpirationAction.getExpiryDatalist>) {
    try {
        yield put(UIAction.showMiniLoader(true))
        yield put(UIAction.setApiError(null));
        const selectedState: string = yield select((state) => state.productExpiration.selectedState || '')
        if (selectedState === action.payload.state) {
            delete action.payload.state
            yield put(ProductExpirationAction.setSelectedState(''));
        }
        if (action.payload.state && selectedState !== action.payload.state) {
            yield put(ProductExpirationAction.setSelectedState(action.payload.state));
        }
        const tenantId = JSON.parse(action.payload[0].value);
        yield call(AuthCheck.getInstance().check, { action: 'read', resource: 'productExpiration', tenantId: tenantId });
        const { data } = yield call(ProductExpiryService.getInstance().getExpiryDataList, action.payload);
        if (data.result && data.result.length > 0) {
            yield put(ProductExpirationAction.successExpiryDatalist(data));
        } else {
            yield put(ProductExpirationAction.successExpiryDatalist(empty));
            yield put(UIAction.setApiError({ errorMessage: Constants.ALERT.NO_RECORD_FOUND, severity: 'info', title: "Info" }));
        }
        yield put(UIAction.showMiniLoader(false));
    } catch (err: any) {
        yield put(UIAction.showMiniLoader(false));
        yield put(ProductExpirationAction.successExpiryDatalist(empty));
        const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
        yield put(UIAction.setApiError({ errorMessage: errorMessage, severity: 'error', title: "Error" }));
    }
}

export function* getExpiryGraphData(action: ReturnType<typeof ProductExpirationAction.getExpiryGraphData>) {
    try {
        if (action.payload.showLoader) {
            yield put(UIAction.showLoader(true));
        }
        const tenantId = JSON.parse(action.payload.params[0].value);
        yield call(AuthCheck.getInstance().check, { action: 'read', resource: 'productExpiration', tenantId: tenantId });
        const { data } = yield call(ProductExpiryService.getInstance().getExpiryGraphData, action.payload.params);
        let regionGraphData: any = [];
        const inventoryData: any = (data.consignment) ? (data.consignment) : (data.nonConsignment) ? data.nonConsignment : [];
        if (inventoryData.length > 0) {
            regionGraphData = generateGraphData(inventoryData, action.payload.filter);
        }
        yield put(ProductExpirationAction.successExpiryGraphData(regionGraphData));
        yield put(UIAction.showLoader(false));
    } catch (err: any) {
        yield put(UIAction.showLoader(false));
        yield put(ProductExpirationAction.successExpiryGraphData(empty));
        const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
        yield put(UIAction.setApiError({ errorMessage: errorMessage, severity: 'error', title: "Error" }));
    }
}
