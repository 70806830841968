import React from 'react';
import { Button, Dialog, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { CommonStyles } from '../../../hooks/styles'
import { DialogTitle, DialogContent,DialogActions}  from '../dialogProps';


export default function MapRegionsDialogs() {
  const [open, setOpen] = React.useState(false);
  const classes = CommonStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const top100Films = [
    { title: 'Florida', year: 1994 },
    { title: 'Alabama', year: 1972 },
    { title: 'Hawaii', year: 1974 },
  ];

  return (
    <div>



      <Button
        onClick={handleClickOpen}
        type="button"
        className={classes.viewAllBtn}
        variant="contained"
        color="primary"
      >Map Regions</Button>


      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} >

        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Supplier Regions
        </DialogTitle>

        <DialogContent dividers style={{ width: '500px', height: '200px',fontSize:12 }}>
          <FormGroup row style={{ marginBottom: 10 }}>
            <FormControlLabel
              style={{fontSize:12}}
              control={
                <Checkbox
                  //checked={}
                  //onChange={handleChange}
                  name="checkedB"
                  color="primary"
                />
              }
              label="All Regions"
            />

          </FormGroup>


          <FormGroup row>
            <Autocomplete
              style={{ width: '100%' }}
              multiple
              id="tags-standard"
              options={top100Films}
              getOptionLabel={(option) => option.title}
              defaultValue={[top100Films[2]]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Supplier Regions"
                  //placeholder="Supplier Regions"
                />
              )}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>


          <Button
            onClick={handleClose}
            type="button"
            className={classes.viewAllBtn}
            variant="contained"
            color="primary"
          >Save</Button>

        </DialogActions>
      </Dialog>
    </div>
  );
}