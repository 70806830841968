import Moment from 'moment';
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment as any);

const todayDate = moment();
export const dateRangesList = [
    {
        label: 'Next 7 days',
        value: 'Next_7_days',
        date: moment.range(
            todayDate.clone(),
            todayDate.clone().add(7, 'days')
        )
    },
    {
        label: 'Next 15 days',
        value: 'Next_15_days',
        date: moment.range(
            todayDate.clone(),
            todayDate.clone().add(15, 'days')
        )
    },
    {
        label: 'Next 3 months',
        value: 'Next_3_months',
        date: moment.range(
            todayDate.clone(),
            todayDate.clone().add(3, 'months')
        )
    },
    {
        label: 'Next 6 Months',
        value: 'Next_6_months',
        date: moment.range(
            todayDate.clone(),
            todayDate.clone().add(6, 'months')
        )
    },
    {
        label: 'Current Month',
        value: 'Current_Month',
        date: moment.range(
            moment().clone().startOf('month').add(1, 'days'),
            moment().clone().endOf('month')
        )
    },
];
