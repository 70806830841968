import { action } from 'typesafe-actions';
import { REPORT } from '../constants/actions';
import { ResultSet } from '../models/common/result.set';

export const getRegionGraphData   = (data: any) => action(REPORT.REGION_GRAPH.GET_REGION_GRAPH_DATA, data);
export const regionGraphDataSuccess   = (data: ResultSet<any>) => action(REPORT.REGION_GRAPH.REGION_GRAPH_DATA_SUCCESS, data);
export const clearRegionGraphData   = () => action(REPORT.REGION_GRAPH.CLEAR_REGION_GRAPH_DATA);


export const getDrawerData   = (data: any) => action(REPORT.DRAWER_DATA.GET_DRAWER_DATA, data);
export const successDrawerData   = (data: ResultSet<any>) => action(REPORT.DRAWER_DATA.SUCCESS_DRAWER_DATA, data);


export const getWareHouseData   = (data: any) => action(REPORT.REGION_GRAPH.GET_WAREHOUSE_DATA , data);
export const successWareHouseData   = (data: ResultSet<any>) => action(REPORT.REGION_GRAPH.SUCCESS_WAREHOUSE_DATA, data);
export const clearWareHouseData   = () => action(REPORT.REGION_GRAPH.CLEAR_WAREHOUSE_DATA);

export const getWareHouseDrawerData   = (data: any) => action(REPORT.DRAWER_DATA.GET_WAREHOUSE_DRAWER_DATA , data);
export const successWareHouseDrawerData   = (data: ResultSet<any>) => action(REPORT.DRAWER_DATA.SUCCESS_WAREHOUSE_DRAWER_DATA, data);
