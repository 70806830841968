import React from "react";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import { SupplierRegionsAction } from "../../../redux/actions";
import { RegionTypeMap, RegionTypes } from "./region.interface";
import { DeleteForever } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import TextField from "@material-ui/core/TextField";
import {
	DialogActions,
	DialogContentText,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from "@material-ui/core";
import Box from "@material-ui/core/Box/Box";
import { createRegionParams } from "../../../redux/services";

interface TableMeta {
	rowIndex: number;
	columnIndex: number;
	columnData: Array<any>; // Columns Options object
	rowData: Array<any>; // Full row data
	tableData: Array<any>; // Full table data - Please use currentTableData instead
	currentTableData: Array<any>; // The current table data
	tableState: {
		announceText: null | string;
		page: number;
		rowsPerPage: number;
		filterList: Array<any>;
		selectedRows: {
			data: Array<any>;
			lookup: object;
		};
		showResponsive: boolean;
		searchText: null | string;
	};
}

interface Region {
	id: string;
	regionData: Array<any>;
	regionName: string;
	regionType: string;
	userEmail: string;
}

const RegionList = (props: any) => {
	const [regionList, setRegionList] = React.useState(Array<Region>());
	const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
	const [selectedFile, setSelectedFile] = React.useState({} as any);
	const [isFilePicked, setIsFilePicked] = React.useState(false);
	const [newRegion, setNewRegion] = React.useState({
		name: "",
		type: "",
	});
	const [newRegionNameError, setNewRegionNameError] = React.useState("");
	const [sortOrder, setSortOrder] = React.useState(null as any);

	const handleEditRegion = (tableMeta: TableMeta) => {
		props.onChangeMode(
			"EDIT",
			regionList[tableMeta.rowIndex]
				? regionList[tableMeta.rowIndex]
				: "error"
		);
	};
	const handleDeleteRegion = (tableMeta: TableMeta) => {
		props.deleteRegion(regionList[tableMeta.rowIndex].id);
		const smallerList = [...regionList];
		smallerList.splice(tableMeta.rowIndex, 1);
		setRegionList(smallerList);
	};

	const handleCreateNewRegionBtnClicked = () => {
		setOpenCreateDialog(true);
	};

	const handleCreateDialogClose = () => {
		setNewRegion({ name: "", type: "" });
		setOpenCreateDialog(false);
	};

	const handleFileChange = (event: any) => {
		setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
	};

	const handleNewRegionTypeSelection = (event: any) => {
		const newerRegion = { ...newRegion };
		newerRegion.type = event.target.value;
		setNewRegion(newerRegion);
	};
	const isNewRegionNameTaken = (newRegionName: string): boolean => {
		return regionList.some((region) => region.regionName === newRegionName);
	};
	const handleNewRegionName = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		// Check if Region name is taken
		if (isNewRegionNameTaken(event.target.value)) {
			setNewRegionNameError(
				"Region name already exists.  You can not have duplicate region names."
			);
			console.error("Duplicate Region Name!");
			return;
		} else {
			// Reset name errors since it's changed
			setNewRegionNameError("");
		}

		const newerRegion = { ...newRegion };
		newerRegion.name = event.target.value;
		setNewRegion(newerRegion);
	};

	const handleSaveNewRegion = () => {
		if (newRegion.name === "") {
			alert("Region name is required!");
			return -1;
		}
		if (newRegion.type === "") {
			alert("Region type is required!");
			return -2;
		}
		if (isNewRegionNameTaken(newRegion.name)) {
			alert("Region name is already taken!");
			return -3;
		}

		props.createRegion({
			regionName: newRegion.name,
			regionType: newRegion.type,
			regionData: [],
			callback: handleUploadRegionDataCSV,
		});
		handleCreateDialogClose();
	};

	const handleUploadRegionDataCSV = () => {
		if (isFilePicked && selectedFile) {
			const formData = new FormData();
			formData.append("File", selectedFile);
			const payload = {
				regionName: newRegion.name,
				regionType: newRegion.type,
				formData: formData,
				callback: handleUploadComplete,
			};
			props.uploadRegionDataCSV(payload);
		} else {
			props.getRegions({order: sortOrder ? [sortOrder] : ['regionName asc']});
		}
	};

	const handleUploadComplete = () => {
		setIsFilePicked(false);
		setSelectedFile({});
		props.getRegions({order: sortOrder ? [sortOrder] : ['regionName asc']});
	};

	const columns = [
		{
			label: "Region Name",
			name: "regionName",
		},
		{
			label: "Region Type",
			name: "regionType",
			options: {
				customBodyRenderLite: (dataIndex: number) => {
					const value: string = regionList[dataIndex].regionType;
					return RegionTypeMap[value as keyof RegionTypes];
				},
			},
		},
		{
			label: "What's Inside",
			name: "regionData",
			options: {
				sort: false,
				customBodyRenderLite: (dataIndex: number) => {
					const value: Array<string> = regionList[
						dataIndex
					].regionData.map((data: any) => data.name);
					return value.join(", ");
				},
			},
		},
		{
			label: "Action",
			name: "action",
			options: {
				sort: false,
				customBodyRender: (
					value: string,
					tableMeta: TableMeta,
					updateVlue: (value: string) => void
				) => {
					return (
						<>
							<IconButton
								color="primary"
								onClick={() => handleEditRegion(tableMeta)}
							>
								<Edit />
							</IconButton>
							<IconButton
								color="secondary"
								onClick={() => handleDeleteRegion(tableMeta)}
							>
								<DeleteForever />
							</IconButton>
						</>
					);
				},
			},
		},
	];

	const tableOptions = {
		search: false,
		filter: false,
		download: false,
		print: false,
		serverSide: true,
		viewColumns: false,
		selectableRows: "none",
		count: 0,
		pagination: false,
		onColumnSortChange: (changedColumn: string, direction: string) => {
			setSortOrder(`${changedColumn} ${direction}`);
		},
	};

	React.useEffect(() => {
		props.getRegions({order: sortOrder ? [sortOrder] : ['regionName asc']});
	}, [sortOrder]);

	React.useEffect(() => {
		const newList = [...props.regionsList];
		if (
			props.editedRegion &&
			props.editedRegion.regionData &&
			props.editedRegion.regionData.length > 0
		) {
			let updateIndex = -1;
			newList.forEach((value: any, index: number) => {
				if (value.id === props.editedRegion.id) {
					updateIndex = index;
					return;
				}
			});
			if (updateIndex >= 0) {
				newList[updateIndex] = { ...props.editedRegion };
			}
		}
		setRegionList(newList);
	}, [props.regionsList]);

	return (
		<>
			<div>
				<MUIDataTable
					data={regionList}
					columns={columns}
					options={tableOptions}
				/>
			</div>
			<div style={{ paddingTop: "20px" }}>
				<Grid container spacing={2} justifyContent="flex-end">
					<Grid item>
						<Button
							variant="outlined"
							onClick={() => handleCreateNewRegionBtnClicked()}
						>
							Create New Region
						</Button>
					</Grid>
				</Grid>
			</div>
			<Dialog open={openCreateDialog} onClose={handleCreateDialogClose}>
				<DialogTitle>Create New Region</DialogTitle>
				<DialogContent>
					<DialogContentText>
						After you create the new region you'll then be able to
						add items to it based off it's type using the "edit"
						button.
					</DialogContentText>
					<TextField
						id="name"
						label="Region Name"
						type="text"
						fullWidth
						variant="standard"
						onChange={handleNewRegionName}
						error={newRegionNameError !== ""}
						helperText={newRegionNameError}
					/>
					<Box component="form" style={{ height: "60px" }}>
						<FormControl variant="standard" fullWidth>
							<InputLabel id="region-type-label">
								Region Type
							</InputLabel>
							<Select
								variant="standard"
								labelId="region-type-label"
								id="region-type-select"
								value={newRegion.type}
								onChange={handleNewRegionTypeSelection}
								label="Region Type"
								style={{ height: "40px" }}
							>
								<MenuItem value="">
									<em>None</em>
								</MenuItem>
								<MenuItem value={"STATE"}>
									{RegionTypeMap["STATE"]}
								</MenuItem>
								<MenuItem value={"ZIP3"}>
									{RegionTypeMap["ZIP3"]}
								</MenuItem>
								<MenuItem value={"GPO"}>
									{RegionTypeMap["GPO"]}
								</MenuItem>
								<MenuItem value={"IDN"}>
									{RegionTypeMap["IDN"]}
								</MenuItem>
							</Select>
						</FormControl>
					</Box>
					<br />
					<DialogContentText>
						**Optional** You may upload a CSV with the desired
						contents of your region. For example data and how to
						format the CSV please click the "Download Templates"
						button below.
					</DialogContentText>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<h1>{props.title}</h1>
							<input
								type="file"
								name="file"
								accept=".csv"
								onChange={handleFileChange}
							/>
							{isFilePicked && selectedFile && (
								<div>
									<p>Filename: {selectedFile.name}</p>
									<p>Filetype: {selectedFile.type}</p>
								</div>
							)}
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						style={{ float: "left" }}
						onClick={props.downloadRegionDataCSVTemplates}
					>
						Download Templates
					</Button>
					<Button
						variant="outlined"
						onClick={handleCreateDialogClose}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						onClick={handleSaveNewRegion}
						disabled={newRegionNameError !== ""}
					>
						Create
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

const mapStateToProps = (state: any) => ({
    authUser: state.user.authUser,
	regionsList:
		state.supplierRegion && state.supplierRegion.regionsList
			? state.supplierRegion.regionsList
			: [],
	currentState: state,
});

const mapDispatchToProps = (dispatch: any) => ({
	getRegions: (filter: any) => dispatch(SupplierRegionsAction.readRegionAction(filter)),
	createRegion: (newRegion: createRegionParams) =>
		dispatch(SupplierRegionsAction.createRegionAction(newRegion)),
	deleteRegion: (regionId: string) =>
		dispatch(SupplierRegionsAction.deleteRegionAction(regionId)),
	uploadRegionDataCSV: (payload: any) =>
		dispatch(SupplierRegionsAction.uploadRegionDataCSV(payload)),
	downloadRegionDataCSVTemplates: () =>
		dispatch(SupplierRegionsAction.downloadRegionDataCSVTemplates()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegionList);
