import { Modal, Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { ArrowBack, ArrowForward, CloudUpload } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import React from "react";
import FileUpload from "../fileUpload/fileUpload";

interface DualListSelectorProperties {
	newSelectionTitle: string;
	newSelectionsData?: Array<any>;
	availableSelectionTitle: string;
	availableOptionsData: Array<any>;
	columns: Array<DualListSelectorColumn>;
	onSelectionComplete: (selections: Array<any>) => void;
	onCancelSelection: () => void;
}
interface DualListSelectorColumn {
	name: string;
	label: string;
}

/**
 * The "Dual List Selector" is two tables side by side that allows selecting many items for the table on the right and
 *  placing them in the table on the left.  This is a way to handle very large lists, like 100 plus items, by allowing
 *  them to filtered and sorted in a fancy table.
 * @param props Series of properties to customize the Selector
 */
export default function DualListSelector(props: DualListSelectorProperties) {
	const [newGroup, setNewGroup] = React.useState(props.newSelectionsData ? props.newSelectionsData : Array<any>());
	const [availableItems, setAvailableItems] = React.useState(props.availableOptionsData);

	const handleAddToRegion = (tableMeta: TableMeta) => {
		tableMeta.currentTableData.splice(tableMeta.rowIndex, 1);
		let dataItem: any;
		availableItems.splice(
			availableItems.findIndex((element: any) => {
				if (element[props.columns[0].name] === tableMeta.rowData[1]) {
					dataItem = element;
					return true;
				}
			}),
			1
		);
		setAvailableItems([...availableItems]);
		setNewGroup([...newGroup, dataItem]);
	};
	const handleRemoveFromRegion = (tableMeta: TableMeta) => {
		// console.log("orginal group", newGroup);
		let dataItem: any;
		newGroup.splice(
			newGroup.findIndex((element: any) => {
				if (element[props.columns[0].name] === tableMeta.rowData[0]) {
					dataItem = element;
					return true;
				}
			}),
			1
		);
		setNewGroup([...newGroup]);
		setAvailableItems([dataItem, ...availableItems]);
	};

	const newSelectionColumns = [
		...props.columns,
		{
			name: "action",
			label: "Remove From Region",
			options: {
				customBodyRender: (value: string, tableMeta: TableMeta, updateVlue: (value: string) => void) => {
					return (
						<IconButton color="secondary" onClick={() => handleRemoveFromRegion(tableMeta)}>
							<ArrowForward />
						</IconButton>
					);
				},
			},
		},
	];
	const availableOptionsColumns = [
		{
			name: "action",
			label: "Add to Region",
			options: {
				customBodyRender: (value: string, tableMeta: TableMeta, updateVlue: (value: string) => void) => {
					return (
						<IconButton color="primary" onClick={() => handleAddToRegion(tableMeta)}>
							<ArrowBack />
						</IconButton>
					);
				},
			},
		},
		...props.columns,
	];

	React.useEffect(() => {
		if (props.availableOptionsData.length > 0) {
			setAvailableItems(props.availableOptionsData);
		}
	}, [props.availableOptionsData]);

	return (
		<div>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<MUIDataTable
						title={props.newSelectionTitle}
						data={newGroup}
						columns={newSelectionColumns}
						options={{
							selectableRows: "none",
							search: false,
							responsive: "simple",
							filter: false,
							download: true,
							print: false,
							viewColumns: false,
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<MUIDataTable
						title={props.availableSelectionTitle}
						data={availableItems}
						columns={availableOptionsColumns}
						options={{
							selectableRows: "none",
							search: false,
							responsive: "simple",
							filter: false,
							download: false,
							print: false,
							viewColumns: false,
						}}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
			<Grid item xs={2}>
					<Button
						variant="outlined"
						onClick={() => {
							props.onCancelSelection();
						}}
					>
						Cancel
					</Button>
				</Grid>
				<Grid item xs={8}></Grid>
				<Grid item xs={2}>
					<Button
						variant="contained"
						onClick={() => {
							props.onSelectionComplete(newGroup);
						}}
					>
						Complete Selection
					</Button>
				</Grid>
			</Grid>
		</div>
	);
}

interface TableMeta {
	rowIndex: number;
	columnIndex: number;
	columnData: Array<any>; // Columns Options object
	rowData: Array<any>; // Full row data
	tableData: Array<any>; // Full table data - Please use currentTableData instead
	currentTableData: Array<any>; // The current table data
	tableState: {
		announceText: null | string;
		page: number;
		rowsPerPage: number;
		filterList: Array<any>;
		selectedRows: {
			data: Array<any>;
			lookup: object;
		};
		showResponsive: boolean;
		searchText: null | string;
	};
}
