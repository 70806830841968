import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import HeatChart from './heatChart';
import InventoryList from './inventoryList'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    scrollBar: {
        height: '100%'
    }
}));

const HeatChartSection = (props: any) => {
    const classes = useStyles();
    const [selectedState, setSelectedState] = React.useState<string>('')

    return (
      <>
        {props.expiryGraphData.length > 0 && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <HeatChart
                searchPayload={props.searchPayload}
                selectedState={selectedState}
                setSelectedState={setSelectedState}
                mapData={props.expiryGraphData}
                graphTitle={""}
              />
            </Grid>
            <Grid item xs={12}>
              <InventoryList />
            </Grid>
          </Grid>
        )}
      </>
    );
}
const mapStateToProps = (state: any) => (
    {
        expiryDatalist: (state.productExpiration && state.productExpiration.expiryDatalist) ? state.productExpiration.expiryDatalist : [],
        expiryGraphData: (state.productExpiration && state.productExpiration.expiryGraphData) ? state.productExpiration.expiryGraphData : [],
    }
);
export default connect(mapStateToProps)(HeatChartSection);