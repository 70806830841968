export const Constants = {
  DATE_FORMAT: "MM/DD/YYYY",
  DATE_TIME_FORMAT: "MM/DD/YYYY HH:mm:ss",
  PAGE_TITLE: {
    DASHBOARD: "Dashboard",
    INVENTORY: "Inventory",
    WAREHOUSE_INVENTORY: "Inventory",
    WAREHOUSE_INVENTORY_LOCATOR: "Inventory Locator",
    REPORTS: "Reports",
    REGION_GRAPH: "Inventory Locator",
    WAREHOUSE_REGION_GRAPH: "Inventory Locator",
    SALES_INVENTORY: "Sales & Inventory",
    DESTRIBUTION_CENTERS: "Destribution Centers",
    PRODUCT_EXPIRATION: "Product Expiration",
    SUPPLIERS: "Suppliers",
    REGIONS: "Regions",
    STATES: "States",
    USERS: "Users",
    SAVE_SEARCH: "Save Search list",
    TRANSACTIONS: "Usage Report",
    SCHEDULED_REPORTS: "Scheduled Reports",
    PROVIDER_PRODUCT_CONFIG: "Provider Product Config",
  },
  FILTERS: {
    SUPPLIER_NAME: "Supplier Name",
    INVENTORY_TYPE: "WHS & Consignment",
    PRODUCT_NAME: "Product Name",
    LOT_NAME: "Lot Name",
    DATE_RANGE: "Expiry Date",
    LOCATION: "City or Postcode",
    INVENTORY_MEASURE: "Inventory Measure",
    FIRST_NAME: "First name",
    LAST_NAME: "Last name",
    EMAIL: "Email",
    CUSTOMER_NAME: "Customer Name",
  },
  DASHBOARD: {
    TOTAL_SALE: "Total Sale",
    TOTAL_INVENTORY: "Total Inventory",
    PRODUCTS: "Products",
    SALES_TRENDS: "Sales Trends",
    TOP_PRODUCT: "Top Product",
  },
  ALERT: {
    NO_RECORD_FOUND: "Sorry, no matching records found.",
    NOT_AUTHORIZED: "You are not authorized to view this content.",
    SERVER_ERROR: "A server error has occurred.",
    REGIONS_CSV_ERROR:
      "There was an error uploading region data. Your CSV formatting/data may be invalid.",
    DELAY: 6000,
  },
  SAVE_SEARCH: {
    PAGE_PREFIX: "SUP:",
  },
  SEARCH: {
    CUSTOMER_NAME: "Supplier Name",
    EXPIRY_DATE: "Expiry Date",
  },
};

export const List = {
    InventoryFilters: [
        { "label": Constants.FILTERS.PRODUCT_NAME, "value": "PRODUCT_NAME" },
        { "label": Constants.FILTERS.LOT_NAME, "value": "LOT_NUMBER" },
        { "label": Constants.FILTERS.DATE_RANGE, "value": "DATE_RANGE" },
        { "label": Constants.FILTERS.LOCATION, "value": "LOCATION" },
    ],


    DashboardFilters: [
        //{ "label": Constants.FILTERS.INVENTORY_MEASURE, "value": "INVENTORY_MEASURE" },
        { "label": Constants.FILTERS.INVENTORY_TYPE, "value": "INVENTORY_TYPE" },
    ],

    InventoryType: [
        { "label": 'All', "value": null },
        { "label": 'Consignment', "value": "2" },
        { "label": 'Non-Consignment', "value": "1" }
    ],
    InventoryMeasure: [
        { "label": 'Dollar', "value": "1" },
        { "label": 'Quantity', "value": "2" }
    ],
    PageLimitOptions: [10, 20, 30, 50, 100],
    UserSearchFilters: [
        { "label": Constants.FILTERS.FIRST_NAME, "value": "FIRST_NAME" },
        { "label": Constants.FILTERS.LAST_NAME, "value": "LAST_NAME" },
        { "label": Constants.FILTERS.EMAIL, "value": "EMAIL" },
    ],
    ScheduleReportFrequency: [
        'NEVER_SCHEDULE',
        'DAILY_SUP_SAVE_SEARCH_REPORT',
        'WEEKLY_SUP_SAVE_SEARCH_REPORT',
        'MONTHLY_SUP_SAVE_SEARCH_REPORT',
    ],
    TransactionFilters: [
        { "label": 'Product', "value": "PRODUCT" },
        { "label": 'Customer', "value": "CUSTOMER" },
        { "label": 'Provider Id', "value": "PROVIDER_ID" },
    ]

};


