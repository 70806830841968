import createReducer from './createReducer';
import { DashboardActions } from '../actions'
import { DASHBOARD } from '../constants/actions';


export const dashboard = createReducer({}, {

  [DASHBOARD.SUCCESS_INVENTORY_REGION_DATA](state = {}, action: ReturnType<typeof DashboardActions.successInventoryRegionData>) {
    return { ...state, inventoryRegionData: action.payload }
  },

  [DASHBOARD.SUCCESS_INVENTORY_ROLL_UP](state = {}, action: ReturnType<typeof DashboardActions.successInventoryRollUp>) {
    return { ...state, inventoryRollUpData: action.payload }
  },

  [DASHBOARD.SHOW_INVENTORY_ROLL_UP_LOADER](state = {}, action: ReturnType<typeof DashboardActions.showInventoryRollUpLoader>) {
    return {...state, inventoryRoolUpLoader : action.payload }
  },

  [DASHBOARD.SUCCESS_STATE_INVENTORY](state = {}, action: ReturnType<typeof DashboardActions.successStateInventory>) {
    return { ...state, stateInventoryData: action.payload }
  },

  [DASHBOARD.SHOW_STATE_INVENTORY_LOADER](state = {}, action: ReturnType<typeof DashboardActions.showInventoryRollUpLoader>) {
    return {...state, stateInventoryLoader : action.payload }
  },

  [DASHBOARD.SHOW_CUSTOMER_CABINET_COUNTS_LOADER](state = {}, action: ReturnType<typeof DashboardActions.showCustomerCabinetCountsLoader>) {
    return {...state, customerCabinetCountsLoader : action.payload }
  },

  [DASHBOARD.SUCCESS_INVENTORY_REVENUE](state = {}, action: ReturnType<typeof DashboardActions.successInventoryRevenue>) {
    return {...state, inventoryRevenueData : action.payload }
  },

  [DASHBOARD.SHOW_REVENUE_LOADER](state = {}, action: ReturnType<typeof DashboardActions.showInventoryRevenueLoader>) {
    return {...state, inventoryRevenueLoader : action.payload }
  },

  [DASHBOARD.SUCCESS_COMPARISION_FOR_SALE](state = {}, action: ReturnType<typeof DashboardActions.successComparisionForSale>) {
    return {...state, comparisionForSaleData : action.payload }
  },


  [DASHBOARD.SHOW_COMPARISION_FOR_SALE_LOADER](state = {}, action: ReturnType<typeof DashboardActions.showComparisionForSaleLoader>) {
    return {...state, comparisionForSaleLoader : action.payload }
  },

  [DASHBOARD.SUCCESS_REGION_INVENTORY](state = {}, action: ReturnType<typeof DashboardActions.successRegionInventory>) {
    return {...state, regionInventory : action.payload }
  },

  [DASHBOARD.SET_CUSTOMER_CABINET_COUNTS](state = {}, action: ReturnType<typeof DashboardActions.setCustomerCabinetCounts>) {
    return {...state, customerCabinetCounts : action.payload }
  },

  [DASHBOARD.SHOW_REGION_INVENTORY_LOADER](state = {}, action: ReturnType<typeof DashboardActions.showRegionInventoryLoader>) {
    return {...state, showRegionInventoryLoader : action.payload }
  },
  
  [DASHBOARD.SET_IS_LOADING](state = {}, action: ReturnType<typeof DashboardActions.setDashboardLoading>) {
    return {...state, isDashboardLoading : action.payload }
  },

  [DASHBOARD.SET_IS_PRODUCTS_LOADING](state = {}, action: ReturnType<typeof DashboardActions.setIsProductsLoading>) {
    return {...state, isProductListLoading : action.payload }
  },

  [DASHBOARD.CLEAR_DASHBOARD](state = {}, action: ReturnType<typeof DashboardActions.clearDashboard>) {
    return { ...state, 
      dashboardDataItems: [], 
      inventoryRollUpData: [], 
      inventoryRegionData: [], 
      stateInventoryData: {}, 
      inventoryRevenueData: [], 
      comparisionForSaleData: {},
      isDashboardLoading: false,
      isProductListLoading: false
    }
  },

});