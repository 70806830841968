import React from "react";
import { connect } from "react-redux";
import { Supplier } from '../../../components/autoComplete'
import { UIAction } from "../../../redux/actions";
import { useParams } from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles, FormControl } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  changeCustomerIcon: {
    fontSize: '1.2em',
    cursor: 'pointer',
    verticalAlign: 'middle',
    '&:hover': {
      color: '#aaa'
    }
  },
  cancelChangeCustomer: {
    fontSize: '1.4em',
    cursor: 'pointer',
    color: '#aaa',
    '&:hover': {
      color: 'white'
    }
  }
}))


interface SupplierSelectorProps {
  selectedCustomer: any;
  suppliersList: any;
  selectedSupplier: any;
  setSelectedSupplier: (supplier: any) => void;
  authUser: any;
}

const SupplierSelector: React.FC<any> = (props: SupplierSelectorProps) => {
  const [displaySelector, setDisplaySelector] = React.useState(props.selectedCustomer ? false : true);
  const { supplierId }: any = useParams();
  const isFirstRender = React.useRef(true);

  React.useEffect(() => {
    if (isFirstRender.current) {
      if (supplierId && props.suppliersList.length > 0) {
        onChangeSupplier(props.suppliersList.find((supplier: any) => supplier.supplierCode === supplierId ? supplier : null));
        isFirstRender.current = false;
        return;
      }
      isFirstRender.current = false;
    }
  }, [props.suppliersList])

  React.useEffect(() => {
    if (props.selectedSupplier) {
      setCustomerInUrl(props.selectedSupplier);
      setDisplaySelector(false);
    }
    if (!props.selectedSupplier) {
      setDisplaySelector(true);
    }
  }, [props.selectedSupplier])

  const onChangeSupplier = (supplier: any) => {
    if (!supplier) {
      return;
    }
    props.setSelectedSupplier(supplier);
    setCustomerInUrl(supplier);
    if (supplier) {
      setDisplaySelector(false);
    }
  }

  const setCustomerInUrl = (supplier: any) => {
    const page = window.location.pathname.split('/')[1];
    if (page !== 'save-search' && page !== 'scheduled-reports') {
      const queryString = window.location.search ? `?${window.location.search.replace('?', '')}` : '';
      window.history.pushState({}, '', `/${page}/${supplier.supplierCode}${queryString}`)
    }
  }

  const onClickEdit = () => {
    setDisplaySelector(true);
  }

  const onClickCancel = () => {
    setDisplaySelector(false);
  }

  const displayOnPage = () => {
    /*if (props.navState && props.navState.title === 'Products') {
      return false; 
    }*/
    return true;
  }

  const classes = styles();
  return (
    <div className={'supplierSelectorBox'} style={{ width: '25%', textAlign: 'right', marginRight: '10px' }}>
      {/*props.authUser && displayOnPage() ?
        <div>
          {!displaySelector &&
            <h5>
              {props.selectedSupplier?.supplierName}&nbsp;
              <EditIcon className={classes.changeCustomerIcon} onClick={onClickEdit} />
            </h5>}

          <div style={{ display: displaySelector ? 'block' : 'none' }}>
            <FormControl style={{ width: '80%' }}>
              <Supplier style={{ width: '200px' }} value={props.selectedSupplier} onChangeSupplier={onChangeSupplier} />
            </FormControl>
            <FormControl style={{ width: '20%', padding: '20px 0 0 10px' }}>
              {props.selectedCustomer && <CancelIcon className={classes.cancelChangeCustomer} onClick={onClickCancel} />}
            </FormControl>
          </div>

        </div>
        : <div />
      */}

    </div>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  setSelectedSupplier: (data: any) => dispatch(UIAction.setSelectedSupplier(data)),
  //resetTenants: () => dispatch(TenantsActions.clearTenantsList(true)),
})
const mapStateToProps = (state: any) => ({
  navState: state.ui.menuState,
  authUser: state.user.authUser,
  selectedSupplier: state.ui.selectedSupplier ? state.ui.selectedSupplier : null,
  suppliersList:
    state.supplier.suppliersList && state.supplier.suppliersList.result
      ? state.supplier.suppliersList.result
      : [],
})

export default connect(mapStateToProps, mapDispatchToProps)(SupplierSelector);