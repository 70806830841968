import { call, put, delay } from 'redux-saga/effects';
import { ReportActions, UIAction } from '../actions/index';
import { ReportService } from '../services';
import { Constants } from "../../constants/Constants";
import { AuthCheck } from "../lib/authCheck";

export function* getRegionGraphData(action: ReturnType<typeof ReportActions.getRegionGraphData>) {
    try {
        yield put(UIAction.setApiError(null));
        yield put(UIAction.showLoader(true));
        const tenantId = JSON.parse(action.payload[0].value).where.supplierCode
        yield call(AuthCheck.getInstance().check,{ action: 'read', resource: 'inventoryLocator', tenantId: tenantId });
        const { data } = yield call(ReportService.getInstance().getRegionGraph, action.payload);
        let regionGraphData: any = [];
        if (data) {
            data.forEach((item: any) => {
                let graphDataObj: any = {};
                if (item.latitude && item.onHandQty) {
                    for (let i = 0; i < item.onHandQty; i++) {
                        graphDataObj = {
                            "name": item.state,
                            "city": item.city,
                            "lat": item.latitude,
                            "lon": item.longitude,
                            "zipcode": item.postcode,
                        };
                        regionGraphData.push(graphDataObj);
                    }
                }
            });
        }
        yield put(ReportActions.regionGraphDataSuccess(regionGraphData));
        yield put(UIAction.showLoader(false));
    } catch (err: any) {
        const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
        yield put(UIAction.setApiError({ errorMessage: errorMessage, severity: 'error', title: "Error" }));
        yield put(UIAction.showLoader(false));
    }
}


export function* getDrawerData(action: ReturnType<typeof ReportActions.getDrawerData>) {
    try {
        yield put(ReportActions.successDrawerData([] as any));
        yield put(UIAction.showMiniLoader(true));
        yield put(UIAction.setApiError(null));
        const tenantId = action.payload[0].value ? JSON.parse(action.payload[0].value).where.supplierCode : ''
        yield call(AuthCheck.getInstance().check,{ action: 'read', resource: 'inventoryLocator', tenantId: tenantId });
        const { data } = yield call(ReportService.getInstance().getInventory, action.payload);
        yield put(ReportActions.successDrawerData(data));
        yield put(UIAction.showLoader(false));
        yield put(UIAction.showMiniLoader(false));
        /*if (!data || !data.result || data.result.length === 0) {
            yield put(UIAction.setApiError({ errorMessage: Constants.ALERT.NO_RECORD_FOUND, severity: 'info', title: "Info" }));
        }*/
    } catch (err: any) {
        yield put(UIAction.showMiniLoader(false));
        yield put(UIAction.showLoader(false));
        const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
        yield put(UIAction.setApiError({ errorMessage: errorMessage, severity: 'error', title: "Error" }));
        yield delay(Constants.ALERT.DELAY);
        yield put(UIAction.setApiError(null));
    }
}


export function* getWareHouseData(action: ReturnType<typeof ReportActions.getWareHouseData>) {
    try {
        yield put(UIAction.setApiError(null));
        yield put(UIAction.showLoader(true));
        yield call(AuthCheck.getInstance().check,{ action: 'read', resource: 'inventoryLocator', tenantId: action.payload.supplierId });
        const { data } = yield call(ReportService.getInstance().getWareHouseData, action.payload);

        let regionGraphData: any = [];
        if (data && data.length > 0) {
            data.forEach((item: any) => {
                let graphDataObj: any = {};
                if (item.latitude && item.onHandQty) {
                    const latitude:number = Number(item.latitude);
                    const longitude:number = Number(item.longitude);
                    graphDataObj = {
                        "id": item.id,
                        "name": item.state,
                        "city": item.city,
                        "lat": parseFloat(latitude.toFixed(2)),
                        "lon": parseFloat(longitude.toFixed(2)),
                        "zipcode": item.postcode,
                        "value": item.onHandQty,
                    };
                    regionGraphData.push(graphDataObj);
                }
            });
        }
        /*else  {
            yield put(UIAction.setApiError({ errorMessage: Constants.ALERT.NO_RECORD_FOUND, severity: 'info', title: "Info" }));
        }*/
        yield put(ReportActions.successWareHouseData(regionGraphData));
        yield put(UIAction.showLoader(false));

    } catch (err: any) {
        yield put(UIAction.showLoader(false));
        const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
        yield put(UIAction.setApiError({ errorMessage: errorMessage, severity: 'error', title: "Error" }));
        yield delay(Constants.ALERT.DELAY);
    }
}




export function* getWareHouseDrawerData(action: ReturnType<typeof ReportActions.getWareHouseDrawerData>) {
    try {
        yield put(ReportActions.successWareHouseDrawerData([] as any));
        yield put(UIAction.setApiError(null));
        yield put(UIAction.showMiniLoader(true));
        yield call(AuthCheck.getInstance().check,{ action: 'read', resource: 'inventoryLocator', tenantId: action.payload.supplierId });
        const { data } = yield call(ReportService.getInstance().getWareHouseDrawerData, action.payload);
        yield put(ReportActions.successWareHouseDrawerData(data));
        yield put(UIAction.showMiniLoader(false));
    } catch (err: any) {
        yield put(UIAction.showMiniLoader(false));
        const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
        yield put(UIAction.setApiError({ errorMessage: errorMessage, severity: 'error', title: "Error" }));
        yield delay(Constants.ALERT.DELAY);
    }
}

