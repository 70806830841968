import createReducer from './createReducer';
import { SaveSearchActions } from '../actions'
import { SAVE_SEARCH_ACTIONS } from '../constants/actions';


export const saveSearch = createReducer({}, {

  [SAVE_SEARCH_ACTIONS.CREATE.ERROR_CREATE_SEARCH](state = {}, action: ReturnType<typeof SaveSearchActions.saveSearchError>) {
    return { ...state, error: action.payload }
  },
  [SAVE_SEARCH_ACTIONS.LIST.RESPONSE_LIST_SEARCH](state = {}, action: ReturnType<typeof SaveSearchActions.setAdvanceSearch>) {
    return { ...state, searchList: action.payload }
  },
 
});