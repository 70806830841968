import React from 'react';
import {
  Button,
  Dialog,
  FormGroup,
  FormControl
} from '@material-ui/core';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { CommonStyles } from '../../../hooks/styles';
import { DialogTitle, DialogContent, DialogActions } from './dialogProps';
import ClearIcon from "@material-ui/icons/Clear";
import { SaveFilterProps, SaveSearchPayloadType, SaveSearchPayload } from './models';
import { SaveSearchActions } from '../../../redux/actions';
import { useStyles } from '../styles';
import {Constants} from '../../../constants/Constants';
const SaveFilter = (props: SaveFilterProps) => {

  const [open, setOpen] = React.useState(false);
  const classes = CommonStyles();
  const searchClasses = useStyles();
  const [filterNameRequired, setFilterNameRequired] = React.useState<boolean>(false);
  let [filterName, setFilterName] = React.useState<string>('');
  const [showError, setShowError] = React.useState(false);
  React.useEffect(() => {
    setOpen(props.open);
    setFilterName((props.title) ? props.title : '')

  }, [props.open]);

  const handleClose = () => {
    props.handleClose();
  };

  const handleSave = () => {
    setFilterNameRequired(false);
    if (!filterName) {
      setFilterNameRequired(true);
      return;
    }
    let paylaod: SaveSearchPayloadType = new SaveSearchPayload();
    paylaod.title = filterName;
    paylaod.userEmail = props.authUser?.record?.email;
    paylaod.uuid = (props.uuid) ? props.uuid : undefined;
    
    if (!props.uuid) {
      paylaod.data = JSON.stringify(props.filters);
      paylaod.pageName = `${Constants.SAVE_SEARCH.PAGE_PREFIX}${props.pageName}`;
    }
    props.saveSearch(paylaod);
    setShowError(true);
  }

  React.useEffect(() => {
    if (props.error && props.error.severity != 'error') {
      setShowError(true);
      props.handleClose();
    }
  }, [props.error]);

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Save Search
        </DialogTitle>
        <DialogContent dividers style={{ width: '500px', height: '210px', fontSize: 12 }}>
          <FormGroup row style={{ marginBottom: 10 }}>
            <FormControl className={classes.formControl} style={{ width: '100%', margin: '0 auto' }}>
              <TextField disabled id="pageName" label="Page Name" defaultValue={props.pageName} />
            </FormControl>
          </FormGroup>

          <FormGroup row style={{ marginBottom: 10 }}>
            <FormControl className={classes.formControl} style={{ width: '100%', margin: '0 auto' }}>
              <TextField disabled id="email" label="Email" defaultValue={props.authUser?.record?.email} />
            </FormControl>
          </FormGroup>

          <FormGroup row style={{ marginBottom: 10 }}>
            <FormControl className={classes.formControl} style={{ width: '100%', margin: '0 auto' }}>
              <TextField
                error={filterNameRequired}
                helperText={(filterNameRequired) && 'Title is required'}
                InputProps={{
                  value: filterName,
                  endAdornment: (
                    (filterName) && <ClearIcon style={{ cursor: 'pointer' }} onClick={() => {
                      setFilterName('')
                      setShowError(false);
                    }} />
                  )
                }}
                autoComplete='off'
                id="outlined-basic"
                label={'Title'}
                onChange={({ target }) => {
                  setFilterName(target.value);
                  setFilterNameRequired(false);
                  setShowError(false);
                }} />
            </FormControl>
          </FormGroup>
          {(props.error && props.error.severity == 'error' && showError) && <p className={searchClasses.saveSearchError}>{props.error.message}</p>}
        </DialogContent>
        <DialogActions style={{ padding: 20 }}>
          <Button
            onClick={handleSave}
            type="button"
            className={classes.viewAllBtn}
            variant="contained"
            color="primary"
          >Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state: any) => (
  {
    authUser: state.user.authUser,
    error: (state.saveSearch && state.saveSearch.error) ? state.saveSearch.error : null
  }

);

const mapDispatchToProps = (dispatch: any) => ({
  saveSearch: (paylaod: SaveSearchPayloadType) => dispatch(SaveSearchActions.saveAdvanceSearch(paylaod)),
  getAdvanceSearch: (filter: any) => dispatch(SaveSearchActions.getAdvanceSearch(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveFilter);