import { connect } from 'react-redux';
import { UsersComponent } from './users.component';
import { UserManagementActions, UIAction } from '../../redux/actions';

const mapStateToProps = (state: any) => ({
  authUser: state.user.authUser,
  usersList: (state.userManagement && state.userManagement.userList && state.userManagement.userList.result) ? state.userManagement.userList.result : [],
  isLoading: state.ui.isLoading,
  selectedSupplier: state.ui.selectedSupplier ? state.ui.selectedSupplier : null,
  suppliersList:
    state.supplier.suppliersList && state.supplier.suppliersList.result
      ? state.supplier.suppliersList.result
      : [],
  totalUsers: (state.userManagement && state.userManagement.userList && state.userManagement.userList.total_record) ? state.userManagement.userList.total_record : 0,
  selectedFilterValues: state.ui.selectedFilterValues || {}
});

const mapDispatchToProps = (dispatch: any) => ({
  getUsersList: (payload: any | null) => dispatch(UserManagementActions.getUserListStartAction(payload)),
  setSelectedSupplier: (data: any) => dispatch(UIAction.setSelectedSupplier(data)),
  resetAllFilters: () => dispatch(UIAction.resetAllFilters())
});

const UsersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersComponent);

export default UsersContainer;