import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { CommonStyles } from '../../hooks/styles'
import Alert from '@material-ui/lab/Alert';
interface AlertProps {
    open: boolean;
    handleClose: () => void;
    content: string;
    title: string;
}

export default function AlertDialog(props: AlertProps) {
    const [open, setOpen] = React.useState(false);
    const classes = CommonStyles();
    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const handleClose = () => {
        props.handleClose();

    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title" style={{ background: '#f2f2f2' }}>{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Alert variant="outlined" severity="warning">
                            {props.content}
                        </Alert>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ padding: 20 }}>
                    <Button className={`${classes.centerButton} ${classes.defaultButton}`} onClick={handleClose} variant="contained" size="small" color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}