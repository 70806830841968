import { call, put } from 'redux-saga/effects';
import { DashboardActions, UIAction } from '../actions/index';
import { DashboardService } from '../services';
import { AuthCheck } from "../lib/authCheck";
import { Constants } from "../../constants/Constants";
const empty: any = [];

export function* getAll(action: ReturnType<typeof DashboardActions.getAllDashboardData>): any {
    try {
        yield put(UIAction.showLoader(true));
        yield put(UIAction.setApiError(null));
        yield put(DashboardActions.showInventoryRollUpLoader(true));
        yield put(DashboardActions.showStateInventoryLoader(true));
        yield put(DashboardActions.showInventoryRevenueLoader(true));
        yield put(DashboardActions.showComparisionForSaleLoader(true));
        yield put(DashboardActions.showCustomerCabinetCountsLoader(true));
        yield put(DashboardActions.setDashboardLoading(true));
//        yield put(DashboardActions.setProductLoading(true));
        // yield put(DashboardActions.showRegionInventoryLoader(true));
        let dashboardDataError: string | null = null
        delete action.payload.valueType;
        delete action.payload.consignmentType; //???

        try {
            // Load Inventory Rollup
            yield call(AuthCheck.getInstance().check, { action: 'read', resource: 'dashboard', tenantId: action.payload.supplierId });
            const rollupResult = yield call(DashboardService.getInstance().getInventoryRollUp, action.payload);
            yield put(DashboardActions.successInventoryRollUp(rollupResult.data));
            yield put(DashboardActions.showInventoryRollUpLoader(false));
            yield put(UIAction.showLoader(false));
        } catch (err: any) {
            dashboardDataError = 'Inventory Rollup Error: ' + err.message
            yield put(DashboardActions.showInventoryRollUpLoader(false));
            yield put(UIAction.showLoader(false));
        }

        try {
            // Load Customer Cabinet Counts
            yield call(AuthCheck.getInstance().check, { action: 'read', resource: 'dashboard', tenantId: action.payload.supplierId });
            const customerCabinetCountsResult = yield call(DashboardService.getInstance().getCustomerCabinetCounts, action.payload);
            yield put(DashboardActions.setCustomerCabinetCounts(customerCabinetCountsResult.data));
            yield put(DashboardActions.showCustomerCabinetCountsLoader(false));
            yield put(UIAction.showLoader(false));
        } catch (err: any) {
            dashboardDataError = 'Customer Cabinet Counts Error: ' + err.message
            yield put(DashboardActions.showCustomerCabinetCountsLoader(false));
            yield put(UIAction.showLoader(false));
        }

        try {
            // Load State Inventory
            yield call(AuthCheck.getInstance().check, { action: 'read', resource: 'dashboard', tenantId: action.payload.supplierId });
            const stateInvResult = yield call(DashboardService.getInstance().getStateInventory, action.payload);
            yield put(DashboardActions.successStateInventory(stateInvResult.data));
            yield put(DashboardActions.showStateInventoryLoader(false));
        } catch (err: any) {
            dashboardDataError = 'State Inventory Error: ' + err.message
            yield put(DashboardActions.showStateInventoryLoader(false));
        }

        try {
            // Load Inventory Revenue
            yield call(AuthCheck.getInstance().check, { action: 'read', resource: 'dashboard', tenantId: action.payload.supplierId });
            const invRevenueResult = yield call(DashboardService.getInstance().getInventoryRevenue, action.payload);
            yield put(DashboardActions.successInventoryRevenue(invRevenueResult.data));
            yield put(DashboardActions.showInventoryRevenueLoader(false));
        } catch (err: any) {
            dashboardDataError = 'Inventory Revenue Error: ' + err.message
            yield put(DashboardActions.showInventoryRevenueLoader(false));
        }

        try {
            // Load Sales Comparison
            yield call(AuthCheck.getInstance().check, { action: 'read', resource: 'dashboard', tenantId: action.payload.supplierId });
            const salesComparison = yield call(DashboardService.getInstance().getComparisionForSale, action.payload);
            yield put(DashboardActions.successComparisionForSale(salesComparison.data));
            yield put(DashboardActions.showComparisionForSaleLoader(false));
        } catch (err: any) {
            dashboardDataError = 'Sales Comparison Error: ' + err.message
            yield put(DashboardActions.showComparisionForSaleLoader(false));
        }

        // try {
        //     // Load Region Inventory
        //     yield call(AuthCheck.getInstance().check, { action: 'read', resource: 'dashboard', tenantId: action.payload.supplierId });
        //     const { data } = yield call(DashboardService.getInstance().getRegionInventory, {...action.payload, valueType: 2});
        //     yield put(DashboardActions.successRegionInventory(data));
        //     yield put(DashboardActions.showRegionInventoryLoader(false));
        // } catch (err: any) {
        //     dashboardDataError = 'Region Inventory Error: ' + err.message
        //     yield put(DashboardActions.showRegionInventoryLoader(false));
        // }

        if (dashboardDataError) {
            throw new Error(dashboardDataError)
        }
        else {
            yield put(DashboardActions.setDashboardLoading(false));
             //yield put(DashboardActions.setProductLoading(false));
        }

    } catch (err: any) {
        const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
        yield put(UIAction.setApiError({ errorMessage: errorMessage, severity: 'error', title: "Error" }));
    }
}

export function* getInventoryRegionData(action: ReturnType<typeof DashboardActions.getInventoryRegionData>) {
    try {
        yield put(UIAction.setApiError(null));
        yield put(DashboardActions.showInventoryRegionLoader(true));
        yield call(AuthCheck.getInstance().check, { action: 'read', resource: 'dashboard', tenantId: JSON.parse(action.payload).where.supplierCode });
        const { data } = yield call(DashboardService.getInstance().getInventoryRegionData, action.payload);
        yield put(DashboardActions.successInventoryRegionData(data));
        yield put(DashboardActions.showInventoryRegionLoader(true));
    } catch (err: any) {
        yield put(DashboardActions.successInventoryRegionData(empty));
        const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
        yield put(UIAction.setApiError({ errorMessage: errorMessage, severity: 'error', title: "Error" }));
        yield put(DashboardActions.showInventoryRegionLoader(true));
    }
}


export function* getInventoryRollUp(action: ReturnType<typeof DashboardActions.getInventoryRollUp>) {
    try {
        yield put(UIAction.setApiError(null));
        yield put(DashboardActions.showInventoryRollUpLoader(true));
        yield call(AuthCheck.getInstance().check, { action: 'read', resource: 'dashboard', tenantId: action.payload.supplierId });

        const { data } = yield call(DashboardService.getInstance().getInventoryRollUp, action.payload);
        yield put(DashboardActions.successInventoryRollUp(data));
        yield put(DashboardActions.showInventoryRollUpLoader(false));

    } catch (err: any) {


        yield put(DashboardActions.successInventoryRollUp(empty));
        const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
        yield put(UIAction.setApiError({ errorMessage: errorMessage, severity: 'error', title: "Error" }));
        yield put(DashboardActions.showInventoryRollUpLoader(false));
    }
}

export function* getStateInventory(action: ReturnType<typeof DashboardActions.getStateInventory>) {
    try {
        yield put(UIAction.setApiError(null));
        yield put(DashboardActions.showStateInventoryLoader(true));
        yield call(AuthCheck.getInstance().check, { action: 'read', resource: 'dashboard', tenantId: action.payload.supplierId });

        const { data } = yield call(DashboardService.getInstance().getStateInventory, action.payload);
        yield put(DashboardActions.successStateInventory(data));
        yield put(DashboardActions.showStateInventoryLoader(false));
    } catch (err: any) {
        yield put(DashboardActions.showStateInventoryLoader(false));
        yield put(DashboardActions.successStateInventory(empty));
        const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
        yield put(UIAction.setApiError({ errorMessage: errorMessage, severity: 'error', title: "Error" }));
    }
}

export function* getInventoryRevenue(action: ReturnType<typeof DashboardActions.getInventoryRevenue>) {
    try {
        yield put(UIAction.setApiError(null));
        yield put(DashboardActions.showInventoryRevenueLoader(true));
        yield call(AuthCheck.getInstance().check, { action: 'read', resource: 'dashboard', tenantId: action.payload.supplierId });

        const { data } = yield call(DashboardService.getInstance().getInventoryRevenue, action.payload);
        yield put(DashboardActions.successInventoryRevenue(data));
        yield put(DashboardActions.showInventoryRevenueLoader(false));
    } catch (err: any) {
        yield put(DashboardActions.showInventoryRevenueLoader(false));
        yield put(DashboardActions.successInventoryRevenue(empty));
        const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
        yield put(UIAction.setApiError({ errorMessage: errorMessage, severity: 'error', title: "Error" }));
    }
}


export function* getComparisionForSale(action: ReturnType<typeof DashboardActions.getComparisionForSale>) {
    try {
        yield put(UIAction.setApiError(null));
        yield put(DashboardActions.showComparisionForSaleLoader(true));
        yield call(AuthCheck.getInstance().check, { action: 'read', resource: 'dashboard', tenantId: action.payload.supplierId });

        const { data } = yield call(DashboardService.getInstance().getComparisionForSale, action.payload);
        yield put(DashboardActions.successComparisionForSale(data));
        yield put(DashboardActions.showComparisionForSaleLoader(false));
    } catch (err: any) {
        yield put(DashboardActions.showComparisionForSaleLoader(false));
        yield put(DashboardActions.successComparisionForSale(empty));
        const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
        yield put(UIAction.setApiError({ errorMessage: errorMessage, severity: 'error', title: "Error" }));
    }
}


export function* getRegionInventory(action: ReturnType<typeof DashboardActions.getRegionInventory>) {
    try {
        yield put(UIAction.setApiError(null));
        yield put(DashboardActions.showRegionInventoryLoader(true));
        yield call(AuthCheck.getInstance().check, { action: 'read', resource: 'dashboard', tenantId: action.payload.supplierId });
        const { data } = yield call(DashboardService.getInstance().getRegionInventory, action.payload);
        yield put(DashboardActions.successRegionInventory(data));
        yield put(DashboardActions.showRegionInventoryLoader(false));
    } catch (err: any) {
        yield put(DashboardActions.showRegionInventoryLoader(false));
        yield put(DashboardActions.successRegionInventory(empty));
        const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
        yield put(UIAction.setApiError({ errorMessage: errorMessage, severity: 'error', title: "Error" }));
    }
}







