import createReducer from './createReducer';
import { LocationActions } from '../actions'
import { LOCATION } from '../constants/actions';


export const location = createReducer({}, {

  [LOCATION.LOCATION_LIST_SUCCESS](state = {}, action: ReturnType<typeof LocationActions.locationListSuccess>) {
    return { ...state, list: action.payload }
  },

 




});