import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
export const UsersStyles = makeStyles((theme) => ({
  customerSearchWrap: {
    'margin-bottom': '2em',
    padding: 9,
    background: '#fff',
  },
  viewAllBtn: {
    margin: '12px 0 0 10px',
    backgroundColor: '#558db1 !important',
    fontSize:'10px',
  },
  chip: {
    margin: '3px'
  },
  addMoreButton: {
    color: '#558db1!important',
    '&:hover': {
        background: 'none!important'
    },
    width:'40px!important',
    fontSize:'10px!important',
    marginTop:'10px!important'
},
formControl: {
  marginBottom: '12px!important',
  marginLeft: 0,
  marginRight: '20px!important',
},
}))

export const UsersTableTheme = (createMuiTheme as any)({
  overrides: {
    MuiTableCell: {
      root: {
        cursor: 'pointer'
      }
    },
    MuiTableSortLabel: {
      root: {
        'margin-top': '7px'
      }
    },
    MUIDataTableHeadCell: {
      root: {
        fontSize:12
      }
    },
    MUIDataTableBodyCell: { 
      root: {
        fontSize:12
      }
    },
    MuiTypography:{
      h6:{
        fontSize:14
      }
    },
    MUIDataTableFilterList: {
      chip: {
        display: 'none'
      }
    }
  }
});