import React from 'react';
import { connect } from 'react-redux';
import { Container } from '@material-ui/core';
import { DashboardStyles } from '../../hooks/styles'
import BasePage from '../common/base'
import InventoryRollUp from './inventoryRollup';
import HeatChartSection from './heatChartSection';
import OnHandPieChartSection from './onHandPieChartSection';
import EmptyContent from '../../components/emptyContent';
import GlobalFiltersComponentContainer from '../../components/globalFiltersComponent';
import { UIAction, DashboardActions, ProductActions } from '../../redux/actions';
import Loading from '../../components/loading';

const Dashboard = (props: any) => {

  const classes = DashboardStyles();
  const [selectedInventoryMeasure, setSelectedInventoryMeasure] = React.useState(2 as any);
  const [selectedInventoryType, setSelectedInventoryType] = React.useState(null as any); //????
  const [bigQueryFilter, setBigQueryFilter] = React.useState({} as any);

  React.useEffect(() => {
    if (props.suppliersList?.length > 0) {
      if (!props.selectedSupplier) {
        props.setSelectedSupplier(props.suppliersList[0]);
        handleDashboardSearch(props.suppliersList[0]);
      }
    }
  }, [props.suppliersList]);

  const handleDashboardSearch = (supplier?: any) => {
    const supplierToSearch = props.selectedSupplier || supplier || null;
    if (!supplierToSearch) {
      console.error('No supplier');
      return;
    }
    
    const filterValues = props.selectedFilterValues || {};
    const productsArrayForQuery: any[] = filterValues['PRODUCT_NAME'] || [];
    const newBigQueryFilter: any = {};

    newBigQueryFilter.supplierId = (supplierToSearch) ? supplierToSearch.supplierCode : undefined;
    newBigQueryFilter.productGroup = (productsArrayForQuery && productsArrayForQuery.length) ? productsArrayForQuery.map(x => '"' + x.productGroup + '"').toString() : undefined;
    
    newBigQueryFilter.valueType = ((selectedInventoryMeasure)) ? selectedInventoryMeasure : undefined;
    newBigQueryFilter.consignmentType = (selectedInventoryType) ? selectedInventoryType : undefined;

    if(filterValues['REGION']) newBigQueryFilter.regionName = filterValues['REGION']

    setBigQueryFilter(newBigQueryFilter);
    props.getAllDashboardData(newBigQueryFilter);
  }
  
 return (
		<BasePage 
      title="Dashboard" 
      drawerOpen={true}
      pageNameKey="dashboard"
    >
			<Container maxWidth={false} className={classes.container}>
				<div className={classes.appBarSpacer}></div>
				<GlobalFiltersComponentContainer
					pageName="DASHBOARD"
					executeSearch={handleDashboardSearch}
					onReset={() => {
						props.resetAllFilters();
					}}
				/>

				{((props.total && props.total.length > 0) ||
					(props.inventoryRollUpData &&
						props.inventoryRollUpData.length > 0) ||
					(props.totalRegionInventory &&
						props.totalRegionInventory.length > 0) ||
					(props.consignment && props.consignment.length > 0) ||
					(props.revenue && props.revenue.length > 0) ||
					(props.nonConsignment && props.nonConsignment.length > 0) ||
					(props.comparisionForSaleData &&
						props.comparisionForSaleData.length > 0)) &&
				props.selectedSupplier ? (
					<div>
						<InventoryRollUp bigQueryFilter={bigQueryFilter} />
						{props.isDashboardLoading ||
						props.isProductListLoading ? (
							<div>
								<Loading message="Loading supplier data..." />
							</div>
						) : (
							<>
								<HeatChartSection
									bigQueryFilter={bigQueryFilter}
								/>
								<OnHandPieChartSection
									bigQueryFilter={bigQueryFilter}
								/>
							</>
						)}
					</div>
				) : (
					<EmptyContent message="Select the filter criteria above to view dashboard information."></EmptyContent>
				)}
			</Container>
		</BasePage>
 );
}

const mapStateToProps = (state: any) => ({
  total: state.dashboard.dashboardDataItems?.total || [],
  inventoryRollUpData: state.dashboard.inventoryRollUpData || [],
  totalRegionInventory: state.dashboard.inventoryRegionData?.totalRegionInventory || [],
  consignment: state.dashboard.stateInventoryData?.consignment || [],
  revenue: (state.dashboard.inventoryRevenueData) ? state.dashboard.inventoryRevenueData : [],
  nonConsignment: (state.dashboard.stateInventoryData && state.dashboard.stateInventoryData.nonConsignment) ?
    state.dashboard.stateInventoryData.nonConsignment : [],
  comparisionForSaleData: (state.dashboard.comparisionForSaleData && state.dashboard.comparisionForSaleData.totalSaleData) ?
    state.dashboard.comparisionForSaleData.totalSaleData : [],
  supplierCode: state.ui.selectedSupplier?.supplierCode || null,
  selectedSupplier: state.ui.selectedSupplier || null,
  suppliersList: state.supplier?.suppliersList?.result || [],
  productList: state.product.productList || [],
  selectedFilterValues: state.ui.selectedFilterValues || {},
  isDashboardLoading: state.dashboard.isDashboardLoading || false,
  isProductListLoading: state.dashboard.isProductListLoading || false,
});

const mapDispatchToProps = (dispatch: any) => ({
  setSelectedSupplier: (data: any) => dispatch(UIAction.setSelectedSupplier(data)),
  resetAllFilters: () => dispatch(UIAction.resetAllFilters()),
  getAllDashboardData: (filter: any) => dispatch(DashboardActions.getAllDashboardData(filter)),
  getProductList: (supplierId: any) => dispatch(ProductActions.getProductList({
    supplierId: supplierId
  }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);



