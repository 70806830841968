

//import { TenantsFilter, TenantsList} from "../models/tenants/tenants";
import { GET_INVENTORY_LIST, GET_INVENTORY_DETAIL_LIST, GET_WAREHOUSE_GROUP_INVENTORY, GET_EXPORT_INVENTORY, GET_REGION_GROUP_INVENTORY, GET_PAR_DATA } from '../constants/api.url';
import { HttpResponse, ResultSet } from "../models/common/result.set";
import Api from "../lib/api";


export class InventoryService {

  private static instance: InventoryService;
  private constructor() { }

  public static getInstance(): InventoryService {
    if (!InventoryService.instance) {
      InventoryService.instance = new InventoryService();
    }
    return InventoryService.instance;
  }

  public getInventory = async (payload: any | null): Promise<HttpResponse<ResultSet<any>>> => {
    return await Api.get(`${GET_INVENTORY_LIST.URL}`, payload)
  }

  public getInventoryDetail = async (payload: any | null): Promise<HttpResponse<ResultSet<any>>> => {
    return await Api.get(`${GET_INVENTORY_DETAIL_LIST.URL}`, payload)
  }

  public getWareHouseInventory = async (filter: any | null): Promise<HttpResponse<ResultSet<any>>> => {
    const params = [{ key: 'filter', value: filter }];
    return await Api.get(`${GET_WAREHOUSE_GROUP_INVENTORY.URL}`, params)
  }

  public getExportInventory = async (filter: any | null): Promise<any> => {
    const params = [{ key: 'filter', value: filter }];
    const optional: any = { fileName: 'Consignment-Inventory.csv' };

    return await Api.get(`${GET_EXPORT_INVENTORY.URL}`, params, optional)
  }

  public getRegionGroupInventory = async (filter: any | null): Promise<HttpResponse<ResultSet<any>>> => {
    const params = [{ key: 'filter', value: filter }];
    return await Api.get(`${GET_REGION_GROUP_INVENTORY.URL}`, params)
  }

  public fetchParData = async (filter: any | null): Promise<HttpResponse<ResultSet<any>>> => {

    let paramString: string = '';
    Object.keys(filter).forEach(function (key: any, index: number) {
      if (typeof filter[key] !== undefined && typeof filter[key] !== 'undefined') {
        paramString += (index > 0) ? '&' + key + '=' + filter[key] : '?' + key + '=' + filter[key]
      }
    })
    return await Api.get(`${GET_PAR_DATA.URL}${paramString}`, [])
  }

}