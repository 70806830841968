import React from 'react';
import { connect } from 'react-redux';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highmaps";
import { ProductExpirationAction } from '../../redux/actions';
import usAll from "../../static/useAll";
import proj4 from "proj4";
import { makeStyles } from '@material-ui/core/styles';
window.proj4 = window.proj4 || proj4;
window.Highcharts = Highcharts;
const useStyles = makeStyles(theme => ({
    graphTitle: {
        backgroundColor: '#f2f2f2',
        padding:10,
        marginTop:0
    },
}));

const HeatChart = React.memo((props: any) => {
    const classes = useStyles();

    React.useEffect(() => {
        Highcharts.charts.forEach((chart: any) => {
            if (chart) {
                chart.reflow();
                chart.redraw();
            }
        });

    }, [props.mapData, props.keys]);


    const getMapData = () => {
        let index = props.mapData.findIndex((obj: { selected: boolean; }) => obj.selected === true);
        if (index > -1) {
            props.mapData[index].selected = false;
        }
        return props.mapData;
    }

    const chartOption = {
      chart: {
        map: usAll,
        //panning: false,
        //margin: [20, 20, 20, -80],
        events: {},
      },
      title: {
        text: "",
      },
      subtitle: {
        /*text:''*/
      },
      legend: {
        enabled: true,
        layout: "vertical",
        align: "right",
        floating: true,
        x: 10,
        y: 10,
        backgroundColor:
          (Highcharts.defaultOptions &&
            Highcharts.defaultOptions.legend &&
            Highcharts.defaultOptions.legend.backgroundColor) ||
          "rgba(255, 255, 255, 0.85)",
      },
      mapNavigation: {
        enableMouseWheelZoom: false,
        enableDoubleClickZoomTo: false,
        enabled: false,
        buttonOptions: {
          verticalAlign: "bottom",
        },
      },
      colorAxis: {
        maxColor: "#500007",
        minColor: "#b55232",
        labels: {
          format: "{value}",
        },
      },
      tooltip: {},

      plotOptions: {
        series: {
          turboThreshold: 0,
          dataLabels: {
            enabled: false,
          },
          events: {
            click: function (e: any) {
              if (e.point) {
                console.log(e);
                let payload: any = [...props.searchPayload];
                payload.push({ key: "state", value: e.point.stateCode });
                props.getExpiryDatalist(payload);
              }
            },
          },
        },
      },
      series: [
        {
          data: getMapData(),
          joinBy: ["hc-key", "code"],
          name: "Heat Map",
          allowPointSelect: true,
          tooltip: {
            valueSuffix: "",
          },
          borderWidth: 0.5,
          states: {
            hover: {
              color: "#a4edba",
            },
            select: {
              borderColor: "black",
              borderWidth: 5,
              color: "red",
            },
          },
          shadow: false,
        },
      ],
      credits: {
        enabled: false,
      },
    };
    return (
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOption}
          constructorType={"mapChart"}
          containerProps={{ style: { height: "240px", width: "100%" } }}
        />
      </div>
    );
});

const mapDispatchToProps = (dispatch: any) => ({
    getExpiryGraphData: (filter: any) => dispatch(ProductExpirationAction.getExpiryGraphData(filter)),
    getExpiryDatalist: (filter: any) => dispatch(ProductExpirationAction.getExpiryDatalist(filter)),
});
const mapStateToProps = (state: any) => (
    {

    }
);
export default connect(mapStateToProps, mapDispatchToProps)(HeatChart);