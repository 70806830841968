import React from 'react';
import { connect } from 'react-redux';
import { Container } from '@material-ui/core';
import { DashboardStyles } from '../../../hooks/styles'
import BasePage from '../../common/base'
import MarkerGraph from '../regionGraph/graph.marker-clusters';
import { Constants } from '../../../constants/Constants';
import EmptyContent from '../../../components/emptyContent';
import GlobalFiltersComponentContainer from '../../../components/globalFiltersComponent';
import { UIAction, ReportActions, DashboardActions  } from '../../../redux/actions';
import { warehousePermission } from '../../../hooks/functions';
import Loading from '../../../components/loading';

/*
* aka Inventory Locator
* used for both FDI Inventory and Warehouse Inventory
*/
const RegionGraph = (props: any) => {

    const classes = DashboardStyles();
    const [filter, setFilter] = React.useState({} as any);
    let [dataListType, setDataListType] = React.useState(2);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [isInventoryLocatorLoading, setIsInventoryLocatorLoading] = React.useState(false);
    let [tableDrawerRefresh, setTableDrawerRefresh] = React.useState('1');
    const [wareHouseFilter, setWareHouseFilter] = React.useState({} as any);
    const menuDrawerOpen = (open: any) => {
        setDrawerOpen(open);
    }

    const isWarehouseInventoryPage: boolean = window.location.pathname.indexOf('warehouse-inventory') > -1;

    const tableDrawerToggle = (open: any, tableType?: any) => {

        setDataListType(tableType);
        setDrawerOpen(open);
        tableDrawerRefresh = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        setTableDrawerRefresh(tableDrawerRefresh);
    }

    const searchInventoryLocator = async () => {
      if (!props.selectedSupplier) {
        return;
      }

      setIsInventoryLocatorLoading(true);
      props.setIsProductListLoading(true);

      const filterValues = props.selectedFilterValues || {};
      const selectedInventoryType: string = '2'; //hardcode to consignment as that is the only data we have

      if (selectedInventoryType === null || selectedInventoryType === '2') {
        tableDrawerToggle(true, 2);
      } 
      else {
        tableDrawerToggle(true, 1);
      }

      props.clearRegionGraphData();
      props.clearWareHouseData();

      const productGroup: any[] = [];
      const selectedProductsArray = filterValues['PRODUCT_NAME'] || [];

      if (selectedProductsArray?.length) {
        selectedProductsArray.forEach((item: any) => {
          productGroup.push(item.productGroup);
        })
      }
      
      filter.productGroup = (productGroup?.length) ? productGroup : undefined;

      if (
        isWarehouseInventoryPage || 
        (warehousePermission(props.authUser, props.selectedSupplier?.supplierCode) && 
        (selectedInventoryType === null || selectedInventoryType === '1'))
      ) {
        
        
        const newWarehouseFilter: any = {};
        newWarehouseFilter.supplierId = props.selectedSupplier?.supplierCode //supplier.supplierCode;
        newWarehouseFilter.productGroup = (productGroup && productGroup.length) ? productGroup.map((x: any) => '"' + x + '"').toString() : undefined;
        setWareHouseFilter(newWarehouseFilter);
        
        props.getWareHouseData(newWarehouseFilter);

        //confusing and could be done better but
        //in the case of the WH inventory locator need to short circuit this to make it show up
        if (isWarehouseInventoryPage) {
          setIsInventoryLocatorLoading(false);
          props.setIsProductListLoading(false);
        }
      }

      if ( !isWarehouseInventoryPage &&
          (selectedInventoryType === null || selectedInventoryType === '2')) {
        filter.supplierId = props.selectedSupplier?.id;
        filter.supplierCode = props.selectedSupplier?.supplierCode;

        setFilter(filter);

        let regionFilter: any = {
          "where": filter,
          "fields": { "state": true, "city": true, "postcode": true }
        }

        let regionPayload = [{ key: 'filter', value: JSON.stringify(regionFilter)}]
        if(props.selectedFilterValues['REGION']) regionPayload.push({ key: 'regionName', value: props.selectedFilterValues['REGION']})

        await props.getRegionGraphData(regionPayload);

        let fields: any = { productLabelName: true, productItemId: true };

        if (!filter.productItemId) {
          fields.productLabelName = false;
          fields.productItemId = false;
        }

        fields['state'] = true;
        fields['ndc'] = true;

        if (isWarehouseInventoryPage) {
          fields['plant'] = true;
        }

        let inventoryFilter: any = {
          offset: 0,
          limit: 50,
          where: filter,
          fields: fields,
          order: 'state'
        };

        let inventoryPayload = [{ key: 'filter', value: JSON.stringify(inventoryFilter)}]
        if(props.selectedFilterValues['REGION']) inventoryPayload.push({ key: 'regionName', value: props.selectedFilterValues['REGION']})

        props.showMiniLoader(true);
        await props.getInventoryList(inventoryPayload);

        setIsInventoryLocatorLoading(false);
        props.setIsProductListLoading(false);
      }
    }

    return (
		<BasePage
			title={isWarehouseInventoryPage ? Constants.PAGE_TITLE.WAREHOUSE_REGION_GRAPH : Constants.PAGE_TITLE.REGION_GRAPH}
      pageNameKey={isWarehouseInventoryPage ? 'warehouseInventoryLocator': 'inventoryLocator'}
      drawerOpen={true}
      //Don't think we want this behavior?
			// drawerOpen={
			// 	(props.graphData && props.graphData.length > 0) ||
			// 	(props.warehouseData && props.warehouseData.length > 0)
			// 		? !drawerOpen
			// 		: true
			// }
		>
			<div className={classes.appBarSpacer}></div>
			<Container maxWidth={false} className={classes.container}>
				<GlobalFiltersComponentContainer
					pageName="INVENTORY_LOCATOR"
					executeSearch={searchInventoryLocator}
					onReset={() => {
						props.resetAllFilters();
					}}
				/>

				{props.isLoading ||
				(props.graphData && props.graphData.length > 0) 
        ||
				(props.warehouseData && props.warehouseData.length > 0) ? (
					
          props.isProductListLoading || isInventoryLocatorLoading ? (
						<div>
							<Loading message="Loading  data..." />
						</div>
					) 
          : (
						<MarkerGraph
							wareHouseFilter={wareHouseFilter}
							dataListType={dataListType}
							filter={filter}
							menuDrawerOpen={menuDrawerOpen}
							tableDrawerRefresh={tableDrawerRefresh}
							tableDrawerOpen={drawerOpen}
              isWarehouseInventoryPage={isWarehouseInventoryPage}
						/>
					)
				) 
        : (
					<EmptyContent message="Select the filter criteria above to view current inventory information."></EmptyContent>
				)}
			</Container>
		</BasePage>
	);
}

const mapStateToProps = (state: any) => ({
    graphData: state.report?.graphData || [],
    inventoryData: state.report?.inventoryData?.result || [],
    totalInventoryCount: state.report?.inventoryData?.totalRecord || 0,
    warehouseData: state.report?.warehouseData || [],
    isLoading: state.ui.isLoading,
    selectedSupplier: state.ui.selectedSupplier || null,
    selectedFilterValues: state.ui.selectedFilterValues || {},
    isProductListLoading: state.dashboard.isProductListLoading || false,
    isDashboardLoading: state.dashboard.isDashboardLoading || false,
});

const mapDispatchToProps = (dispatch: any) => ({
  resetAllFilters: () => dispatch(UIAction.resetAllFilters()),
  getRegionGraphData: (filter: any) => dispatch(ReportActions.getRegionGraphData(filter)),
  getInventoryList: (filter: any) => dispatch(ReportActions.getDrawerData(filter)),
  showMiniLoader: (isLoader: boolean) => dispatch(UIAction.showMiniLoader(isLoader)),
  setSelectedSupplier: (data: any) => dispatch(UIAction.setSelectedSupplier(data)),
  getWareHouseData: (filter: any) => dispatch(ReportActions.getWareHouseData(filter)),
  clearRegionGraphData: () => dispatch(ReportActions.clearRegionGraphData()),
  clearWareHouseData: () => dispatch(ReportActions.clearWareHouseData()),
  getWareHouseDrawerData: (filter: any) => dispatch(ReportActions.getWareHouseDrawerData(filter)),
  setIsProductListLoading: (isLoading: boolean) => dispatch(DashboardActions.setIsProductsLoading(isLoading))
});

export default connect(mapStateToProps, mapDispatchToProps)(RegionGraph);

