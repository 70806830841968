import React from 'react';
import { connect } from 'react-redux';
import { Container } from '@material-ui/core';
import { DashboardStyles } from '../../hooks/styles'
import BasePage from '../common/base'
import GlobalFiltersComponentContainer from '../../components/globalFiltersComponent';
import { UIAction, ProductActions } from '../../redux/actions';
import Moment from "moment";
import { extendMoment } from "moment-range";
import { Constants } from '../../constants/Constants';
import { Paper } from '@material-ui/core';
import Api from "../../redux/lib/api";
import {  REPORTS } from '../../redux/constants/api.url';

const moment = extendMoment(Moment as any);

const ProviderProductConfig = (props: any) => {

  const classes = DashboardStyles();
  const [parsedLayoutData, setParsedLayoutData] = React.useState([] as any);

  React.useEffect(() => {
    
    if (props.providerProductConfigData?.results) {
      //parse data into needed grouping for UI to display
      const stepOneData = parseDataStepOne( props.providerProductConfigData.results );
      const stepTwoData = parseDataStepTwo(stepOneData.PPCObjects, stepOneData.PPCRawData);

      setParsedLayoutData(stepTwoData);
    }

  }, [props.providerProductConfigData]);

  const parseDataStepOne = (data: any) => {
    const productMap: any = {};
    //sort by product
      data.forEach((ppcQR: any) => {
        const productId = ppcQR.Product_Item_ID;

        if (!productId) return;

        if (!productMap[productId]) {
          productMap[productId] = {}; //1st level map 
        }

        const customerId = ppcQR.Customer_ID;

        if (!productMap[productId][customerId]) {
          productMap[productId][customerId] = []; //2nd level map, contains array of providers
        }
    
        productMap[productId][customerId].push(ppcQR);
      });

      return {
        PPCObjects: productMap,
        PPCRawData: data //for the map to look up data as needed
      }
 
  }

  const parseDataStepTwo = (PPCJson: any, PPCRawData: any) => {
    const arrayOfObjs: any[] = [];

    Object.keys(PPCJson).forEach((productObjKey: string) => {
      const thisProdObj = PPCJson[productObjKey];

      arrayOfObjs.push( createProductBlock(thisProdObj, productObjKey, PPCRawData) );
    });

    return arrayOfObjs;

    function createProductBlock(prodObj: any, prodName: string, PPCRawData: any[]) {

      const prodDetails = PPCRawData.find((rd: any) => {
        return rd.Product_Item_ID === prodName;
      });

      return (
        <Paper style={{
          marginBottom: 20,
          padding: 12
         }}>
          <h3>
              Product: {prodDetails?.product_name} (NDC: {prodDetails?.ndc})
          </h3>
          {
            Object.keys(prodObj).map((custObjKey: string) => {
              const thisCustArray = prodObj[custObjKey];

              return createCustomerBlock(thisCustArray, custObjKey, PPCRawData);
            })
          }
        </Paper>
      )

      function createCustomerBlock(custArray: any[], custId: string, PPCRawData: any[]) {
        const custDetails = PPCRawData.find((cd: any) => {
          return cd.Customer_ID === custId;
        });

        const cn = custDetails ? custDetails.Customer_Name : '';

        return (
          <div style={{
            marginLeft: 20,
            marginBottom: 20
          }}>
            <h4>
              Customer: {cn} ({custId})
            </h4>
            {custDetails && 
              <div>
                {custDetails.Customer_Street_Address1 || 'UNKNOWN ADDRESS'} {custDetails.Customer_Street_Address2}
                <br/>
                {custDetails.Customer_City || 'UNKNOWN ADDRESS'}, {custDetails.Customer_State} {custDetails.Customer_ZIP}
                <br />
                GPO: {custDetails.GPO_Name || 'UNKNOWN GPO'}

              </div>
            }
            {
              custArray.map((providerItem: any) => {             
                return createProviderBlock(providerItem);
              })
            }
          </div>
        )

        function createProviderBlock(providerItem: any) {
          return (
            <div style={{
              marginLeft: 20
            }}>
              Provider: {providerItem.first_name} {providerItem.last_name} (NPI: {providerItem.npi_id})
            </div>
          )
        }
      }
    }
  }
 
  const handleProviderProductConfigSearch = () => {
    const supplierId = props.selectedSupplier?.supplierCode || null;

    if (!supplierId) return;

    props.getProviderProductConfigData(supplierId);
  }

  const handleProviderProductConfigExport = async () => {
    const supplierId = props.selectedSupplier?.supplierCode || null;

    if (!supplierId) return;

    const reportParamsForExport: any = {
      report_type: "SUP_ProviderProductConfigReport",
      report_frequency: "Daily", //not really used, for convenience
      delivery_method: null,
      file_type: "csv",
      user_name: props.authUser?.record.email,
      recipient_addresses: [], //not really used, for convenience
      customers_reported_on_id: props.selectedSupplier.supplierCode,
      customers_reported_on_name: props.selectedSupplier.supplierName,
      timezone: moment.tz.guess(),
      additional_filters: [],
      custom_start_date: undefined,
      custom_end_date: undefined,
      origin_application: 'SUP',
      is_direct_download: true       
    };

    const rawCsv: any = await Api.post(REPORTS.SEND_ONE_TIME_REPORT, reportParamsForExport);

    var blob = new Blob([rawCsv.data]);
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = "report.csv";
    link.click();
  }


  return (
		<BasePage
			title={Constants.PAGE_TITLE.PROVIDER_PRODUCT_CONFIG}
      pageNameKey="providerProductConfigReport"    
			drawerOpen={true}
		>
			<Container maxWidth={false} className={classes.container}>
				<div className={classes.appBarSpacer}></div>

				<GlobalFiltersComponentContainer
					pageName="PROVIDER_PRODUCT_CONFIG"
					executeSearch={handleProviderProductConfigSearch}
					onReset={() => {
						props.resetAllFilters();
					}}
					doExport={handleProviderProductConfigExport}
				/>

				<div>{parsedLayoutData}</div>
			</Container>
		</BasePage>
  );
}
const mapStateToProps = (state: any) => ({
  selectedSupplier: state.ui.selectedSupplier || null,
  providerProductConfigData: state.product.providerProductConfigData || [],
  authUser: state.user.authUser
});

const mapDispatchToProps = (dispatch: any) => ({
  resetAllFilters: () => dispatch(UIAction.resetAllFilters()),
  getProviderProductConfigData: (supplierId: string) => dispatch(ProductActions.getProviderProductConfigData(supplierId))
});



export default connect(mapStateToProps, mapDispatchToProps)(ProviderProductConfig);

