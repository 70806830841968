import { action } from 'typesafe-actions';
import { PRODUCT_EXPIRATION } from '../constants/actions';
import { ResultSet } from '../models/common/result.set';

export const getExpiryGraphData   = (data: any) => action(PRODUCT_EXPIRATION.GET_GRAPH_DATA, data);
export const successExpiryGraphData   = (data: ResultSet<any>) => action(PRODUCT_EXPIRATION.SUCCESS_GRAPH_DATA, data);

export const getExpiryDatalist   = (data: any) => action(PRODUCT_EXPIRATION.GET_EXPIRY_DATALIST, data);
export const successExpiryDatalist   = (data: ResultSet<any>) => action(PRODUCT_EXPIRATION.SUCCESS_EXPIRY_DATALIST, data);

export const setSelectedState   = (stateCode: string) => action(PRODUCT_EXPIRATION.SET_SELECTED_STATE, stateCode);

export const clearProductExpirationData   = () => action(PRODUCT_EXPIRATION.CLEAR_PRODUCT_EXPIRATION_DATA);
