import { action } from 'typesafe-actions';
import { DASHBOARD } from '../constants/actions';
import { ResultSet } from '../models/common/result.set';

export const getStateInventory   = (data: any) => action(DASHBOARD.GET_STATE_INVENTORY, data);
export const successStateInventory   = (data: ResultSet<any>) => action(DASHBOARD.SUCCESS_STATE_INVENTORY, data);
export const showStateInventoryLoader = (isLoading : boolean) => action(DASHBOARD.SHOW_STATE_INVENTORY_LOADER, isLoading);

export const getInventoryRegionData   = (data: any) => action(DASHBOARD.GET_INVENTORY_REGION_DATA, data);
export const successInventoryRegionData   = (data: ResultSet<any>) => action(DASHBOARD.SUCCESS_INVENTORY_REGION_DATA, data);
export const showInventoryRegionLoader = (isLoading : boolean) => action(DASHBOARD.SHOW_INVENTORY_REGION_LOADER, isLoading);

export const getInventoryRollUp   = (data: any) => action(DASHBOARD.GET_INVENTORY_ROLL_UP, data);
export const successInventoryRollUp   = (data: ResultSet<any>) => action(DASHBOARD.SUCCESS_INVENTORY_ROLL_UP, data);
export const showInventoryRollUpLoader = (isLoading : boolean) => action(DASHBOARD.SHOW_INVENTORY_ROLL_UP_LOADER, isLoading);


export const getInventoryRevenue   = (data: any) => action(DASHBOARD.GET_INVENTORY_REVENUE, data);
export const successInventoryRevenue   = (data: ResultSet<any>) => action(DASHBOARD.SUCCESS_INVENTORY_REVENUE, data);
export const showInventoryRevenueLoader = (isLoading : boolean) => action(DASHBOARD.SHOW_REVENUE_LOADER, isLoading);


export const getComparisionForSale   = (data: any) => action(DASHBOARD.GET_COMPARISION_FOR_SALE, data);
export const successComparisionForSale   = (data: ResultSet<any>) => action(DASHBOARD.SUCCESS_COMPARISION_FOR_SALE, data);
export const showComparisionForSaleLoader = (isLoading : boolean) => action(DASHBOARD.SHOW_COMPARISION_FOR_SALE_LOADER, isLoading);

export const getRegionInventory   = (data: any) => action(DASHBOARD.GET_REGION_INVENTORY, data);
export const successRegionInventory   = (data: ResultSet<any>) => action(DASHBOARD.SUCCESS_REGION_INVENTORY, data);
export const showRegionInventoryLoader   = (isLoading : boolean) => action(DASHBOARD.SHOW_REGION_INVENTORY_LOADER, isLoading);

export const getAllDashboardData = (filter: any) => action(DASHBOARD.GET_ALL, filter);
export const setDashboardLoading = (isLoading: boolean) => action(DASHBOARD.SET_IS_LOADING, isLoading);
export const setIsProductsLoading = (isLoading: boolean) => action(DASHBOARD.SET_IS_PRODUCTS_LOADING, isLoading); 

export const getCustomerCabinetCounts   = (data: any) => action(DASHBOARD.GET_CUSTOMER_CABINET_COUNTS, data);
export const setCustomerCabinetCounts   = (data: ResultSet<any>) => action(DASHBOARD.SET_CUSTOMER_CABINET_COUNTS, data);
export const showCustomerCabinetCountsLoader = (isLoading : boolean) => action(DASHBOARD.SHOW_CUSTOMER_CABINET_COUNTS_LOADER, isLoading);

export const clearDashboard = () => action(DASHBOARD.CLEAR_DASHBOARD); 
