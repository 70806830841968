import React from 'react';
import { Container, Paper, Button } from '@material-ui/core';
import { IMAGES_URL } from '../../constants/Images';
import { connect } from 'react-redux';

const FourZeroFour = (props: any) => {
  const goToLogin = () => {
    window.location.href = '/';
  }

  return (
    <>
      {!props.authProcessing &&
        <Container component="main" maxWidth="xs">
          <Paper style={{
            marginTop: '2em',
            padding: '1.5em',
            textAlign: 'center',
            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`
          }}>
            <img style={{ width: '100%' }} src={IMAGES_URL.FFF_LOGO} alt="FFF Logo" />
            <h1>404</h1>
            <p>Page not found</p>
            <Button
              onClick={goToLogin}
              type="button"
              variant="contained"
              color="primary"
              style={{ backgroundColor: '#558db1' }}
            >
              Go Back to App
            </Button>
          </Paper>
        </Container>
      }
    </>
  );
}

const mapStateToProps = (state: any) => ({
  authProcessing: state.ui.authProcessing
})

export default connect(mapStateToProps)(FourZeroFour);