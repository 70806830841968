import { makeStyles } from '@material-ui/core/styles';
const drawerWidth = 230
export const SideBarStyles = makeStyles((theme) => ({
    
    appMenu: {
      width: '100%',
    },
    navList: {
      width: drawerWidth,
    },
    menuItem: {
      width: 'auto',
    },
    menuItemActive: {
      width: 'auto',
      background: '#dfdfdf',
    },
    menuItemIcon: {
      minWidth: '40px !important'
    },
    link: {
      'text-decoration': 'inherit',
      color: 'inherit',
      '&:focus, &:hover, &:visited, &:link, &:active': {
        'text-decoration': 'inherit',
        color: 'inherit'
      }
    }
    
  
  }));
export default SideBarStyles;