
export const nameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
export const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
export const passwordRegex = {
  oneNumber: /^(?=.*\d)/,
  oneSpecialChar: /^(?=.*[~!@#$%^&*_?])/,
  oneLowercase: /^(?=.*[a-z])/,
  oneUppercase: /^(?=.*[A-Z])/,
  lengthLimits: /^.{8,255}$/
}
export const getUserResources = (claims: any, tenantId: string) => {
  let resources: string[] = [];
  if (!claims || !tenantId) {
    return [];
  }
  
  if (claims['ALL']) {
    tenantId = 'ALL'
  }
  if (!claims[tenantId]) {
    return [];
  }
  Object.keys(claims[tenantId]).forEach((resource) => {
    resources.push(resource);
  })
  return resources;
}

export const getUserRoles = (claims: any, tenantId: string, resource: string) => {
  if (!claims || !tenantId || !resource) {
    return [];
  }
  if (claims['ALL']) {
    tenantId = 'ALL'
  }
  if (!claims[tenantId]) {
    return []
  }

  const userRole = claims[tenantId][resource];
  
  if (!userRole) {
    return [];
  }
  if (userRole === 'OWNER') {
    return ['OWNER', 'EDITOR', 'VIEWER'];
  }
  if (userRole === 'EDITOR') {
    return ['EDITOR', 'VIEWER'];
  }
  return ['VIEWER'];
}