import moment from "moment";
import { Constants } from "../../constants/Constants";

// isOffset refers to if the date/timestamp has an offset for the timezone and whether to apply it or not
// 'false' means the offset will be ignored so no conversion will be applied
export const CustomDateFormat = (dateValue: any, isOffset = false, dateFormat?: string) => {
    if (dateValue) {
        if (!dateFormat) dateFormat = Constants.DATE_FORMAT;
        return isOffset === false ? moment(dateValue).utcOffset(0, false).format(dateFormat) : moment(dateValue).format(dateFormat);
    } else {
        return '';
    }
}