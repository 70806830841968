import Api from "../lib/api";
import { USER, USER_PROPERTY, REPORTS, BASE, USER_SERVICE_URL } from '../constants/api.url';
import { UserRecord, Claims } from '../models/user/user';
import { ReportSubscription } from '../models/reports/scheduled-reports-types';

export class UserService {
  private static instance: UserService;
  private constructor() { }

  public RESOURCES = ['inventory', 'inventoryLocator', 'userManagement', 'region', 'dashboard', 'productExpiration'];

  public static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }

  public setupUser = async (payload: any): Promise<any> => {
    await Api.post(USER.URL + '/setup', payload);
  }

  public createUser = async (userData: UserRecord) : Promise<any> => {
    await Api.post(USER.URL, userData);
  }

  public updateUser = async (userData: UserRecord) : Promise<any> => {
    const id = userData.id;
    delete userData.id;
    await Api.patch(USER.URL + '/' + id, userData);
  }

  public getUserRecord = async (uid: string) : Promise<UserRecord> => {
    const response = await Api.get(USER.URL + '/' + uid);
    const record = response.data;
    const userRecord: UserRecord = record;
    return userRecord;
  }

  public getUserByEmail = async (email: string) : Promise<UserRecord> => {
    const response = await Api.get(USER.URL + '/exists?email=' + email);
    const record = response.data;
    const userRecord: UserRecord = record;
    return userRecord;
  }

  public getUserNav = (claims: Claims) => {
    const tenantIds = this.deriveUserTenants(claims);
    let userAllowedResources: string[] = [];
    tenantIds.forEach((tenantId) => {
      userAllowedResources = userAllowedResources.concat(Object.keys(claims[tenantId]));
    });
    const uniqueResourceSet = new Set(userAllowedResources);
    userAllowedResources = Array.from(uniqueResourceSet);
    return userAllowedResources.filter((resource) => {
      return this.RESOURCES.includes(resource);
    })
  }

  public getUserList = async (params: any | null)   => {
    //console.log('params',params);
    //const queryParams = (params)?params:[];
    //const paramsObj:any = {key: "suppliers", value: "1"};
    //queryParams.push(paramsObj);
    //const url = USER.URL + (tenantId ? `?tenantId=${tenantId}` : '');
    return (await Api.get(USER.URL,params)).data;
    //return await (Api.get(USER.URL)).data;
  }

  public downloadUsersCsv = async (params: any): Promise<any> => {
		const { data } = await Api.get(USER.URL, params);
		var blob = new Blob([data]);
		var link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = 'users.csv';
		link.click();
	};

  private deriveUserTenants = (claims: Claims = {}) => {
    return Object.keys(claims);
  }

  public createUserProperty = async (payload: any | null) => {
    return await Api.post(`${USER_PROPERTY.URL}/${payload.user_id}`, payload.body);
  }

  private createQueryParams = (filter: any) => {
    let paramString: string = '';
    Object.keys(filter).forEach(function (key: any, index: number) {
      if (filter[key] !== undefined && filter[key] !== 'undefined') {
        paramString += (index > 0) ? '&' + key + '=' + filter[key] : '?' + key + '=' + filter[key]
      }
    })
    return paramString;
  }

  public getUserProperties = async (params: any | null) => {
    return await Api.get(`${USER_PROPERTY.URL}${this.createQueryParams(params)}`)
  }

  public createReportSubscription = async (reportSubscriptionData: ReportSubscription): Promise<any> => {
    const response = await Api.post(REPORTS.CREATE_SUBSCRIPTION, reportSubscriptionData);
    return response.data as any;
  }

  public deleteReportSubscription = async (params: object): Promise<any> => {
    const response = await Api.post(REPORTS.DELETE_SUBSCRIPTION, params);
    return response.data as any;
  }

  // public createOneTimeReport = async (params: object): Promise<any> => {
  //   const response = await Api.get(REPORTS.CREATE_ONE_TIME_REPORT, params);
  //   return response as any;
  // }

  public editReportSubscription = async (reportSubscriptionData: ReportSubscription): Promise<any> => {
    const response = await Api.patch(REPORTS.EDIT_SUBSCRIPTION, reportSubscriptionData);
    return response.data;
  }

  public sendOneTimeReportRequest  = async (params: object): Promise<any> => {
    const response = await Api.post(REPORTS.SEND_ONE_TIME_REPORT, params);
    return {
      success: true
    };
  }

  public getCustomToken = async (payload: any): Promise<any> => {
    return await Api.post(USER.URL + '/login', payload);
  }

//https://dev-gateway.rightnowinventory.com/suppliers/queue/userreportqueue
  public getUserReportQueue = async (): Promise<any> => {
    //return await Api.get(BASE.URL + '/queue/suppliers/userreportqueue');
    return await Api.get(`${USER_SERVICE_URL}/reports/user-report-queue?origin_app=SUP`);
  }

  public updateUserQueueItem = async (params: object): Promise<any> => {
    const response = await Api.patch(REPORTS.UPDATE_QUEUE_ITEM, params);
    return response;
  }

}

//originApp



