import { UserRecord } from '../models/user/user';
import { userIsAuthorized, RequestPermission } from '@fffenterprises/authorization/lib/userAuthorization';
import firebase from '../../auth/firebase';
import { jwtDecode } from 'jwt-decode';

export default class AuthUser {
  public record: UserRecord;
  private loginExp: number;
  private activityMonitor: any;

  constructor(user: UserRecord, authStorage: string) {
    const decodedToken: any = this.getDecodedToken(authStorage);
    this.loginExp = decodedToken.exp as number;
    this.record = user;
    this.activityMonitorInit();
  }

  public authorizationCheck = (permission: RequestPermission): boolean => {
    return userIsAuthorized(this.record.claims, permission);
  }

  public activityMonitorInit = () => {
    this.activityMonitor = setInterval(() => {
      const idToken = localStorage.getItem('id_token') || '';
			const decodedToken: any = this.getDecodedToken(idToken);
      const loginExp = decodedToken.exp as number;
      const now = new Date().getTime() / 1000;
      if (now > loginExp) {
        window.location.href = '/logout?sessionTimeout=true';
        clearInterval(this.activityMonitor);
      }

      const rniAuthToken = localStorage.getItem('rniAuthToken');
			if (!rniAuthToken) {
				window.location.href = '/logout?sessionTimeout=true';
				clearInterval(this.activityMonitor);
			}
    }, 5000)
  }

  refreshSession = async () => {
    try {
			firebase.auth().onAuthStateChanged(	async (user: any) => {
				const rniAuthToken = localStorage.getItem('rniAuthToken');
				if (!user && !rniAuthToken) {
          clearInterval(this.activityMonitor);
					window.location.href = '/logout?sessionTimeout=true';
          return;
				}
				const idToken = await user?.getIdToken(true);
				localStorage.setItem('id_token', idToken as string);
			});
		} catch (error) {
			console.log('TOKEN ERROR', error);
      window.location.href = '/logout?sessionTimeout=true';
		}
  }

  getDecodedToken = (idToken: string) => {
    try {
      return jwtDecode(idToken);
    } catch (error) {
      console.log('TOKEN ERROR', error)
      window.location.href = '/logout?authError=true';
      return {};
    }
  };
}