import { action } from 'typesafe-actions';
import { USER } from '../constants/actions';

export const userTokenRequestStartAction = () => action(USER.TOKEN);
export const userTokenRequestCompleteAction = (response: {token: string, uid: string}) => action(USER.TOKEN_RESPONSE, response);

export const userRecordRequestStartAction = () => action(USER.RECORD_REQUEST);
export const userRecordRequestCompleteAction = (userRecord: object) => action(USER.RECORD_RESPONSE, userRecord);

export const setUserNav = (userNav: string[]) => action(USER.SET_NAV, userNav);

export const clearUserState = () => action(USER.CLEAR_USER_STATE);
export const sendUserSetup = (payload: any) => action(USER.SEND_USER_SETUP, payload);

export const userPropertiesCreateStartAction = (data: any) => action(USER.CREATE_USER_PROPERTY_REQUEST, data);
export const getUserPropertiesAction = (data: any) => action(USER.GET_USER_PROPERTY_REQUEST, data);
export const successUserPropertiesAction = (response: any) => action(USER.GET_USER_PROPERTY_RESPONSE, response);

export const setSnackbarOpen = (isOpen: boolean) => action(USER.SET_SNACKBAR_OPEN, isOpen);
export const setSnackbarMessage = (payload: object) => action(USER.SET_SNACKBAR_MESSAGE, payload);

export const createReportSubscription = (payload: any) => action(USER.CREATE_REPORT_SUBSCRIPTION_REQUEST, payload);
export const deleteReportSubscription = (payload: any) => action(USER.DELETE_REPORT_SUBSCRIPTION_REQUEST, payload);
export const createOneTimeReport = (payload: any) => action(USER.CREATE_ONE_TIME_REPORT_REQUEST, payload);
export const editReportSubscription = (payload: any) => action(USER.EDIT_REPORT_SUBSCRIPTION_REQUEST, payload);
export const sendOneTimeReport = (payload: any) => action(USER.SEND_ONE_TIME_REPORT_REQUEST, payload);

export const addUserSubscriptionSuccess = (subscrObj: any) => action(USER.ADD_USER_SUBSCRIPTION_SUCCESS, subscrObj);
export const deleteUserSubscriptionSuccess = (subscrName: string) => action(USER.DELETE_USER_SUBSCRIPTION_SUCCESS, subscrName);
export const sendOneTimeReportSuccess = () => action(USER.SEND_ONE_TIME_REPORT_SUCCESS);
export const editUserSubscriptionSuccess = (subscrObj: any) => action(USER.EDIT_USER_SUBSCRIPTION_SUCCESS, subscrObj);

export const getUserReportQueue = () => action(USER.GET_USER_REPORT_QUEUE);
export const getUserReportQueueSuccess = (updatedQueue: any[]) => action(USER.GET_USER_REPORT_QUEUE_SUCCESS, updatedQueue);

export const updateUserQueueItem = (payload: any) => action(USER.UPDATE_USER_QUEUE_ITEM, payload);

export const adjustAwaitedQueueItemsCount = (adjustment: number) => action(USER.ADJUST_AWAITED_QUEUE_ITEMS_COUNT, adjustment);

export const handleRniAuthToken = (payload: object) => action(USER.HANDLE_RNI_AUTH_TOKEN, payload);

export const handleRniAuthPageRefresh = (payload: object) => action(USER.HANDLE_RNI_AUTH_PAGE_REFRESH, payload);
