import React from 'react';
import { connect } from 'react-redux';
import MUIDataTable from "mui-datatables";
import { List } from "../../constants/Constants";
import { InventoryActions, UIAction } from '../../redux/actions';
import EmptyContent from '../../components/emptyContent';

const CurrentInventoryComponent = (props: any) => {
  let [tableColumns, setColumns] = React.useState(props.columns());

	const [tableHeight, setTableHeight] = React.useState(0);

	// Calculate table body height as a percentage of the viewport height
	React.useEffect(() => {
		const viewportHeight = window.innerHeight;
		const percentageHeight = 70; // Adjust this value as needed
		const calculatedHeight = (percentageHeight / 100) * viewportHeight;
		setTableHeight(calculatedHeight);
	}, []);

	return (
		<>
			{!props.inventoryList && <EmptyContent message="Select the filter criteria above to view current inventory." />}
			{props.inventoryList && (
				<MUIDataTable
					data={props.inventoryList}
					columns={tableColumns}
					options={{
						rowsPerPageOptions: List.PageLimitOptions,
						filterType: 'multiselect',
						selectableRows: 'none',
						filter: false,
						search: false,
						download: false,
						count: props.inventoryListCount,
						pagination: true,
						page: props.pagination.page,
						rowsPerPage: props.pagination.limit,
						fixedHeader: true, // This locks the table headers at the top
						tableBodyHeight: tableHeight + 'px', // Set the height for the table body
					}}
				/>
			)}
		</>
	);
}

const mapStateToProps = (state: any) => (
  {
    inventoryList: state.inventory.currentInventory ? state.inventory.currentInventory.result : [],
    inventoryListCount: state.currentInventory ? state.currentInventory.totalRecord : 0,
    isLoading: state.ui.isLoading,
    selectedFilterValues: state.ui.selectedFilterValues || {},
    authUser: (state.user.authUser) ? state.user.authUser : [],
    supplierCode: (state.ui.selectedSupplier && state.ui.selectedSupplier.supplierCode) ? state.ui.selectedSupplier.supplierCode : null,
    userNav: (state.user.nav) ? state.user.nav : [],
  }
);

const mapDispatchToProps = (dispatch: any) => ({
  getLotLevelInventory: (filter: any) => dispatch(InventoryActions.getLotLevelInventory(filter)),
  clearInventoryList: () => dispatch(InventoryActions.clearInventoryList()),
  showLoader: (isLoader: boolean) => dispatch(UIAction.showLoader(isLoader)),
  getExportInventory: (filter: any) => dispatch(InventoryActions.getExportInventory(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrentInventoryComponent);