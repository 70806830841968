import React from 'react';
import { connect } from 'react-redux';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highmaps";
import proj4 from "proj4";
import { NumberFormatter, NumberFormatterWithCommas } from '../../../hooks/functions';
import { TextLabels } from '../../../constants/dataList';

window.proj4 = window.proj4 || proj4;
window.Highcharts = Highcharts;

interface LineChartProps {
  titleText?: string;
  subtitleText?: string;
  categories?: any[];
  yAxisTitleText?: string;
  series: any[];
  datalabel?: string;
}

const LineChart = (props: LineChartProps) => {

  const chartOption = {
    chart: {
      type: 'column'
    },
    title: {
      text: (props.titleText) ? props.titleText : '',
    },
    subtitle: {
      text: (props.subtitleText) ? props.subtitleText : '',
    },
    xAxis: {
      categories: props.categories
    },
    yAxis: {
      title: {
        text: (props.yAxisTitleText) ? props.yAxisTitleText : '',
      }
    },
    tooltip: {
      formatter: function (this: any) {
        const dataLabel = (props.datalabel) ? props.datalabel:'';
        let tooltipHtml = '';
        tooltipHtml += '<span style="font-size:10px"><b>' + this.x + '</b></span><table>';
        this.points.forEach((element: any) => {
          tooltipHtml += '<tr><td style="color:' + element.series.color + ';padding:0">' + element.series.name + ': </td>';
          tooltipHtml += '<td style="padding:0"><b>' + dataLabel+NumberFormatterWithCommas(element.point.y) + '</b> (' + TextLabels.SellableUnits + ': ' + element.point.units + ')</td></tr>';
        });
        tooltipHtml += '</table>';
        return tooltipHtml;
      },
      shared: true,
      useHTML: true
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0
      }
    },
    series: props.series,
    credits: {
      enabled: false
    },
  };
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOption}
      containerProps={{ style: { height: "300px", width: '100%' } }}
    />
  )
}
const mapStateToProps = (state: any) => (
  {}
);
export default connect(mapStateToProps)(LineChart);