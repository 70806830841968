import moment from 'moment-timezone';

export const constructEnqueueParams = (
  reportType: string, 
  userEmail: string, 
  filterObj: any=null, 
  additionalMetadata:any={}, 
  timezone?:string
) => {

	if (filterObj && !filterObj.limit) {
		filterObj.limit = 9999;
	}

	return {
    filter_object_from_ui: filterObj,
    report_type: reportType,
    report_frequency: 'OneTime',
    delivery_method: 'UserQueue',
    file_type: 'csv',
    user_name: userEmail,
    recipient_addresses: JSON.stringify([userEmail]),//probably not used, but in case we want to email reminder
    customers_reported_on_id: undefined, //left here for clarity but for this type of report, details of what we actually want to query will be on filter_object_from_ui
    customers_reported_on_name: undefined,
    timezone: timezone || moment.tz.guess(),
    additional_filters: undefined,
    custom_start_date: undefined,
    custom_end_date: undefined,
    origin_application: 'SUP',
    report_additional_metadata: additionalMetadata
  }
}

export const findUserSelectedColumns = (tableColumns: any[]) => {
  const columnsRequested: string[] = [];
   
  tableColumns.forEach((tc: any) => {
    if ((tc.display === "true" || tc.display === true) && tc.name) {
      columnsRequested.push(tc.name);
    }
  });

  return columnsRequested;
}
