import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl, TextField, Switch, FormControlLabel } from '@material-ui/core';
import { Styles } from './style';
import SelectBox from '../../components/selectBox';
import { UserProperty } from '../../redux/models/user/user';
import CheckIcon from '@material-ui/icons/Check';
import CreateIcon from '@material-ui/icons/Create';
import moment from 'moment-timezone';
import { mapStateToProps, mapDispatchToProps } from './redux-container';

interface ProfileProps {
    user: any;
    userPropertiesCreate: (data: any) => void;
    properties: UserProperty[];
    user_properties: UserProperty[];
}
const Profile = (props: ProfileProps) => {
    const classes = Styles();
    let [firstName, setFirstName] = React.useState('');
    let [lastName, setLastName] = React.useState('');
    let [email, setEmail] = React.useState('');
    let [selectedTimeZones, setSelectedTimeZones] = React.useState('');
    const TIMEZONE: string = 'TIMEZONE';
    const [timeZoneReadOnly, setTimeZoneReadOnly] = React.useState(true);
    const [userSettings, setUserSettings] = React.useState<any>(JSON.parse(localStorage.getItem('userSettings') || '{}'))

    const setTimeZone = (properties: any[]) => {
        if (properties && properties.length > 0) {
            const timezone_key_index: any = properties.findIndex((obj: { propertyKey: string; }) => obj.propertyKey === TIMEZONE);
            selectedTimeZones = (timezone_key_index > -1) ? properties[timezone_key_index].propertyValue : '';
            setSelectedTimeZones(selectedTimeZones);
        } else {
            setSelectedTimeZones('');
        }
    }

    const handleToggleProviderData = () => {
        let providerDataSetting = false
        if (userSettings && userSettings.showProviderData) {
            providerDataSetting = userSettings.showProviderData
        }
        let newUserSettings = {...userSettings, showProviderData: !providerDataSetting}
        localStorage.setItem('userSettings', JSON.stringify(newUserSettings))
        setUserSettings(newUserSettings)
    }

    React.useEffect(() => {
        if (props.properties && props.properties.length > 0) {
            setTimeZone(props.properties);
        } else {
            setTimeZone(props.properties)
        }
    }, [props.properties]);

    React.useEffect(() => {
        if (props.user_properties && props.user_properties.length > 0) {
            setTimeZone(props.user_properties);
        }
    }, [props.user_properties])

    React.useEffect(() => {
        firstName = (props.user && props.user.firstName) ? props.user.firstName : '';
        lastName = (props.user && props.user.lastName) ? props.user.lastName : '';
        email = (props.user && props.user.email) ? props.user.email : '';
        setFirstName(firstName);
        setLastName(lastName);
        setEmail(email);
    }, [props.user]);

    const getTomeZonesList = () => {
        const time = new Date();
        let timezoneList = [{ name: moment.tz.guess() + ' ( Local Timezone )', offset: time.getTimezoneOffset() }];
        const usTimezones = moment.tz.zonesForCountry('US', true);
        timezoneList = timezoneList.concat(usTimezones);
        return timezoneList;
    }

    const setUserTimeZone = (value: string) => {
        setSelectedTimeZones(value);
        if (!email) {
            return;
        }
        let payload: any = {};
        let body: any = {};
        payload.user_id = email;
        body.property_key = TIMEZONE;
        body.property_value = value;
        payload.body = body;
        props.userPropertiesCreate(payload);
    }

    return (
        <div className={classes.profileBox}>
            <Grid container spacing={2} >
                <Grid item xs={5} >
                    <FormControl className={classes.profileFormControl}>
                        <TextField
                            InputLabelProps={{ className: classes.inputLabel }}
                            InputProps={{
                                value: firstName,
                                readOnly: true,
                                disableUnderline: true
                            }}
                            className={classes.profileInput} autoComplete='off' id="outlined-basic" label={'First Name'} onChange={({ target }) => setFirstName(target.value)} />
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container spacing={2} >
                <Grid item xs={5} >
                    <FormControl className={classes.profileFormControl}>
                        <TextField
                            InputLabelProps={{ className: classes.inputLabel }}
                            InputProps={{
                                value: lastName,
                                readOnly: true,
                                disableUnderline: true
                            }}
                            className={classes.profileInput} autoComplete='off' id="outlined-basic" label={'Last Name'} onChange={({ target }) => setLastName(target.value)} />
                    </FormControl>
                </Grid>
            </Grid>


            <Grid container spacing={2} >
                <Grid item xs={5}>
                    <FormControl className={classes.profileFormControl}>
                        <TextField
                            InputLabelProps={{ className: classes.inputLabel }}
                            InputProps={{
                                value: email,
                                readOnly: true,
                                disableUnderline: true
                            }}
                            className={classes.profileInput} autoComplete='off' id="outlined-basic" label={'Email'} onChange={({ target }) => setEmail(target.value)} />
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container spacing={2} >
                <Grid item xs={3}>
                    <FormControl className={classes.profileFormControl} >

                        {(timeZoneReadOnly) ?
                            <TextField
                                InputLabelProps={{ className: classes.inputLabel }}
                                InputProps={{
                                    value: selectedTimeZones,
                                    readOnly: true,
                                    disableUnderline: true
                                }}
                                className={classes.profileInput} autoComplete='off' id="outlined-basic" label={'Timezone'} />

                            : <SelectBox
                                selected={selectedTimeZones}
                                style={{ width: 200 }}
                                inputLabel={'Timezone'}
                                emptyItemLabel={'Choose'}
                                listItems={getTomeZonesList()}
                                onChangeItem={(value: any) => {
                                    setSelectedTimeZones(value);
                                }}
                                getOptionLabel={(option: any) =>
                                    `${option.name || ''} ${option.label || ''}`
                                }
                                getOptionValue={(option: any) =>
                                    `${option.name || ''}`
                                }

                            />
                        }

                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl className={classes.iconFormControl}>
                        {(timeZoneReadOnly) ?
                            <CreateIcon onClick={() => setTimeZoneReadOnly(false)} />
                            : <CheckIcon onClick={() => {
                                setUserTimeZone(selectedTimeZones)
                                setTimeZoneReadOnly(true)
                            }} />
                        }
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container spacing={2} >
                <Grid item xs={5} >
                    <FormControl className={classes.profileFormControl}>
                        <FormControlLabel
                            control={
                                <Switch 
                                    checked={userSettings?.showProviderData || false} 
                                    onChange={handleToggleProviderData} 
                                    name="showProviderDataToggle" 
                                    color="primary"
                                />
                            }
                            label="Show Provider Data"
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);