import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { UIAction } from '../../../redux/actions';
import { Avatar } from '@material-ui/core';
import { BasePageStyles } from '../../../hooks/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Button, Modal } from '@material-ui/core';
import QueueModal from './queueModal';

const MenuListComposition = (props: any) => {
  const classes = BasePageStyles();
  const [open, setOpen] = React.useState(false);
  const [authenticatedUser, setAuthenticatedUser] = React.useState({} as any);
  const [showReportQueueModal, setShowReportQueueModal] = React.useState(false);
  const [undownloadedInQueueCount, setUndownloadedInQueueCount] = React.useState(0);
  const anchorRef = React.useRef(null);
  const history = useHistory();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event:any) => {
    //if (anchorRef.current && anchorRef.current.contains(event.target)) {
      //return;
    //}
    //setOpen(false);
  };
  const handleLogout = (event:any) => {
    window.location.href = '/logout';
  };

  function handleListKeyDown(event:any) {
    if (event.key === 'Tab') {
      event.preventDefault();
     // setOpen(false);
    }
  }
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (props.authUser) {
      setAuthenticatedUser(props.authUser.record)
    }

    if (prevOpen.current === true && open === false) {
      //anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open, props]);


  const handleProfile = () =>{
    props.dispatch(UIAction.setApiError(null));
    history.push('/profile');
  }

  const navigateToQueue = () => {
    setShowReportQueueModal(true);
  }

  //show on UI when there are unread reports in the queue
  React.useEffect(() => {
    if (!props.reportQueue?.length) {
      setUndownloadedInQueueCount(0);
      return;
    }

    const undownloadedCount = props.reportQueue.filter(function(queueItem: any) {
        return !(queueItem.hasBeenDownloaded);
    }).length

    setUndownloadedInQueueCount(undownloadedCount)
  }, [props.reportQueue]);

  const markQueueItemReadLocally = () => {
    if (undownloadedInQueueCount > 0) {
      setUndownloadedInQueueCount(undownloadedInQueueCount - 1);
    }
  }

  return (
    <>
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className="profileMenuButton"
        >
          {Boolean(undownloadedInQueueCount > 0) && 
            <div style={{
                position: 'absolute',
                top: -2,
                left: 40,
                color: 'red',
                zIndex: 2,
                borderRadius: '50%',
                backgroundColor: '#fff',
                padding: '1px 8px',
                fontSize: 14,
                border: '2px solid #ddd'
              }}
            >  
              <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontWeight: 800
                }}
              >
                {undownloadedInQueueCount}
              </div>
            </div>
          }
          <Avatar className={classes.avatar} ref={anchorRef} >
            {`${authenticatedUser?.firstName?.charAt(0)}${authenticatedUser?.lastName?.charAt(0)}`}
          </Avatar>
          <MenuItem className={classes.profileName}>{`${authenticatedUser?.firstName} ${authenticatedUser?.lastName}`}</MenuItem>
          <ArrowDropDownIcon />
        </Button>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    {props.authUser && 
                      <MenuItem onClick={handleProfile}>{`${authenticatedUser?.firstName} ${authenticatedUser.lastName}`}</MenuItem>
                    }
                    {props.authUser && 
                        <MenuItem onClick={navigateToQueue}>
                          Download Queue ({undownloadedInQueueCount})
                        </MenuItem>
                      }
                    <MenuItem onClick={handleLogout} >Logout</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      <Modal
          disableBackdropClick={true}
          open={showReportQueueModal}
        >   
          <QueueModal
            close={() => {
              setShowReportQueueModal(false)
            }}
            markQueueItemReadLocally={markQueueItemReadLocally}
          />
        </Modal>
      </>
  );
}

const mapStateToProps = (state:any) => (
    {
      authUser: state.user.authUser,
      reportQueue: state.user.authUser?.record?.supplierReportQueue || []
    }
  );
export default connect(mapStateToProps)(MenuListComposition);


