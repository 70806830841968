import axios from 'axios';
import { HttpResponse } from '../models/common/result.set';
import configuration from "../../config";


import { store } from '../store/store';

var FileSaver = require('file-saver');
export default class Api {

    static getBaseURL() {
        return '';
    }

    static async headers(route: string) {
        const state = store.getState() as { user: { firebaseToken: string } };
        const token = state.user.firebaseToken;
        let header: any = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        };
        // for local env 
        if (configuration.isLocal) {
            header['x-endpoint-api-userinfo'] = configuration.localUserHeaderAuth;
        }

        return header;

    }

    static get(route: string, params: any = null, optional: any = null): Promise<HttpResponse<any>> {
        return this.xhr(route, params, 'GET', optional);
    }

    static post(route: string, params: any): Promise<HttpResponse<any>> {
        return this.xhr(route, params, 'POST');
    }

    static put(route: string, params: any): Promise<HttpResponse<any>> {
        return this.xhr(route, params, 'PUT');
    }

    static patch(route: string, params: any): Promise<HttpResponse<any>> {
        return this.xhr(route, params, 'PATCH');
    }

    static delete(route: string, params: any): Promise<HttpResponse<any>> {
        return this.xhr(route, params, 'DELETE');
    }

    static encodeUrlParams(url: string, params: { key: string, value: string }[]) {
        let paramString = '';
        params.forEach((param, index) => {
            paramString += (index > 0) ? '&' + param.key + '=' + encodeURI(param.value) : '?' + param.key + '=' + encodeURI(param.value)
        })
        url += paramString;
        return url;
    }

    static async xhr(route: string, params: any, verb: any, optional?: any): Promise<HttpResponse<any>> {

        let url = Api.getBaseURL() + route;
        if (verb === 'GET' && params) {
            url = this.encodeUrlParams(url, params);
            params = null;
        }
        const headers = await Api.headers(route);
        let options: any;
        if (params && params.option && params.option.content_type) {
            options = Object.assign({ method: verb, headers: headers, url: url, responseType: 'arraybuffer' as 'arraybuffer' }, params ? { data: params } : null);
        } else {
            options = Object.assign({ method: verb, headers: headers, url: url }, params ? { data: params } : null);
        }

        return axios(options).then(resp => {
            const header = resp.headers;
            if (resp.status === 200 || resp.status === 201 || resp.status === 204) {
                // if (header['content-type'] === 'text/csv; charset=utf-8') {
                //     const blob = new Blob([resp.data], {
                //         type: "application/vnd.ms-excel;charset=utf-8"
                //     });
                //     const fileName = (optional?.fileName) ? optional.fileName : 'download.csv';
                //     FileSaver.saveAs(blob, fileName);
                //     throw new Error('File Saved');
                // } else {
                    return resp;
                // }
            } else {
                throw resp;
            }
        }).catch(error => {
            throw error;
        });
    }
}