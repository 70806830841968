import { HttpResponse, ResultSet } from "../models/common/result.set";
import Api from "../lib/api";
import { FilterOption } from "../models/common/ui";
import { TENANTS } from "../constants/api.url";

export class UIService {

  private static instance: UIService;
  private constructor() { }

  public static getInstance(): UIService {
    if (!UIService.instance) {
        UIService.instance = new UIService();
    }
    return UIService.instance;
  }

  public getFilterData = async (payload: any) => {
    const filterList: FilterOption[] = payload
    return Promise.all(filterList.map(async (filter: FilterOption) => {
        const params = Object.keys(filter.params).map((filterKey: any) => ({
            key: filterKey,
            value: filter.params[filterKey],
        }));
        switch(filter.name){
            case 'CLASS_OF_TRADE':
                return await Api.get(`${TENANTS.CLASS_OF_TRADE_OPTIONS}`, params)
            case 'FACILITY_TYPE':
                return await Api.get(`${TENANTS.FACILITY_TYPE_OPTIONS}`, params)
        }
    }))
  }

}