import { makeStyles } from '@material-ui/core/styles';
export const DashboardStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },

    depositContext: {
      flex: 1,
    },
    root: {
      flexGrow: 1,
      margin:'auto',
      width:'90%'
    },
    gridPaper: {
      textAlign: 'center',
      color: theme.palette.text.secondary,
      minHeight:'50px',
      /*borderRadius:'10px',*/
      border:'1px solid #e0dada',
      boxShadow:'none',
      "&:hover": {
        boxShadow:'0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
      }

      
    },
    inventoryRollUpGridPaper: {
      height:'122px'
    },
    fdiRollUpGridPaper: {
      height:'135px'
    },
    gridItem:{
      height:'200px'
    },

    infoBox:{
      transform:'translateY(50%)',
      fontSize:'14px',
      fontWeight:600,
      height: 50
      
    },
    iconBox:{
      transform:'translateY(50%)'
    },
    icon:{
      /*background:'#f2f2f2',*/
      height:'40px',
      width:'40px',
      borderRadius:'50%',
      lineHeight:'53px',
      'float':'right'

    },
    labelInfo:{
      fontSize:'15px',
      marginBottom:'10px'
    },

    gridPaperBottom: {
    
      padding: theme.spacing(2),
      textAlign: 'center',
      /*color: theme.palette.text.secondary,*/
      minHeight:'140px',
      /*borderRadius:'10px',*/
      border:'1px solid #e0dada',
      boxShadow:'none',
      "&:hover": {
        boxShadow:'0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
      }
    },

    inventoryRollUpSkeleton: {
      borderRadius: '3%'
    },
    titleHeading:{
      marginBottom:10
    },
    normalErrorColor:{
      background:'#fff'
    },

  }));
  export default DashboardStyles;