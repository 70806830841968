

//import { TenantsFilter, TenantsList} from "../models/tenants/tenants";
import { GET_REGIONS, GET_SUPPLIER_LIST } from '../constants/api.url';
import { HttpResponse, ResultSet } from "../models/common/result.set";
import Api from "../lib/api";


export class SupplierService {

    private static instance: SupplierService;
    private constructor() { }

    public static getInstance(): SupplierService {

      if (!SupplierService.instance) {
        SupplierService.instance = new SupplierService();
      }
      return SupplierService.instance;
    }

    public getSuppliers = async (filter: any | null) => {
      try {
        const params = [{key: 'filter', value: filter}];
        return await Api.get(`${GET_SUPPLIER_LIST.URL}`,params)
      }
      catch(e) {
        return null;
      }
    }

    public getRegions = async () => {
      try {
        return await Api.get(`${GET_REGIONS.URL}`)
      }
      catch(e) {
        return null;
      }
    }

}