import config from "../../config";

const API_BASE_URL = {
	API_GATEWAY: config.apiGateway.host,
};

const SUPPLIER_SERVICE_URL = API_BASE_URL.API_GATEWAY;
export const USER_SERVICE_URL = API_BASE_URL.API_GATEWAY;
//const BIGQUERY_SERVICE_URL = (process.env.REACT_APP_BIGQUERY_SERVICE_URL)?process.env.REACT_APP_BIGQUERY_SERVICE_URL:API_BASE_URL.API_GATEWAY;
const INVENTORY_SERVICE_URL = API_BASE_URL.API_GATEWAY;



export const BASE = {
	URL: `${USER_SERVICE_URL}`,
};

export const USER = {
	URL: `${USER_SERVICE_URL}/suppliers`,
};

export const GET_SUPPLIER_LIST = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier`,
};

export const GET_REGIONS = {
  URL: `${SUPPLIER_SERVICE_URL}/regions`,
}

export const GET_PRODUCT_LIST = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/productsGroup`,
};

export const GET_PRODUCT_NAMES = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/products`,
};

export const GET_INVENTORY_LIST = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/inventory/group`,
};

export const GET_INVENTORY_DETAIL_LIST = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/inventory`,
};

export const GET_LOCATION_LIST = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/locations`,
};

export const GET_TRANSACTIONS = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/transactions`,
};

export const GET_CUSTOMER_CABINET_COUNTS = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/dashboard/customerCabinetCounts`,
};

export const GET_REGION_GRAPH_DATA = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/inventory/geo/location`,
};

export const GET_INVENTORY_REGION_DATA = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/dashboard`,
};

export const GET_PRODUCT_EXPIRY_BIGQUERY = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/bigquery/inventory/expiry`,
};

export const GET_PRODUCT_EXPIRY_LIST_BIGQUERY = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/bigquery/inventory/expiry/detail`,
};

export const GET_INVENTORY_WAREHOUSE_DATA = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/bigquery/nonConsignment/inventory`,
};

export const GET_INVENTORY_WAREHOUSE_DRAWER_DATA = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/bigquery/nonConsignment/inventory/detail`,
};

export const GET_WAREHOUSE_GROUP_INVENTORY = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/bigquery/nonConsignment/inventory/group`,
};

export const GET_SUPPLIER_REGIONS = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/region`,
};

export const GET_REGIONS_STATES = {
	URL: `${SUPPLIER_SERVICE_URL}/ping`,
};

export const GET_STATES_LIST = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/region/availableState`,
};

export const UPDATE_REGIONS_STATES = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/region/update/state`,
};

export const UPDATE_SUPPLIER_REGIONS = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/region/update`,
};

export const CREATE_SUPPLIER_REGIONS = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/region/create`,
};

export const DELETE_SUPPLIER_REGIONS = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/region/delete`,
};

export const GET_INVENTORY_ROLL_UP = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/bigquery/inventoryRollUp`,
};

export const GET_STATE_INVENTORY = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/bigquery/stateInventory`,
};

export const GET_INVENTORY_REVENUE = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/bigquery/inventoryRevenue`,
};

export const GET_EXPORT_INVENTORY = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/inventory/export`,
};

export const GET_COMPARISION_FOR_SALE = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/bigquery/timelineRevenue`,
};

export const GET_REGION_INVENTORY = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/bigquery/regionInventory`,
};

export const GET_REGION_GROUP_INVENTORY = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/regionInventory`,
};

export const SAVE_SEARCH = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/saveSearch`,
};
export const SAVE_SEARCH_SCHEDULE = {
	URL: `${SUPPLIER_SERVICE_URL}/supplier/saveSearch/schedule`,
};

export const USER_PROPERTY = {
	URL: `${USER_SERVICE_URL}/userproperties`,
};

export const GET_PAR_DATA = {
	URL: `${INVENTORY_SERVICE_URL}/supplier/customer/product/par`,
};

export const GET_PROVIDER_PRODUCT_CONFIG_DATA = {
	URL: `${API_BASE_URL.API_GATEWAY}/reports/product-provider-config-data`,
};

export const REPORTS = {
	//CRUD for scheduled reports
	CREATE_SUBSCRIPTION: `${API_BASE_URL.API_GATEWAY}/reports/user/reportSubscription`,
	DELETE_SUBSCRIPTION: `${API_BASE_URL.API_GATEWAY}/reports/user/deleteSubscription`,
	EDIT_SUBSCRIPTION: `${API_BASE_URL.API_GATEWAY}/reports/user/reportSubscription`,
	SEND_ONE_TIME_REPORT: `${API_BASE_URL.API_GATEWAY}/reports/generate-scheduled`,
	UPDATE_QUEUE_ITEM: `${API_BASE_URL.API_GATEWAY}/reports/reportqueue`
};

export const REGIONS = {
	// CRUD for regions
	CREATE: `${API_BASE_URL.API_GATEWAY}/regions`,
	READ: `${API_BASE_URL.API_GATEWAY}/regions`,
	UPDATE: `${API_BASE_URL.API_GATEWAY}/regions`,
	DELETE: `${API_BASE_URL.API_GATEWAY}/regions`,

	// Gets the available data to be put into the region based on type.
	// IE: Gives a list of customer IDs if the region type is "CUSTOMER"
	GET_AVAILABLE_DATA: `${API_BASE_URL.API_GATEWAY}/regions/data`,
	UPLOAD_CSV: `${API_BASE_URL.API_GATEWAY}/regions/data`,
	DOWNLOAD_CSV_TEMPLATES: `${API_BASE_URL.API_GATEWAY}/regions/data/templates`,
};

export const TENANTS = {
	//CRUD for scheduled reports
	CLASS_OF_TRADE_OPTIONS: `${API_BASE_URL.API_GATEWAY}/tenants/classOfTradeOptions`,
	FACILITY_TYPE_OPTIONS: `${API_BASE_URL.API_GATEWAY}/tenants/facilityTypeOptions`,
};
