import createReducer from "./createReducer";
import { SupplierRegionsAction } from "../actions";
import { MAPPING, SUPPLIER_REGIONS } from "../constants/actions";

export const supplierRegion = createReducer(
	{},
	{
		[MAPPING.SUPPLIER_REGIONS.SUCCESS_SUPPLIER_REGIONS](
			state = {},
			action: ReturnType<typeof SupplierRegionsAction.successSupplierRegionsList>
		) {
			return { ...state, suppliersRegionsList: action.payload };
		},

		[MAPPING.REGIONS_STATES.SUCCESS_REGIONS_STATES](
			state = {},
			action: ReturnType<typeof SupplierRegionsAction.successRegionsStateList>
		) {
			let data = action.payload.data;
			let type = action.payload.type;
			return { ...state, [`${type}`]: data };
		},

		[MAPPING.STATE_LIST.SUCCESS_STATES_LIST](
			state = {},
			action: ReturnType<typeof SupplierRegionsAction.successStateList>
		) {
			return { ...state, statesList: action.payload };
		},

		[SUPPLIER_REGIONS.SUCCESS_GET_REGION](
			state = {},
			action: ReturnType<typeof SupplierRegionsAction.successReadRegionAction>
		) {
			return { ...state, regionsList: action.payload };
		},

		[SUPPLIER_REGIONS.SUCCESS_GET_AVAILABLE_REGION_DATA](
			state = {},
			action: ReturnType<typeof SupplierRegionsAction.successGetAvailableRegionDataAction>
		) {
			return { ...state, regionData: action.payload };
		},

		[SUPPLIER_REGIONS.CLEAR_REGION_DATA](
			state = {},
			action: ReturnType<typeof SupplierRegionsAction.clearRegionData>
		) {
			return { ...state, regionData: [] };
		},
	}
);
