import createReducer from './createReducer';
import { UserActions } from '../actions';
import { USER } from '../constants/actions';
import { isUndefined } from 'lodash';

export const user = createReducer({}, {
    [USER.TOKEN_RESPONSE](state = {}, action: ReturnType<typeof UserActions.userTokenRequestCompleteAction>) {
        return {...state, firebaseToken : action.payload.token, uid: action.payload.uid }
    },
    [USER.RECORD_RESPONSE](state = {}, action: ReturnType<typeof UserActions.userRecordRequestCompleteAction>) {
        return {...state, authUser: action.payload}
    },
    [USER.SET_NAV](state = {}, action: ReturnType<typeof UserActions.setUserNav>) {
        return {...state, nav: action.payload}
    },
    [USER.CLEAR_USER_STATE](state = {}, action: ReturnType<typeof UserActions.clearUserState>) {
        return {...state, firebaseToken: undefined, uid: undefined, record: undefined, nav: undefined}
    },
    [USER.GET_USER_PROPERTY_RESPONSE](state = {}, action: ReturnType<typeof UserActions.successUserPropertiesAction>) {
        return {...state, user_properties: action.payload}
    },
     [USER.SET_SNACKBAR_OPEN](state = {}, action: ReturnType<typeof UserActions.setSnackbarOpen>) {
      return {...state, isSnackbarOpen: action.payload}
    },
    [USER.SET_SNACKBAR_MESSAGE](state = {}, action: ReturnType<typeof UserActions.setSnackbarMessage>) {
        return {...state, 
                  isSnackbarOpen: action.payload.message?true:false,
                  snackbarMessage: action.payload.message,
                  snackbarStatus: action.payload.type || 'info',
                  snackbarAutohide: isUndefined(action.payload.snackbarAutohide) ? 5000 : action.payload.snackbarAutohide
                }
    },
    [USER.ADD_USER_SUBSCRIPTION_SUCCESS](state = {} as any, action: ReturnType<typeof UserActions.addUserSubscriptionSuccess>) {
      if (!state.authUser) return {...state}

      const newAuthUser = {...state.authUser};
      newAuthUser.record.supplierReportSubscriptions = newAuthUser.record.supplierReportSubscriptions || {};
      const newSuscrName = action.payload.subscriptionName;
      newAuthUser.record.supplierReportSubscriptions[newSuscrName] = action.payload;

      return {...state, authUser: newAuthUser}
    },
    [USER.DELETE_USER_SUBSCRIPTION_SUCCESS](state = {} as any, action: ReturnType<typeof UserActions.deleteUserSubscriptionSuccess>) {
      if (!state.authUser) return {...state}

      const newAuthUser = {...state.authUser};
      newAuthUser.record.supplierReportSubscriptions = newAuthUser.record.supplierReportSubscriptions || {};
      delete newAuthUser.record.supplierReportSubscriptions[ action.payload ]

      return {...state, authUser: newAuthUser}
    },
    [USER.ADJUST_AWAITED_QUEUE_ITEMS_COUNT](state = {} as any, action: ReturnType<typeof UserActions.adjustAwaitedQueueItemsCount>) {

        const oldVal = state.awaitingQueueItemCount || 0;
        const adjustmentVal = action.payload;
        const netVal = oldVal + adjustmentVal; //may be adding negative number to decrement
        const finalVal = (netVal > 0) ? netVal : 0

        return { ...state, awaitingQueueItemCount: finalVal }
    },
    [USER.GET_USER_REPORT_QUEUE_SUCCESS](state = {} as any, action: ReturnType<typeof UserActions.getUserReportQueueSuccess>) {

        if (!state.authUser || !action.payload) return { ...state }

        const updatedUser = { ...state.authUser };



        if (JSON.stringify(updatedUser.record.supplierReportQueue) === JSON.stringify(action.payload )) {
            return { ...state } //no change
        }

        updatedUser.record.supplierReportQueue = action.payload || [];

        return { ...state, authUser: updatedUser }
    }
  })

