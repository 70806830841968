export  const FixViewColumns=(changedColumn: string, direction: string, tableColumns: any)=>{
    let index = tableColumns.findIndex((obj: { name: string; }) => obj.name === changedColumn);
    const editableColumns = [...tableColumns];
    if (!editableColumns[index].options.change){
        editableColumns[index].options.display = true;
    }else {
        editableColumns[index].options.display = (direction === 'add') ? (true) : (false);
    }
    return editableColumns;
}
export default FixViewColumns;