import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { CssBaseline, Drawer, AppBar, Toolbar, List, Divider, IconButton, MenuList } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SideMenuBar from './sideBar';
import MenuListComposition from './menuList';
import Loading from '../../../components/loading';

import { IMAGES_URL } from '../../../constants/Images';
import { BasePageStyles } from '../../../hooks/styles'
import { UIAction, UserActions } from '../../../redux/actions'
import { Alert, AlertTitle } from '@material-ui/lab';
import SupplierSelector from './supplierSelector';
import usePoll from 'react-use-poll';

const BasePage = (props: any) => {

  const classes = BasePageStyles();
  const [open, setOpen] = React.useState(props.drawerOpen);

  const checkQueue = () => {
    props.getUserReportQueue(); //this method checks and only makes the server call if we are awaiting new reports!
  }

  usePoll(() => checkQueue(), [], {
    interval: 10000 //should be async but brute force delay for now
  });


  React.useEffect(() => {
    setOpen(props.drawerOpen);
  }, [props.drawerOpen]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };



  const clearError = () => {
    props.dispatch(UIAction.setApiError(null))
  }



  return (
    <div className={clsx(classes.root, props.isLoading ? "show-loader" : "")}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          {props.title && (
            <MenuList className={classes.topmenu}>
              <h3>{props.title}</h3>
            </MenuList>
          )}
          <SupplierSelector onChangeSupplier={() => {}} />
          <MenuListComposition />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={IMAGES_URL.FFF_LOGO} className="logo-image" alt="fdi" />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>

        <List className='side-menu-bar'>
          <SideMenuBar 
            open={open} 
            setOpen={setOpen} 
            title={props.title} 
            pageNameKey={props.pageNameKey}
          />
        </List>
      </Drawer>
      <main className={classes.content}>
        {props.children}{" "}
        {/* content of the specific page, inclusing filters besides the Supplier Selector, inserted here */}
        {props.errorMessage && (
          <div onClick={clearError} className={classes.errorAlert}>
            <Alert severity={props.errorMessage.severity}>
              <AlertTitle>{props.errorMessage.title}</AlertTitle>
              {props.errorMessage.errorMessage}
            </Alert>
          </div>
        )}
      </main>

      {props.isLoading ? <Loading message="Loading..." /> : ""}
    </div>
  );
};
//export default BasePage;
const mapStateToProps = (state: any) => ({
  activeMenu: state.ui.menuState,
  isLoading: state.ui.isLoading,
  errorMessage: state.ui.errorMessage,
  awaitingQueueItemCount: state.user?.awaitingQueueItemCount || 0
});

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  getUserReportQueue: () => dispatch(UserActions.getUserReportQueue())
})

export default connect(mapStateToProps, mapDispatchToProps)(BasePage);

