
import { store } from '../store/store';
import { userIsAuthorized } from '@fffenterprises/authorization/lib/userAuthorization';

export class AuthCheck {

    private static instance: AuthCheck;
    private constructor() { }
    public static getInstance(): AuthCheck {
        if (!AuthCheck.instance) {
            AuthCheck.instance = new AuthCheck();
        }
        return AuthCheck.instance;
    }

    public check = async (permissions: any) => {

        const states = store.getState();
        const user: any = (states && states.user) ? states.user : {};
        const claims: any = user.authUser.record.claims;
        console.log("CLAIMS", claims);
        console.log("PERMS", permissions);
        let isAuthorized = await userIsAuthorized(claims, permissions);
        if (!isAuthorized) {
            throw new Error('403');
        }
        return;
    }





}