import createReducer from './createReducer';
import { InventoryActions } from '../actions'
import { INVENTORY } from '../constants/actions';
import { ObjectListToString } from '../../hooks/functions';


export const inventory = createReducer({}, {

  [INVENTORY.SUCCESS_INVENTORY_LIST](state = {}, action: ReturnType<typeof InventoryActions.successInventoryList>) {
    return { ...state, inventoryData: action.payload }
  },


  [INVENTORY.SUCCESS_CURRENT_INVENTORY](state = {}, action: ReturnType<typeof InventoryActions.successCurrentInventory>) {
    return { ...state, currentInventory: action.payload }
  },


  [INVENTORY.SUCCESS_INVENTORY](state = {}, action: ReturnType<typeof InventoryActions.successInventory>) {
    const payload: any = action.payload;
    const request: any = JSON.parse(payload.request);
    let dataIndex: string = '';
    if (request.where) {
      dataIndex = ObjectListToString(dataIndex,request.where);
    }
    return { ...state, [`INVENTORY-${dataIndex}`]: payload.response }
  },




  [INVENTORY.SUCCESS_INVENTORY_DETAIL_LIST](state = {}, action: ReturnType<typeof InventoryActions.successInventoryDetailList>) {
    const payload: any = action.payload;
    const request: any = JSON.parse(payload.request);
    let dataIndex: string = '';
    if (request.where) {
      dataIndex = ObjectListToString(dataIndex,request.where);
    }
    return { ...state, [`INVENTORY_DETAIL-${dataIndex}`]: payload.response }

  },

  [INVENTORY.CLEAR_INVENTORY_LIST](state = {}, action: ReturnType<typeof InventoryActions.clearInventoryList>) {
    return { ...state, inventoryData: [], currentInventory: [], warehouseInventory: [] }
  },


  [INVENTORY.SUCCESS_GROUP_INVENTORY](state = {}, action: ReturnType<typeof InventoryActions.successGroupInventory>) {
    const payload: any = action.payload;
    const request: any = JSON.parse(payload.request);
    let dataIndex: string = '';
    if (request.where) {
      dataIndex = ObjectListToString(dataIndex,request.where);
    }
    return { ...state, [`INVENTORY_GROUP-${dataIndex}`]: payload.response }

  },


  [INVENTORY.SUCCESS_LOT_LEVEL_INVENTORY](state = {}, action: ReturnType<typeof InventoryActions.successLotLevelInventory>) {
    const payload: any = action.payload;
    const request: any = JSON.parse(payload.request[0].value);
    let dataIndex: string = '';
    if (request.where) {
      dataIndex = ObjectListToString(dataIndex,request.where);
    }
    return { ...state, [`INVENTORY_LOT-${dataIndex}`]: payload.response }

  },

  [INVENTORY.SUCCESS_WAREHOUSE_INVENTORY](state = {}, action: ReturnType<typeof InventoryActions.successWareHouseInventory>) {
    return { ...state, warehouseInventory: action.payload };
  },

  [INVENTORY.SUCCESS_REGION_GROUP_INVENTORY](state = {}, action: ReturnType<typeof InventoryActions.successRegionGroupInventory>) {
    return { ...state, regionGroup: action.payload }
  },

  [INVENTORY.SUCCESS_PAR_DATA](state = {}, action: ReturnType<typeof InventoryActions.successParData>) {
    const payload: any = action.payload;
    const request: any = payload.request;
    let dataIndex: string = '';
    if (request) {
     dataIndex = ObjectListToString(dataIndex,request);
    }
    return { ...state, [`PAR-DATA-${dataIndex}`]: payload.response }
  },




});