import FDI_LOGO from '../assets/images/view-logo.svg';
import FFF_LOGO from '../assets/images/view-logo.svg';
import WAREHOUSE_ICON from '../assets/images/warehouse_icon.png';
import ORANGE_CLUSTER from '../assets/images/orange_cluster.png';
import GREEN_CLUSTER from '../assets/images/green_cluster.png';
import PRODUCT_EXPIRATION_ICON from '../assets/images/Product_Expiration.svg';

export const IMAGES_URL = {
    FDI_LOGO: FDI_LOGO,
    FFF_LOGO: FFF_LOGO,
    WAREHOUSE_ICON: WAREHOUSE_ICON,
    ORANGE_CLUSTER: ORANGE_CLUSTER,
    GREEN_CLUSTER: GREEN_CLUSTER,
    PRODUCT_EXPIRATION_ICON:PRODUCT_EXPIRATION_ICON
};