import React from "react";
import { connect } from "react-redux";
import { SupplierRegionsAction } from "../../../redux/actions";
import DualListSelector from "../../../components/dual-list-selector/dualListSelector";
import { RegionTypes, RegionTypeMap } from "./region.interface";
import { updateRegionParams } from "../../../redux/services";

const EditRegions = (props: any) => {
	const [availableRegionData, setAvailableRegionData] = React.useState(Array<any>());

	const handleOnSelectionComplete = (selections: Array<any>) => {
		const selectionsToSend: any = [];
		for (let i = 0; i < selections.length; i++) {
			const selectionId = props.regionData.find((data: any) => data.name === selections[i].data).id
			selectionsToSend.push(selectionId);
		}
		const updateRegionCallback = () => {
			const editedRegion = { ...props.region };
			editedRegion.regionData = props.regionData.filter((data: any) => selections.includes(data.name));
			props.clearRegionData()
			props.onChangeMode("DISPLAY", editedRegion);
		}
		props.updateRegionAction({ id: props.region.id, regionData: selectionsToSend , callback: updateRegionCallback});
	};

	const handleOnCancelEdit = () => {
			props.clearRegionData()
			props.onChangeMode("DISPLAY");
	}

	const currentRegionTableData: Array<any> = [];
	
	for (let i = 0; i < props.region.regionData.length; i++) {
		currentRegionTableData.push({ data: props.region.regionData[i].name });
	}

	React.useEffect(() => {
		props.getRegionData(props.region.regionType);
	}, []);

	React.useEffect(() => {
		const newAvailableRegionData = [];
		const filteredRegionData = props.regionData.filter((data: any) => !currentRegionTableData.map((currentData: any) => currentData.data).includes(data.name))
		for (let i = 0; i < filteredRegionData.length; i++) {
			newAvailableRegionData.push({ data: filteredRegionData[i].name });
		}
		setAvailableRegionData(newAvailableRegionData);
	}, [props.regionData]);

	React.useEffect(() => {
		props.getRegionData(props.region.regionType);
	}, [props.region.regionType]);

	return (
		<div>
			<DualListSelector
				newSelectionTitle={props.region.regionName}
				newSelectionsData={currentRegionTableData}
				availableSelectionTitle={"Available " + RegionTypeMap[props.region.regionType as keyof RegionTypes]}
				availableOptionsData={availableRegionData}
				columns={[{ name: "data", label: RegionTypeMap[props.region.regionType as keyof RegionTypes] }]}
				onSelectionComplete={handleOnSelectionComplete}
				onCancelSelection={handleOnCancelEdit}
			></DualListSelector>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	selectedSupplier: state.ui.selectedSupplier ? state.ui.selectedSupplier : null,
	regionData: state.supplierRegion && state.supplierRegion.regionData ? state.supplierRegion.regionData : [],
	currentState: state,
});

const mapDispatchToProps = (dispatch: any) => ({
	getRegionData: (type: string) => dispatch(SupplierRegionsAction.getAvailableRegionDataAction(type)),
	updateRegionAction: (newRegionData: updateRegionParams) => dispatch(SupplierRegionsAction.updateRegionAction(newRegionData)),
	clearRegionData: () => dispatch(SupplierRegionsAction.clearRegionData())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditRegions);
