import createReducer from './createReducer';
import { ProductActions } from '../actions'
import { PRODUCT } from '../constants/actions';


export const product = createReducer({}, {

  [PRODUCT.PRODUCT_LIST_SUCCESS](state = {}, action: ReturnType<typeof ProductActions.productListSuccess>) {
    return { ...state, productList: action.payload }
  },

  [PRODUCT.PRODUCT_NAME_LIST_SUCCESS](state = {}, action: ReturnType<typeof ProductActions.productNameListSuccess>) {
    return { ...state, productNameList: action.payload }
  },

  [PRODUCT.PROVIDER_PRODUCT_CONFIG_DATA_SUCCESS](state = {}, action: ReturnType<typeof ProductActions.providerProductConfigDataSuccess>) {
    return { ...state, providerProductConfigData: action.payload }
  },




});