import local from './config/local.json';
import devSup from './config/dev-sup.json';
import qaSup from './config/qa-sup.json';
import sup from './config/sup.json';
import devView from './config/dev-view.json'
import qaView from './config/qa-view.json'
import view from './config/view.json'
import demoView from './config/demo-view.json'

const configFiles: any = {
  'dev-sup': devSup,
  'qa-sup': qaSup,
  'sup': sup,
  'dev-view': devView,
  'qa-view': qaView,
  'view': view,
  'demo-view': demoView
}

function getConfig() {
  // @ts-ignore
  const subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;

  
  if (!subdomain) {
    return local;
  }
  

  return configFiles[subdomain];
}

export default (getConfig());
