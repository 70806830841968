import React from 'react';
import { Popper } from '@material-ui/core';
import Autocomplete, { AutocompleteCloseReason } from '@material-ui/lab/Autocomplete';
import ButtonBase from '@material-ui/core/ButtonBase';
import { TextField, InputBase,ClickAwayListener } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from '../styles';
import { connect } from 'react-redux';
import { SaveSearchActions } from '../../../redux/actions';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useHistory } from "react-router-dom";
import {Constants} from '../../../constants/Constants';

interface FilterListProps {
    searchList: any;
    authUser: any;
    getAdvanceSearch: (filter: any) => void;
    changeFilter: (filter: any) => void;
    pageName: string;
    componentKey?:any;
    error:any;
}
const FilterList = (props: FilterListProps) => {
    const history = useHistory();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [value, setValue] = React.useState<null | any[]>(null);
    const isFirstRender = React.useRef(true);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickAway = () => {
        handleClose()
    }
    const handleClose = () => {
       
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'github-label' : undefined;

    let payload: any = {
        order: ["title asc"],
        where: { userEmail: props.authUser?.record?.email,pageName:`${Constants.SAVE_SEARCH.PAGE_PREFIX}${props.pageName}` },
    };

    const searchOnTyping = (keyword?: any) => {
        payload.where.title = (keyword.length > 0) ? { like: "%" + keyword + "%" } : undefined;
        props.getAdvanceSearch(JSON.stringify(payload));
    };

    React.useEffect(() => {
        if (isFirstRender.current) {
            props.getAdvanceSearch(JSON.stringify(payload));
        }
        isFirstRender.current = false;
    })

    React.useEffect(() => {
        if (props.error && props.error.severity != 'error' && props.error.operation ==  'CREATE') {
            props.getAdvanceSearch(JSON.stringify(payload));
        }
    },[props.error])

    const onChangeItem = (itemValue:any) => {
        if(itemValue) {
            props.changeFilter(itemValue)
        }

        setValue(null); //allow re-selection of the same filter in case they want to reapply after Reset or other changes made
    }

    React.useEffect(() => {
        setValue(null);
    },[props.componentKey])

    return (
        <React.Fragment>
            <div className={classes.root}>
                <ButtonBase
                    disableRipple
                    className={classes.button}
                    aria-describedby={id}
                    onClick={handleClick}
                >
                    <ExpandMoreIcon className={classes.expandButton} />
                </ButtonBase>
            </div>
            
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-start"
                className={classes.popper}
            >
                <div className={classes.header} onClick={()=>{
                    history.push('/save-search');
                }}><div className={classes.saveSearchLeft}>Manage Filter</div><div className={classes.saveSearchRight}><ArrowForwardIcon /></div></div>
                <ClickAwayListener onClickAway={handleClickAway}>
                    <Autocomplete
                    classes={{
                        paper: classes.paper,
                        option: classes.option,
                        popperDisablePortal: classes.popperDisablePortal,
                    }}
                    onChange={(event?: any, value?: any) => onChangeItem(value)}
                    value={value}
                    open
                    //onClose={handleClose}
                    id="combo-box-demo"
                    options={props.searchList}
                    getOptionLabel={(option: any) => option.title}
                    style={{ width: 300 }}
                    renderInput={(params) => (
                        <InputBase
                            onChange={({ target }) => searchOnTyping(target.value)}
                            ref={params.InputProps.ref}
                            inputProps={params.inputProps}
                            autoFocus
                            className={classes.inputBase}
                        />
                    )}
                />
                </ClickAwayListener>
            </Popper>
            
        </React.Fragment>
    );
}

const mapStateToProps = (state: any) => (
    {
        searchList: (state.saveSearch && state.saveSearch.searchList) ? state.saveSearch.searchList : [],
        authUser: state.user.authUser,
        error: (state.saveSearch && state.saveSearch.error) ? state.saveSearch.error : null
    }

);

const mapDispatchToProps = (dispatch: any) => ({
    getAdvanceSearch: (filter: any) => dispatch(SaveSearchActions.getAdvanceSearch(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterList);
