import { call, put } from 'redux-saga/effects';
import { ProductActions,UIAction, DashboardActions  } from '../actions/index';
import { ProductService } from '../services';
import { SanitizeArray } from '../../hooks/functions/SanitizeData';



export function* getProductList(action: ReturnType<typeof ProductActions.getProductList>) {
    try {
    	yield put(DashboardActions.setIsProductsLoading(true));
        yield put(UIAction.showProductLoading(true));
        const { data } = yield call(ProductService.getInstance().getProducts, action.payload);
        yield put(ProductActions.productListSuccess(SanitizeArray(data)));
        yield put(UIAction.showProductLoading(false));
        yield put(DashboardActions.setIsProductsLoading(false));
    } 
    catch (err) {
        yield put(UIAction.showProductLoading(false));
        console.log("saga error" + err); 
    }
}

export function* getProductNameList(action: ReturnType<typeof ProductActions.getProductNameList>) {
    try {
        // yield put(DashboardActions.setIsProductsLoading(true));
        // yield put(UIAction.showProductLoading(true));
        const { data } = yield call(ProductService.getInstance().getProductNames, action.payload);

        
        //for simplicity, we reuse the name productGroup for the shared component to understand
        const formattedResult: any[] = [];

        data.forEach((di: any) => {
          if (!(formattedResult.find((p: any) => {
              return di.productLabelName === p.productGroup //avoid dupes
          }))) {
           formattedResult.push({
            productGroup: di.productLabelName
            })
          }           
        });

        yield put(ProductActions.productNameListSuccess(formattedResult));
        // yield put(UIAction.showProductLoading(false));
        // yield put(DashboardActions.setIsProductsLoading(false));
    } 
    catch (err) {
       // yield put(UIAction.showProductLoading(false));
        console.log("saga error" + err); 
    }
}

export function* getProviderProductConfigData(action: ReturnType<typeof ProductActions.getProviderProductConfigData>) {
    try {
        const { data } = yield call(ProductService.getInstance().getProviderProductConfigData, action.payload);
        yield put(ProductActions.providerProductConfigDataSuccess(data));
    } 
    catch (err) {
        // yield put(UIAction.showProductLoading(false));
        // yield put(DashboardActions.setIsProductsLoading(false));
        console.log("saga error" + err);
    }
}







