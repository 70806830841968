import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import * as uiReducer from './ui.reducer';
import * as userReducer from './user.reducer';
import * as supplierReducer from './supplier.reducer';
import * as productReducer from './product.reducer';
import * as inventoryReducer from './inventory.reducer';
import * as locationReducer from './location.reducer';
import * as reportReducer from './report.reducer';
import * as dashboardReducer from './dashboard.reducer';
import * as productExpirationReducer from './product.expiration.reducer';
import * as supplierRegionReducer from './supplier.region.reducer';
import * as userManagementReducer from './user-management.reducer';
import * as saveSearchReducer from './save.search.reducer';
import * as transactionReducer from './transaction.reducer';
import * as tenantsReducer from './tenants.reducer';
const createRootReducer = (history: History<any>) => combineReducers(Object.assign(
  { router: connectRouter(history) },
  uiReducer,
  userReducer,
  supplierReducer,
  productReducer,
  inventoryReducer,
  locationReducer,
  reportReducer,
  dashboardReducer,
  productExpirationReducer,
  supplierRegionReducer,
  userManagementReducer,
  saveSearchReducer,
  transactionReducer,
  tenantsReducer,
));
export default createRootReducer;
