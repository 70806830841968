export interface Region {
	id?: string;
	userEmail?: string;
	regionName: string;
	regionType: string;
	regionData?: Array<string>;
}

export interface RegionTypes {
	STATE: string;
	CUSTOMER: string;
	ZIP3: string;
	GPO: string;
	IDN: string;
}

export const RegionTypeMap: RegionTypes = {
	STATE: "US States",
	CUSTOMER: "Customer IDs",
	ZIP3: "3-Digit Zipcodes",
	GPO: "GPO",
	IDN: "IDN",
};
