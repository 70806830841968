import React from "react";
import PickerToolbar from "@material-ui/pickers/_shared/PickerToolbar";
import ToolbarButton from "@material-ui/pickers/_shared/ToolbarButton";
import { makeStyles } from '@material-ui/core';
import dateFormat from "dateformat";

export const useStyles = makeStyles({
	toolbar: {
		display: "flex",
		flexDirection: "row",
        alignItems: "flex-start",
        height:'50px',
        background:'#558db1 !important'
    },
    button:{
        marginTop:16,
        marginRight:10
    },
   
});

export const MuiDatepickerToolbar = (props:any) => {

	const { 
		isLandscape,
		openView,
		setOpenView,
		title} = props;

	const handleChangeViewClick = (view:any) => (e:any) => {

		setOpenView(view);

	}

	const classes = useStyles();

	return (
		<PickerToolbar className={classes.toolbar} title={title} isLandscape={isLandscape}>
			<ToolbarButton
                className={classes.button}
				onClick={handleChangeViewClick("year")}
				variant="h5"
				label={dateFormat(new Date(), "yyyy")}
				selected={openView === "year"}
			/>
			<ToolbarButton
                className={classes.button}
				onClick={handleChangeViewClick("month")}
				variant="h5"
				selected={openView === "month"}
				label={dateFormat(new Date(), "mmmm")}
			/>
		</PickerToolbar>
	);

}
export default MuiDatepickerToolbar;