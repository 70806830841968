

//import { TenantsFilter, TenantsList} from "../models/tenants/tenants";
import { GET_LOCATION_LIST } from '../constants/api.url';
import { HttpResponse, ResultSet } from "../models/common/result.set";
import Api from "../lib/api";


export class LocationService {

    private static instance: LocationService;
    private constructor() { }

    public static getInstance(): LocationService {
      if (!LocationService.instance) {
        LocationService.instance = new LocationService();
      }
      return LocationService.instance;
    }

    public getLocation = async (filter: any | null) : Promise<HttpResponse<ResultSet<any>>> => {
      const params = [{key: 'filter', value: filter}];
      return await Api.get(`${GET_LOCATION_LIST.URL}`,params)
    }

}