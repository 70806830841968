import { connect, useDispatch } from 'react-redux';
import {  UserActions, ProductActions } from '../../redux/actions'; //CabinetActions
import dataList from "../../constants/dataList";
import { ScheduledReportsComponentShared } from '@fffenterprises/scheduled-reports-screen/lib/scheduled-reports-screen';
import { Product } from '../../components/autoComplete';
import { SUPProductPicker } from '@fffenterprises/sup-product-picker/lib/sup-product-picker';

const mapStateToProps = (state: any) => ({
  authUser: state.user.authUser,
  customers: state.supplier?.suppliersList?.result || [],
  filteredCustomersOrSuppliers: (state.user.authUser?.record?.claims && state.user.authUser?.record?.claims['ALL']) ? 
	  (state.supplier?.suppliersList?.result || [])
	  : 
	  (state.user.authUser?.record?.claims && state.supplier?.suppliersList?.result) ?
	    state.supplier?.suppliersList?.result.filter((c: any) => {
	      return Object.keys(state.user.authUser?.record.claims).includes(c.supplierCode)
	    }) : [],
  cabinets: state.cabinet?.cabinetCollection || [],
  userSubscriptions: state.user.authUser?.record.supplierReportSubscriptions || {},
  token: state.user.firebaseToken,
  dataList: dataList,
  sourceApplication: 'SUP',
  productPicker_productList: state.product?.productList || [],
  productPicker_productNameList: state.product?.productNameList || [],
	productPicker_productLoading: state.ui.productLoading ? state.ui.productLoading : false,
	productPicker_supplierLoading: state.ui.supplierLoading ? state.ui.supplierLoading : false,

   productPicker_productNameLoading: state.ui.productNameLoading ? state.ui.productNameLoading : false,

  SUPProductPicker: SUPProductPicker,
  regionOptions: state.supplier.regions || [],
  productGroups: []
});


const mapDispatchToProps = (dispatch: any) => ({
 sendCreateReportSubscriptionRequest: (params: any) => dispatch(UserActions.createReportSubscription(params) ),
 sendDeleteReportSubscriptionRequest: (params: any) => dispatch(UserActions.deleteReportSubscription(params) ),
 sendEditReportSubscriptionRequest: (params: any) => dispatch(UserActions.editReportSubscription(params) ),
 setSnackbarMessage: (payload: object) => dispatch(UserActions.setSnackbarMessage(payload)),
 searchCabinets: () => {}, //(payload: object) => dispatch( CabinetActions.cabinetRequestStartAction(payload)),
 sendOneTimeReportRequest: (params: any) => dispatch(UserActions.sendOneTimeReport(params) ),
 productPicker_getProductList: (filter: any) => dispatch(ProductActions.getProductList(filter)),
 productPicker_getProductNameList: (filter: any) => dispatch(ProductActions.getProductNameList(filter))
});


const ScheduledReportsContainer = connect(
  mapStateToProps, 
  mapDispatchToProps
)(ScheduledReportsComponentShared);

export default ScheduledReportsContainer;