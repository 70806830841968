import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


interface ConfirmProps {
    open: boolean;
    handleClose: () => void;
    content: string;
    title: string;
    handleYes:()=>void;
}

export default function ConfirmDialog(props: ConfirmProps) {
    const [open, setOpen] = React.useState(false);



    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const handleClose = () => {
        props.handleClose();

    };

    const handleYes =()=>{
        props.handleYes();
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{background:'#f2f2f2'}}>{props.title}</DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.content}
                    </DialogContentText>
                </DialogContent>

                <DialogActions style={{padding:20}}>


                    <Button onClick={handleClose} variant="contained" size="small" >
                        Cancel
                    </Button>


                    <Button variant="contained" size="small" color="secondary" onClick={handleYes}>
                        Yes
                    </Button>
                </DialogActions>

            </Dialog>
        </div>
    );
}