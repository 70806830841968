import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Loading from '../../components/loading';

const Authentication: React.FC = (props: any) => {

  const pageUrls: any[] = [
    { 'page': 'dashboard', 'url': '/dashboard' },
    { 'page': 'inventory', 'url': '/inventory' },
    { 'page': 'inventoryLocator', 'url': '/inventory-locator' },
    { 'page': 'productExpiration', 'url': '/expiry' },
    { 'page': 'region', 'url': '/regions' },
    { 'page': 'userManagement', 'url': '/users' },
  ];

  const getAuthenicationScreen = () => {

    const pagePermissions: any[] = [];
    const idToken = localStorage.getItem('id_token');
    if (idToken && props.authUser) {
      Object.keys(props.authUser.record.claims).forEach((record, index) => {
        Object.keys(props.authUser.record.claims[record]).forEach((page, index) => {
          pagePermissions.push(page);
        });
      });

      for (let i = 0; i <= pageUrls.length; i++) {
        // console.log(pageUrls[i]);
        if (pagePermissions.includes(pageUrls[i].page)) {
          return <Redirect to={pageUrls[i].url} />;
        }
      }
      return <Loading message="Logging In..." />
    }
    return <Loading message="Logging In..." />
  }
  return (
    <div>
      {getAuthenicationScreen()}
    </div>
  )
}

const mapStateToProps = (state: any) => (
  {
    authUser: state.user.authUser
  }
);
export default connect(mapStateToProps)(Authentication);