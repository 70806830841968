export const SanitizeArray = (jsonArray: any[]) => {
    let dataList: any = [];
    jsonArray.forEach((item: any) => {
        let errorInObj: boolean = false;
        Object.keys(item).forEach(function (key: any) {
            if (!item[key] || item[key] == null) {
                errorInObj = true;
                return;
            }
        });
        if (!errorInObj) {
            dataList.push(item);
        }
    });
    return dataList;
}