import React from 'react';
import { connect } from 'react-redux';
import { Container, Chip, Button } from '@material-ui/core';
import BasePage from '../common/base'
import { Constants } from '../../constants/Constants';
import { CommonStyles } from '../../hooks/styles'
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { getMuiTheme } from '../../hooks/styles';
import { SaveSearchActions } from '../../redux/actions';
import SaveFilter from '../../components/advanceFilters/saveFilter';
import ScheduleFilter from '../../components/advanceFilters/saveFilter/schedule';
import ConfirmDialog from '../../components/dialog/confirm';
import AlertDialog from '../../components/dialog/alert';
import { useHistory } from "react-router-dom";
import { List } from "../../constants/Constants";

interface FilterListProps {
    searchList: any;
    authUser: any;
    getAdvanceSearch: (filter: any) => void;
    saveSearch: (payload: any) => void;
    isLoading: boolean;
    error:any;
}

const SaveSearchList = (props: FilterListProps) => {
    let history = useHistory();
    const classes = CommonStyles();
    const isFirstRender = React.useRef(true);
    const [openSaveFilter, setOpenSaveFilter] = React.useState(false);
    const [pageName, setPageName] = React.useState('');
    const [filterTitle, setFilterTitle] = React.useState('');
    const [uuid, setUuid] = React.useState('');
    const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);
    const [openScheduleFilter, setOpenScheduleFilter] = React.useState(false);
    let [frequency, setFrequency] = React.useState([] as any);
    const [fileType, setFileType] = React.useState('');
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
    const [pagination, setPagination] = React.useState({
        offset: 0,
        limit: 10,
        page: 0
    } as any);
    let [sortOrder, setSortOrder] = React.useState('title asc');

    const handleClose = () => {
        setOpenSaveFilter(false);
    }
    const handleCloseSchedule = () => {
        setOpenScheduleFilter(false);
        setUuid('');
        setPageName('');
        setFilterTitle('');
        setFileType('');
    }
    const handleConfirmClose = () => {
        setUuid('');
        setConfirmOpen(false);
    }

    const handleAlertClose = () => {
        setAlertOpen(false);
    }



    const handleSaveFilter = (rowData: any) => {
        setUuid(rowData[0])
        setPageName(rowData[2]);
        setFilterTitle(rowData[1])
        setOpenSaveFilter(true);

    }

    const fetchSaveSearch = () => {
        const payload: any = {
            offset: pagination.offset,
            limit: pagination.limit,
            skip: 0,
            order: [sortOrder],
            where: { userEmail: props.authUser?.record?.email, pageName: { like: `%${Constants.SAVE_SEARCH.PAGE_PREFIX}%` } },
        };
        props.getAdvanceSearch(JSON.stringify(payload));
    }

    React.useEffect(() => {
        fetchSaveSearch();
    }, [pagination, sortOrder])

    React.useEffect(() => {
        if (props.error && props.error.severity != 'error' && props.error.operation ==  'UPDATE') {
            fetchSaveSearch();
        }
    },[props.error])

    const customBodyRenderFunction = (value?: any, tableMeta?: any) => {
        if ('Action' === tableMeta.columnData.label) {
            return (
                <div>
                    {/*<Button size="small" variant="outlined" color="primary" onClick={() => {
                        const rowData = tableMeta.rowData;
                        setUuid(rowData[0])
                        setPageName(rowData[2]);
                        setFilterTitle(rowData[1]);
                        setOpenScheduleFilter(true);
                        frequency = (rowData[3] && rowData[3] != 0) ? rowData[3].split(',') : ['NEVER_SCHEDULE'];
                        setFrequency(frequency)
                        setFileType(rowData[4]);
                    }
                    }>
                        Schedule
                    </Button> */}
                    <Button size="small" variant="outlined" color="primary" onClick={() => handleSaveFilter(tableMeta.rowData)} style={{
                        marginRight: 8
                    }}>
                        Edit
                    </Button> 
                    <Button size="small" variant="outlined" color="secondary" onClick={() => handleDeleteFilter(tableMeta.rowData)}>
                        Delete
                    </Button>
                </div>
            );
        }
        else if ('Scheduled' === tableMeta.columnData.label) {
            let scheduled = (value && value != 0) ? showFrequency(value) : 'NEVER';
            return (scheduled);
        }
        else if ('Page' === tableMeta.columnData.label) {
            let valueArray = value.split(':');
            return ((valueArray && valueArray[1]) ? valueArray[1].replace("_", " ") : '');
        }
        else {
            return value;
        }
    }

    const showFrequency = (frequency: string) => {
        const frequencyArray = frequency.split(',');
        return (<div>
            {frequencyArray.map((name: string) => {
                return (<Chip color="primary" variant="outlined" className={classes.chipStyle} label={name.split('_')[0]} />);
            })
            }
        </div>
        )
    }

    const getColumns = () => {
        const columns = [
            {
                label: "UUID",
                name: "uid",
            },
            {
                label: "Title",
                name: "title",
            },
            {
                label: "Page",
                name: "pageName",
            },
            // {
            //     label: "Scheduled",
            //     name: "schedule",
            // },
            {
                label: "File Type",
                name: "fileType"
            },
            {
                label: "Action",
                name: "action"
            },

        ];
        let columnsList = columns.map((item, index) => {
            return {
                name: item.name,
                label: item.label,
                options: {
                    sort: (item.label == 'Action') ? false : true,
                    display: (item.label == 'UUID' || item.label == 'File Type') ? false : true,
                    customBodyRender: customBodyRenderFunction,
                    setCellHeaderProps: cellHeaderProps
                }
            }
        });
        return columnsList;
    }

    const cellHeaderProps = (tableMeta: any) => {
        if (tableMeta.name == 'action') {
            return ({ style: { width: '30%' } })
        }
    }

    const tableOptions = {
        search: false,
        filter: false,
        download: false,
        print: false,
        serverSide: true,
        viewColumns: false,
        selectableRows: 'none',
        count: (props.searchList && props.searchList.total_count) ? props.searchList.total_count : 0,
        responsive: "vertical",
        pagination: true,
        page: pagination.page,
        rowsPerPage: pagination.limit,
        rowsPerPageOptions: List.PageLimitOptions,
        textLabels: {
            body: {
                noMatch: (props.isLoading) ? 'Loading records' : 'Sorry no matching record found'
            },
        },
        onTableChange: (tableAction: any, tableState: any) => {
            switch (tableAction) {
                case 'changePage':
                    if (tableState.page > pagination.page) {
                        setPagination({ offset: pagination.offset + pagination.limit, limit: pagination.limit, page: tableState.page });
                    }
                    else if (tableState.page < pagination.page) {
                        setPagination({ offset: tableState.page === 0 ? 0 : pagination.offset - pagination.limit, limit: pagination.limit, page: tableState.page });
                    }
                    break;
                case 'changeRowsPerPage':
                    setPagination({ offset: 0, limit: tableState.rowsPerPage, page: 0 });
                    break;
                case 'sort':
                    sortOrder = `${tableState.sortOrder.name} ${tableState.sortOrder.direction}`
                    setSortOrder(sortOrder);
                    break;
            }
        },
    };

    const handleDeleteFilter = (rowData: any) => {
        if (rowData[3]) {
            setAlertOpen(true);
        } else {
            setConfirmOpen(true);
            setUuid(rowData[0])
            setPageName('');
            setFilterTitle(rowData[1]);
        }
    }

    const handleYes = () => {
        let payload: any = {};
        payload.uuid = uuid;
        payload.action = 'DELETE';
        props.saveSearch(payload);
        handleConfirmClose();
    }

    return (
		<BasePage title={Constants.PAGE_TITLE.SAVE_SEARCH} drawerOpen={true}>
			<Container maxWidth={false} className={classes.container}>
				<div className={classes.appBarSpacer}></div>
				<div className={classes.topBackButton}>
					<Button
						className={classes.defaultButton}
						size="small"
						variant="contained"
						color="primary"
						onClick={() => history.goBack()}
					>
						Back
					</Button>
				</div>
				{/*<MuiThemeProvider theme={getMuiTheme({ tableHeadBackground: '#f1f1f1!important', tableHeadCellFontSize: '12px' })} >*/}
				<MUIDataTable
					data={props.isLoading ? [] : props.searchList?.data}
					columns={getColumns()}
					options={tableOptions}
				/>
				{/*</MuiThemeProvider>*/}
			</Container>
			<ConfirmDialog
				open={confirmOpen}
				handleClose={handleConfirmClose}
				handleYes={handleYes}
				content={`Are you sure you want to delete this filter "${filterTitle}" ?`}
				title={"Delete Filter"}
			/>
			<AlertDialog
				open={alertOpen}
				handleClose={handleAlertClose}
				content={`You can't delete this filter. This filter is scheduled for reporting!`}
				title={"Delete Filter"}
			/>
			<SaveFilter
				filters={{}}
				uuid={uuid}
				title={filterTitle}
				pageName={pageName}
				open={openSaveFilter}
				handleClose={handleClose}
			/>
			<ScheduleFilter
				fileType={fileType}
				frequency={frequency}
				uuid={uuid}
				title={filterTitle}
				pageName={pageName}
				open={openScheduleFilter}
				handleClose={handleCloseSchedule}
			/>
		</BasePage>
	);
}
const mapStateToProps = (state: any) => (
    {
        searchList: (state.saveSearch && state.saveSearch.searchList) ? state.saveSearch.searchList : [],
        authUser: state.user.authUser,
        isLoading: state.ui.isLoading,
        error: (state.saveSearch && state.saveSearch.error) ? state.saveSearch.error : null
    }
);
const mapDispatchToProps = (dispatch: any) => ({
    getAdvanceSearch: (filter: any) => dispatch(SaveSearchActions.getAdvanceSearch(filter)),
    saveSearch: (paylaod: any) => dispatch(SaveSearchActions.saveAdvanceSearch(paylaod)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveSearchList);