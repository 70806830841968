import { action } from 'typesafe-actions';
import { PRODUCT } from '../constants/actions';
import { ResultSet } from '../models/common/result.set';

export const getProductList = (data: any) => action(PRODUCT.GET_PRODUCT_LIST, data);
export const getProductNameList = (data: any) => action(PRODUCT.GET_PRODUCT_NAME_LIST, data);

export const productListSuccess = (data: ResultSet<any>) => action(PRODUCT.PRODUCT_LIST_SUCCESS, data);
export const productNameListSuccess = (data: any) => action(PRODUCT.PRODUCT_NAME_LIST_SUCCESS, data);

export const getProviderProductConfigData = (supplierId: string) => action(PRODUCT.GET_PROVIDER_PRODUCT_CONFIG_DATA, supplierId);
export const providerProductConfigDataSuccess = (data: any) => action(PRODUCT.PROVIDER_PRODUCT_CONFIG_DATA_SUCCESS, data);