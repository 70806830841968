import React from "react";
import { connect } from 'react-redux';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highmaps";
import Annotations from 'highcharts/modules/annotations';
import usAll from "./useAll";
import proj4 from "proj4";
import markerClusters from "highcharts/modules/marker-clusters";
import HC_more from "highcharts/highcharts-more";
import { IMAGES_URL } from '../../../constants/Images';
import { ReportActions, UIAction } from '../../../redux/actions';

HC_more(Highcharts); //init module
markerClusters(Highcharts);
window.proj4 = window.proj4 || proj4;
window.Highcharts = Highcharts;
Annotations(Highcharts);

const areDataEqual = (prevProps: any, nextProps: any) => {
  if (prevProps.graphData === nextProps.graphData || prevProps.warehouseData === nextProps.warehouseData) {
    return false;
  }

  return false;
};


const Chart = React.memo((props: any) => {
  //const Chart = (props: any) => {
  const [selectedCluster, setSelectedCluster] = React.useState(null as any);
  const [graphData, setGraphData] = React.useState(props.isWarehouseInventoryPage ? props.warehouseData : props.graphData); //data passed in -- "graph" is misnomer, it's a map
  const [filter] = React.useState(props.filter);
  const [zoomLevel, setZoomLevel] = React.useState<any>(null)


  React.useEffect(() => {
    setGraphData(props.isWarehouseInventoryPage ? props.warehouseData : props.graphData);
    setSelectedCluster({ "name": "", "city": "", "lat": 0, "lon": -1, "zipcode": "0" });
    Highcharts.charts.forEach((chart: any) => {
      if (chart) {
        chart.reflow();
        chart.redraw();
      }
    });

  }, [props.graphData, props.warehouseData]);

  React.useEffect(() => {
    Highcharts.charts.forEach((chart: any) => {
      if (chart) {
        chart.reflow();
        chart.redraw();
      }
    });
  }, [props.warehouseData]);

  const onWarehouseClick = () => {
    if (selectedCluster && selectedCluster.length > 0) {
      selectedCluster.forEach((obj: any) => {
        graphData.push(obj);
      });
      setGraphData(graphData);
      setSelectedCluster({ "name": "", "city": "", "lat": 0, "lon": -1, "zipcode": "0" });
    }
  }

  const onClusterClick = (e: any) => {

    if (e.point.isCluster && e.point.clusteredData) {
      const clusteredData = (e.point.clusteredData) ? e.point.clusteredData : [];
      const selected: any = (selectedCluster[0]) ? selectedCluster[0] : [];
      if (!selected || selected.length === 0 || clusteredData[0].options != selected) {
        manageClusterClick(clusteredData, e);
      }
      else {
        manageClusterUnClick();
      }
    } else if (e.point && e.point.options) {
      const clusteredData = (e.point.options) ? [{ options: e.point.options }] : [];
      const selected: any = (selectedCluster[0]) ? selectedCluster[0] : [];
      if (!selected || selected.length === 0 || (clusteredData[0].options.lat != selected.lat || clusteredData[0].options.lon != selected.lon)) {
        manageClusterClick(clusteredData, e);
      }
      else {
        manageClusterUnClick();
      }
    }

  }


  const manageClusterClick = (clusteredData: any, e: any) => {
    props.openDrawer(e);
    let selectedClusterList: any = [];
    if (selectedCluster && selectedCluster.length > 0) {
      selectedCluster.forEach((obj: any) => {
        graphData.push(obj);
      });
      setGraphData(graphData);
    }
    clusteredData.forEach((item: any) => {
      let selectedClusterobj: any = item.options;
      let index = graphData.findIndex((obj: any) => (obj && obj.lat) && obj.lat === selectedClusterobj.lat);
      delete graphData[index];
      selectedClusterList.push({ "name": selectedClusterobj.name, "city": selectedClusterobj.city, "lat": selectedClusterobj.lat, "lon": selectedClusterobj.lon, "zipcode": selectedClusterobj.zipcode });
    });
    setSelectedCluster(selectedClusterList);
  }

  const manageClusterUnClick = () => {
    selectedCluster.forEach((obj: any) => {
      graphData.push(obj);
    });
    setGraphData(graphData);
    setSelectedCluster({ "name": "", "city": "", "lat": 0, "lon": -1, "zipcode": "0" });
    let fields: any = { productLabelName: true, productItemId: true };
    if (!filter.productItemId) {
      fields.productLabelName = false;
      fields.productItemId = false;
    }
    fields['state'] = true;
    fields['ndc'] = true;
    let inventoryFilter: any = {
      offset: 0,
      limit: 50,
      where: filter,
      fields: fields,
      order: 'state'
    }
    let inventoryPayload = [{ key: 'filter', value: JSON.stringify(inventoryFilter)}]
    props.changePaginationOffset(0);
    props.onChangeTableFilter(filter);
    props.showMiniLoader(true);
    props.getInventoryList(inventoryPayload);
  }


  const redrawCallbacks: any = [];
  const chartOption = {
    chart: {
      map: usAll,
      events: {
        load: function (this: any) {
        },
        click: function (e: any) {
        },
        redraw: function (event: any) {
          const chartObj: any = this
          setZoomLevel(chartObj.mapView.zoom)
          if (selectedCluster && selectedCluster.length > 0 && chartObj.mapView.zoom < zoomLevel) {
            manageClusterUnClick()
          }
          for (var i = 0; i < redrawCallbacks.length; ++i) redrawCallbacks[i].call(this, event);
        }
      }
    },
    title: {
      text: "Inventory Locator"
    },
    subtitle: {
      /*text:''*/
    },
    legend: {
      enabled: true,
      layout: 'vertical',
      align: 'right',
      floating: false,
      backgroundColor: ( // theme
        Highcharts.defaultOptions &&
        Highcharts.defaultOptions.legend &&
        Highcharts.defaultOptions.legend.backgroundColor
      ) || 'rgba(255, 255, 255, 0.85)'
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: 'bottom'
      }
    },
    tooltip: {
      snap: 0,
      formatter: function (this: any) {
        if (typeof (this.point.clusterPointsAmount) === "undefined") {
          const state = (this.point.options.name) ? this.point.options.name : null;
          const city = (this.point.options.city) ? this.point.options.city : null;
          const zipcode = (this.point.options.zipcode) ? this.point.options.zipcode : null;

          let qty = 1;
          if (this.point.series && this.point.series.name && this.point.series.name === 'Non-Consignment Inventory') {
            qty = (this.point.options && this.point.options.value) ? this.point.options.value : 1;
          }
          let toolTip = `<b>Total ${this.point.series.name}: ${qty} <br/>`;

          if (state) {
            toolTip += "<b>State: </b>" + state + "<br>";
          }
          if (city) {
            toolTip += "<b>City: </b>" + city + "<br>";
          }
          if (zipcode) {
            toolTip += "<b>Zip Code: </b>" + zipcode + "<br>";
          }
          return toolTip;
        } 
        else {
          const clusterData = this.point.clusteredData;
          const clusterDetails = props.getClusterDetails(clusterData);
          let toolText = '';
          toolText += "<b>Total " + this.point.series.name + ": " + this.point.clusterPointsAmount + "</b> <br/> <b>State:</b> " + this.point.clusteredData[0].options.name + "<br/>";
          if (clusterDetails.cityCode) {
            toolText += "<b>City:</b> " + clusterDetails.cityCode + "<br/>";
          }
          if (clusterDetails.zipCode) {
            toolText += "<b>Zip Code:</b> " + clusterDetails.zipCode;
          }
          return toolText;
        }
      }
    },
    plotOptions: {
      series: {
        stickyTracking: false,
        turboThreshold: 0,
        dataLabels: {
          enabled: true
        },
        events: {}
      },
      mappoint: {
        cluster: {
          minimumClusterSize: 1,
          enabled: true,
          allowOverlap: false,
          animation: false,
          layoutAlgorithm: {
            type: 'grid',
            gridSize: 30 //this may be coincidental but this value seems to keep our maps from glitching
          }
        }
      }
    },
    series: [
      {
        name: "Basemap",
        allowPointSelect: true,
        borderColor: "#fff",
        nullColor: "#51b8e8",
        showInLegend: false,
        mapData: usAll,
        cursor: 'pointer',

      },
      {
        turboThreshold: 0,
        allowPointSelect: true,
        cursor: 'pointer',
        color: '#0063a1',
        type: "mappoint",
        enableMouseTracking: true,
        colorKey: "clusterPointsAmount",
        name: "Consignment Inventory",
        data: graphData, //here is actual data passed
        showInLegend: false,
        dataLabels: {
          enabled: true,
          color: '#FFFFFF',
          format: '',
        },
        marker: {
          radius: 8,
          symbol: `url(${IMAGES_URL.GREEN_CLUSTER})`
        },
        states: {
          inactive: {
            opacity: 1
          }
        },
        events: {
          click: function (e: any) {
            onClusterClick(e);
            return false;
          },
        }
      },
      {
        allowPointSelect: true,
        cursor: 'pointer',
        color: '#fcb040',
        type: "mappoint",
        enableMouseTracking: true,
        colorKey: "clusterPointsAmount",
        name: "Consignment Inventory",
        data: selectedCluster,
        showInLegend: false,
        dataLabels: {
          enabled: true,
          color: 'black',
          format: '',
        },
        marker: {
          radius: 8,
          symbol: `url(${IMAGES_URL.ORANGE_CLUSTER})`
        },
        states: {
          inactive: {
            opacity: 1
          }
        },
        events: {
          click: function (e: any) {
            onClusterClick(e);
            return false;
          }
        }
      },
      {
        turboThreshold: 0,
        cursor: 'pointer',
        color: 'black',
        type: "mappoint",
        enableMouseTracking: true,
        colorKey: "clusterPointsAmount",
        name: "Non-Consignment Inventory",
        marker: {
          radius: 16,
          symbol: `url(${IMAGES_URL.WAREHOUSE_ICON})`
        },
        events: {
          click: function (e: any) {
            props.getWareHouseDataList(e.point.id);
            //return false;
          }
        },
        data: props.warehouseData,
        showInLegend: false,
        dataLabels: {
          enabled: true,
          useHTML: true,
          allowOverlap: true,
          formatter(this: any) {
            return '';
          }
        },

      },
    ],

    annotations: [{
      labelOptions: {
        backgroundColor: 'black',
        //shape: 'connector',
        borderColor: '#0063a1',
        justify: false,
        crop: false,
        verticalAlign: 'top',
        y: 80,
        x: 40,
        style: {
          fontSize: '10px',
          cursor: 'pointer',
          backgroundColor: 'black!important',
        },

      },
      labels: [
        {
          point: 'NCDC',
          text: 'NCDC',
        }],
      events: {
        click: function (e: any) {
          onWarehouseClick();
          props.getWareHouseDataList('NCDC');
        }
      },
      draggable: false,
    },

    {
      labelOptions: {
        backgroundColor: 'black',
        //shape: 'connector',
        borderColor: '#0063a1',
        justify: true,
        crop: false,
        verticalAlign: 'top',
        y: 80,
        x: 40,
        style: {
          fontSize: '10px',
          cursor: 'pointer',
          backgroundColor: 'black!important',
        },
      },

      draggable: false,
      events: {
        click: function (e: any) {
          onWarehouseClick();
          props.getWareHouseDataList('SCDC');
        }
      },
      labels: [{
        point: 'SCDC',
        text: 'SCDC'
      }]
    },

    {
      labelOptions: {

        backgroundColor: 'black',
        //shape: 'connector',
        borderColor: '#0063a1',
        justify: true,
        crop: false,
        verticalAlign: 'top',
        y: 80,
        x: 40,
        style: {
          fontSize: '10px',
          cursor: 'pointer',
          //textOutline: '1px white',
          backgroundColor: 'black!important',
        },

      },
      labels: [{
        point: 'TXDC',
        text: 'TXDC'
      }],
      draggable: false,
      events: {
        click: function (e: any) {
          onWarehouseClick();
          props.getWareHouseDataList('TXDC');
        }
      },
    }
    ],

  };

  return (
    <>
    {(graphData && graphData.length) || (props.warehouseData?.length) ?
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOption}
        constructorType={"mapChart"}
        //allowChartUpdate={true}
        // {...props}
        //immutable={false}
        containerProps={{ style: { height: "600px", width: '900px', margin: '0 auto' } }}
      />
      :
      <div>No Data.</div>}
    </>
  );
}, areDataEqual);


const mapStateToProps = (state: any) => (
  {
    graphData: state.report.graphData ? state.report.graphData : [],
    inventoryData:
      state.report.inventoryData && state.report.inventoryData.result
        ? state.report.inventoryData.result
        : [],
    warehouseData: state.report.warehouseData ? state.report.warehouseData : [],
  }
);

const mapDispatchToProps = (dispatch: any) => ({
  showMiniLoader: (isLoader: boolean) => dispatch(UIAction.showMiniLoader(isLoader)),
  getInventoryList: (filter: any) => dispatch(ReportActions.getDrawerData(filter)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Chart);