export const UI = {
	NAVIGATE: "UI_NAVIGATE",
	NAVIGATE_RESPONSE: "UI_NAVIGATE_RESPONSE",
	SHOW_LOADER: "SHOW_LOADER",
	SET_INVENTORY_STATE: "SET_INVENTORY_STATE",
	GET_INVENTORY_STATE: "GET_INVENTORY_STATE",
	SET_API_ERROR: "SET_API_ERROR",
	SHOW_MINI_LOADER: "SHOW_MINI_LOADER",
	SET_SUPPLIER: "SET_SUPPLIER",
	SET_NAV_DRAWER: "SET_NAV_DRAWER",
	SET_URL_FILTER: "SET_URL_FILTER",
	SET_NAV_STATE: "SET_NAV_STATE",
	CLOUD_MGNT_LINK_LOADING: "CLOUD_MGNT_LINK_LOADING",
	AUTH_PROCESSING: "AUTH_PROCESSING",
	SUPPLIER_LOADING: "SUPPLIER_LOADING",
	PRODUCT_LOADING: "PRODUCT_LOADING",
	SET_CUSTOMER: "SET_CUSTOMER",
	SET_SELECTED_DATE_RANGE: "SET_SELECTED_DATE_RANGE",
	SET_SELECTED_CABINET_TYPE: "SET_SELECTED_CABINET_TYPE",
	SET_SELECTED_EXPIRY_DATE: "SET_SELECTED_EXPIRY_DATE",
	SET_SELECTED_HISTORY_DATE: "SET_SELECTED_HISTORY_DATE",
	SET_MORE_FILTER_VALUE: "SET_MORE_FILTER_VALUE",
	STORE_CUSTOMER: "STORE_CUSTOMER",
	RESET_ALL_FILTERS: "RESET_ALL_FILTERS",
	GET_FILTER_DATA: "GET_FILTER_DATA",
	SET_FILTER_DATA: "SET_FILTER_DATA",
};

export const USER = {
	TOKEN: "GET_FIREBASE_TOKEN",
	TOKEN_RESPONSE: "GET_FIREBASE_TOKEN_RESPONSE",
	RECORD_REQUEST: "GET_USER_RECORD_REQUEST",
	RECORD_RESPONSE: "GET_USER_RECORD_RESPONSE",
	SET_NAV: "SET_NAV",
	CLEAR_USER_STATE: "CLEAR_USER_STATE",
	SEND_USER_SETUP: "SEND_USER_SETUP",
	CREATE_USER_PROPERTY_REQUEST: "CREATE_USER_PROPERTY_REQUEST",
	GET_USER_PROPERTY_REQUEST: "GET_USER_PROPERTY_REQUEST",
	GET_USER_PROPERTY_RESPONSE: "GET_USER_PROPERTY_RESPONSE",
	SET_SNACKBAR_OPEN: "SET_SNACKBAR_OPEN",
	SET_SNACKBAR_MESSAGE: "SET_SNACKBAR_MESSAGE",
	CREATE_REPORT_SUBSCRIPTION_REQUEST: "CREATE_REPORT_SUBSCRIPTION_REQUEST",
	DELETE_REPORT_SUBSCRIPTION_REQUEST: "DELETE_REPORT_SUBSCRIPTION_REQUEST",
	CREATE_ONE_TIME_REPORT_REQUEST: "CREATE_ONE_TIME_REPORT_REQUEST",
	ADD_USER_SUBSCRIPTION_SUCCESS: "ADD_USER_SUBSCRIPTION_SUCCESS",
	DELETE_USER_SUBSCRIPTION_SUCCESS: "DELETE_USER_SUBSCRIPTION_SUCCESS",
	SEND_ONE_TIME_REPORT_REQUEST: "SEND_ONE_TIME_REPORT_REQUEST",
	SEND_ONE_TIME_REPORT_SUCCESS: "SEND_ONE_TIME_REPORT_SUCCESS",
	EDIT_REPORT_SUBSCRIPTION_REQUEST: "EDIT_REPORT_SUBSCRIPTION_REQUEST",
	EDIT_USER_SUBSCRIPTION_SUCCESS: "EDIT_USER_SUBSCRIPTION_SUCCESS",
	GET_USER_REPORT_QUEUE: "GET_USER_REPORT_QUEUE",
	GET_USER_REPORT_QUEUE_SUCCESS: "GET_USER_REPORT_QUEUE_SUCCESS",
	UPDATE_USER_QUEUE_ITEM: "UPDATE_USER_QUEUE_ITEM",
	ADJUST_AWAITED_QUEUE_ITEMS_COUNT: "ADJUST_AWAITED_QUEUE_ITEMS_COUNT",
	HANDLE_RNI_AUTH_TOKEN: 'HANDLE_RNI_AUTH_TOKEN',
	HANDLE_RNI_AUTH_PAGE_REFRESH: 'HANDLE_RNI_AUTH_PAGE_REFRESH',
};

export const SUPPLIER = {
  GET_SUPPLIER_LIST: 'GET_SUPPLIER_LIST',
  SUCCESS_SUPPLIER_LIST: 'SUCCESS_SUPPLIER_LIST',
  GET_SUPPLIERS: 'GET_SUPPLIERS',
  SUCCESS_SUPPLIERS: 'SUCCESS_SUPPLIERS',
  SUCCESS_REGIONS_LIST: 'SUCCESS_REGIONS_LIST',
}

export const PRODUCT = {
	GET_PRODUCT_LIST: "GET_PRODUCT_LIST",
	GET_PRODUCT_NAME_LIST: "GET_PRODUCT_NAME_LIST",
	PRODUCT_LIST_SUCCESS: "PRODUCT_LIST_SUCCESS",
	PRODUCT_NAME_LIST_SUCCESS: "PRODUCT_NAME_LIST_SUCCESS",
	GET_PROVIDER_PRODUCT_CONFIG_DATA: "GET_PROVIDER_PRODUCT_CONFIG_DATA",
	PROVIDER_PRODUCT_CONFIG_DATA_SUCCESS: "PROVIDER_PRODUCT_CONFIG_DATA_SUCCESS",
};

export const INVENTORY = {
	GET_INVENTORY_LIST: "GET_INVENTORY_LIST",
	SUCCESS_CURRENT_INVENTORY: "SUCCESS_CURRENT_INVENTORY",
	SUCCESS_INVENTORY_LIST: "SUCCESS_INVENTORY_LIST",
	GET_INVENTORY_DETAIL_LIST: "GET_INVENTORY_DETAIL_LIST",
	SUCCESS_INVENTORY_DETAIL_LIST: "SUCCESS_INVENTORY_DETAIL_LIST",

	GET_INVENTORY: "GET_INVENTORY",
	SUCCESS_INVENTORY: "SUCCESS_INVENTORY",
	CLEAR_INVENTORY_LIST: "CLEAR_INVENTORY_LIST",

	GET_GROUP_INVENTORY: "GET_GROUP_INVENTORY",
	SUCCESS_GROUP_INVENTORY: "SUCCESS_GROUP_INVENTORY",

	GET_LOT_LEVEL_INVENTORY: "GET_LOT_LEVEL_INVENTORY",
	SUCCESS_LOT_LEVEL_INVENTORY: "SUCCESS_LOT_LEVEL_INVENTORY",

	GET_WAREHOUSE_INVENTORY: "GET_WAREHOUSE_INVENTORY",
	SUCCESS_WAREHOUSE_INVENTORY: "SUCCESS_WAREHOUSE_INVENTORY",

	GET_EXPOPRT_INVENTORY: "GET_EXPOPRT_INVENTORY",

	GET_REGION_GROUP_INVENTORY: "GET_REGION_GROUP_INVENTORY",
	SUCCESS_REGION_GROUP_INVENTORY: "SUCCESS_REGION_GROUP_INVENTORY",

	GET_PAR_DATA: "GET_PAR_DATA",
	SUCCESS_PAR_DATA: "SUCCESS_PAR_DATA",
};

export const TRANSACTION = {
	GET_TRANSACTIONS: "GET_TRANSACTIONS",
	SET_TRANSACTIONS: "SET_TRANSACTIONS",
	CLEAR_TRANSACTIONS: "CLEAR_TRANSACTIONS",
};

export const LOCATION = {
	GET_LOCATION_LIST: "GET_LOCATION_LIST",
	LOCATION_LIST_SUCCESS: "LOCATION_LIST_SUCCESS",
};

export const REPORT = {
	REGION_GRAPH: {
		GET_REGION_GRAPH_DATA: "GET_REGION_GRAPH_DATA",
		REGION_GRAPH_DATA_SUCCESS: "REGION_GRAPH_DATA_SUCCESS",
		CLEAR_REGION_GRAPH_DATA: "CLEAR_REGION_GRAPH_DATA",
		GET_WAREHOUSE_DATA: "GET_WAREHOUSE_DATA",
		SUCCESS_WAREHOUSE_DATA: "SUCCESS_WAREHOUSE_DATA",
		CLEAR_WAREHOUSE_DATA: "CLEAR_WAREHOUSE_DATA",
	},
	DRAWER_DATA: {
		GET_DRAWER_DATA: "GET_DRAWER_DATA",
		SUCCESS_DRAWER_DATA: "SUCCESS_DRAWER_DATA",
		GET_WAREHOUSE_DRAWER_DATA: "GET_WAREHOUSE_DRAWER_DATA",
		SUCCESS_WAREHOUSE_DRAWER_DATA: "SUCCESS_WAREHOUSE_DRAWER_DATA",
	},
};

export const DASHBOARD = {
	GET_ALL: "GET_ALL",
	GET_STATE_INVENTORY: "GET_STATE_INVENTORY",
	SUCCESS_STATE_INVENTORY: "SUCCESS_STATE_INVENTORY",
	SHOW_STATE_INVENTORY_LOADER: "SHOW_STATE_INVENTORY_LOADER",

	GET_CUSTOMER_CABINET_COUNTS: "GET_CUSTOMER_CABINET_COUNTS",
	SET_CUSTOMER_CABINET_COUNTS: "SET_CUSTOMER_CABINET_COUNTS",
	SHOW_CUSTOMER_CABINET_COUNTS_LOADER: "SHOW_CUSTOMER_CABINET_COUNTS_LOADER",

	GET_INVENTORY_REGION_DATA: "GET_INVENTORY_REGION_DATA",
	SUCCESS_INVENTORY_REGION_DATA: "SUCCESS_INVENTORY_REGION_DATA",
	SHOW_INVENTORY_REGION_LOADER: "SHOW_INVENTORY_REGION_LOADER",

	GET_INVENTORY_ROLL_UP: "GET_INVENTORY_ROLL_UP",
	SUCCESS_INVENTORY_ROLL_UP: "SUCCESS_INVENTORY_ROLL_UP",
	SHOW_INVENTORY_ROLL_UP_LOADER: "SHOW_INVENTORY_ROLL_UP_LOADER",

	GET_INVENTORY_REVENUE: "GET_INVENTORY_REVENUE",
	SUCCESS_INVENTORY_REVENUE: "SUCCESS_INVENTORY_REVENUE",
	SHOW_REVENUE_LOADER: "SHOW_REVENUE_LOADER",

	GET_COMPARISION_FOR_SALE: "GET_COMPARISION_FOR_SALE",
	SUCCESS_COMPARISION_FOR_SALE: "SUCCESS_COMPARISION_FOR_SALE",
	SHOW_COMPARISION_FOR_SALE_LOADER: "SHOW_COMPARISION_FOR_SALE_LOADER",

	GET_REGION_INVENTORY: "GET_REGION_INVENTORY",
	SUCCESS_REGION_INVENTORY: "SUCCESS_REGION_INVENTORY",
	SHOW_REGION_INVENTORY_LOADER: "SHOW_REGION_INVENTORY_LOADER",
	SET_IS_LOADING: "SET_IS_LOADING",
	SET_IS_PRODUCTS_LOADING: "SET_IS_PRODUCTS_LOADING",

	CLEAR_DASHBOARD: "CLEAR_DASHBOARD",
};

export const PRODUCT_EXPIRATION = {
	GET_GRAPH_DATA: "GET_GRAPH_DATA",
	SUCCESS_GRAPH_DATA: "SUCCESS_GRAPH_DATA",

	GET_EXPIRY_DATALIST: "GET_EXPIRY_DATALIST",
	SUCCESS_EXPIRY_DATALIST: "SUCCESS_EXPIRY_DATALIST",
	SET_SELECTED_STATE: "SET_SELECTED_STATE",
	CLEAR_PRODUCT_EXPIRATION_DATA: "CLEAR_PRODUCT_EXPIRATION_DATA",
};

export const MAPPING = {
	SUPPLIER_REGIONS: {
		GET_SUPPLIER_REGIONS: "GET_SUPPLIER_REGIONS",
		SUCCESS_SUPPLIER_REGIONS: "SUCCESS_SUPPLIER_REGIONS",
		UPDATE_SUPPLIER_REGIONS: "UPDATE_SUPPLIER_REGIONS",
		CREATE_SUPPLIER_REGIONS: "CREATE_SUPPLIER_REGIONS",
		DELETE_SUPPLIER_REGIONS: "DELETE_SUPPLIER_REGIONS",
	},
	REGIONS_STATES: {
		GET_REGIONS_STATES: "GET_REGIONS_STATES",
		SUCCESS_REGIONS_STATES: "SUCCESS_REGIONS_STATES",
		UPDATE_REGIONS_STATES: "UPDATE_REGIONS_STATES",
	},
	STATE_LIST: {
		GET_STATES_LIST: "GET_STATES_LIST",
		SUCCESS_STATES_LIST: "SUCCESS_STATES_LIST",
	},
};

export const USER_MANAGEMENT = {
	LIST_REQUEST: "GET_USER_LIST_REQUEST",
	LIST_RESPONSE: "GET_USER_LIST_RESPONSE",
	CLEAR_USER_LIST: "CLEAR_USER_LIST",
	CREATE_REQUEST: "CREATE_USER_REQUEST",
	UPDATE_REQUEST: "UPDATE_USER_REQUEST",
	USER_EXISTS_REQUEST: "USER_EXISTS_REQUEST",
	USER_EXISTS_RESPONSE: "USER_EXISTS_RESPONSE",
	CLEAR_USER_CHECK: "CLEAR_USER_CHECK",
	ERROR: "USER_ERROR",
};

export const SAVE_SEARCH_ACTIONS = {
	CREATE: {
		REQUEST_CREATE_SEARCH: "REQUEST_CREATE_SEARCH",
		ERROR_CREATE_SEARCH: "ERROR_CREATE_SEARCH",
	},
	UPDATE: {
		REQUEST_UPDATE_SEARCH: "REQUEST_UPDATE_SEARCH",
		RESPONSE_UPDATE_SEARCH: "RESPONSE_UPDATE_SEARCH",
	},
	LIST: {
		REQUEST_LIST_SEARCH: "REQUEST_LIST_SEARCH",
		RESPONSE_LIST_SEARCH: "RESPONSE_LIST_SEARCH",
	},
	SCHEDULE: {
		SCHEDULE_SAVE_REQUEST: "SCHEDULE_SAVE_REQUEST",
	},
};

export const SUPPLIER_REGIONS = {
	CREATE_REGION: "CREATE_REGION",
	GET_REGION: "GET_REGION",
	SUCCESS_GET_REGION: "SUCCESS_GET_REGION",
	UPDATE_REGION: "UPDATE_REGION",
	DELETE_REGION: "DELETE_REGION",
	CLEAR_REGION_DATA: "CLEAR_REGION_DATA",
	GET_AVAILABLE_REGION_DATA: "GET_AVAILABLE_REGION_DATA",
	SUCCESS_GET_AVAILABLE_REGION_DATA: "SUCCESS_GET_AVAILABLE_REGION_DATA",
	UPLOAD_REGION_DATA_CSV: "UPLOAD_REGION_DATA_CSV",
	DOWNLOAD_REGION_DATA_CSV_TEMPLATES: "DOWNLOAD_REGION_DATA_CSV_TEMPLATES",
};

export const TENANTS = {
	SET_CLASS_OF_TRADE_OPTIONS: "SET_CLASS_OF_TRADE_OPTIONS",
	SET_FACILITY_TYPE_OPTIONS: "SET_FACILITY_TYPE_OPTIONS",
};
