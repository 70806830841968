export const ObjectListToString = (strigIndex: string, filterObj: any) => {
    Object.keys(filterObj).forEach(function (key: any) {
        if (typeof filterObj[key] === 'object') {
            strigIndex += ObjectListToString(strigIndex, filterObj[key])
        } else {
            let value: any = (filterObj[key]) && filterObj[key];
            strigIndex += (value !=='undefined' && value!==undefined)? value :'';
        }
    });
    strigIndex = strigIndex.replace(' ', '-');
    return strigIndex;
}