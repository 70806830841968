import { action } from 'typesafe-actions';
import { USER_MANAGEMENT } from '../constants/actions';
import {UserRecord } from '../models/user/user';

export const getUserListStartAction = (payload: any | null) => action(USER_MANAGEMENT.LIST_REQUEST, payload);

export const getUserListCompleteAction = (response: UserRecord[]) => action(USER_MANAGEMENT.LIST_RESPONSE, response);
export const clearUserList = () => action(USER_MANAGEMENT.CLEAR_USER_LIST);

export const createUserAction = (userData: UserRecord) => action(USER_MANAGEMENT.CREATE_REQUEST, userData);
export const updateUserAction = (userData: UserRecord) => action(USER_MANAGEMENT.UPDATE_REQUEST, userData);
export const userExistsCheckAction = (email: string) => action(USER_MANAGEMENT.USER_EXISTS_REQUEST, email);
export const userExistsCheckCompleteAction = (response: string) => action(USER_MANAGEMENT.USER_EXISTS_RESPONSE, response);
export const clearUserCheckAction = () => action(USER_MANAGEMENT.CLEAR_USER_CHECK);
export const userErrorAction = (error: boolean) => action(USER_MANAGEMENT.ERROR, error);